// Questions about human body
export const humanBody = [
  {
    question: "Humans only use 10% of their brains",
    answer: false,
    category: "human body",
    explanation: "This is a common myth. Brain scans show activity throughout the entire brain, even during sleep. Different tasks activate different regions, but over the course of a day, we use virtually all of our brain. No area of the brain is completely inactive.",
    searchMapping: {
      key: 'human brain',
      phrase: '"brain activity"',
      required: ['neuroscience', 'cognition'],
      excluded: []
    }
  },
  {
    question: "The human body has four blood types: A, B, AB, and O",
    answer: true, 
    category: "human body",
    explanation: "The ABO blood group system classifies human blood into four main types: A, B, AB, and O, based on the presence or absence of A and B antigens on red blood cells. These types are further classified by the Rh factor (positive or negative).",
    searchMapping: {
      key: 'blood types',
      phrase: '"human blood types"',
      required: ['blood', 'donation', 'medical'],
      excluded: []
    }
  },
  {
    question: "Your nose and ears keep growing all your life",
    answer: true,
    category: "human body",
    explanation: "Ears and nose do appear to get larger as we age, but they're not actually growing. Instead, the cartilage in these areas weakens and stretches due to gravity and loss of elasticity, creating the impression of continued growth.",
    searchMapping: {
      key: 'nose ears',
      phrase: '"nose ear growth"',
      required: ['nose', 'ear', 'grow'],
      excluded: []
    }
  },
  {
    question: "Everyone has different fingerprints, even twins",
    answer: true,
    category: "human body",
    explanation: "Fingerprints are formed by a combination of genetic factors and random environmental conditions in the womb. Even identical twins with the same DNA develop different fingerprints due to variations in pressure, position, and blood flow during development.",
    searchMapping: {
      key: 'fingerprints',
      phrase: '"fingerprint twins"',
      required: ['fingerprint', 'genetic', 'environmental'],
      excluded: []
    }
  },
  {
    question: "People have five senses",
    answer: true,
    category: "human body",
    explanation: "The five traditional senses are sight, hearing, smell, taste, and touch. However, scientists recognize additional senses including balance (equilibrioception), temperature (thermoception), pain (nociception), and body position (proprioception).",
    searchMapping: {
      key: 'people senses',
      phrase: '"human senses"',
      required: ['people', 'sense', 'senses'],
      excluded: []
    }
  },
  {
    question: "Drinking milk makes your bones stronger",
    answer: true,
    category: "human body",
    explanation: "Milk contains calcium and vitamin D, nutrients essential for building and maintaining strong bones. Calcium provides structure and hardness to bones, while vitamin D helps the body absorb and use calcium more effectively.",
    searchMapping: {
      key: 'milk bones',
      phrase: '"milk bone health"',
      required: ['milk', 'bone', 'health'],
      excluded: []
    }
  },
  {
    question: "Your hair and nails hurt when you cut them",
    answer: false,
    category: "human body",
    explanation: "Hair and nails are made of keratin, which consists of dead cells containing no nerve endings or blood vessels. This is why cutting them doesn't cause pain. The pain you might feel when cutting nails too short comes from the sensitive skin underneath.",
    searchMapping: {
      key: 'hair nails',
      phrase: '"hair nail pain"',
      required: ['hair', 'nail', 'cut'],
      excluded: []
    }
  },
  {
    question: "All your blood in your body is red",
    answer: true,
    category: "human body",
    explanation: "All human blood is red due to the hemoglobin in red blood cells. Oxygenated blood appears bright red, while deoxygenated blood is darker red (appearing bluish through skin). The blue appearance of veins is due to how light interacts with skin, not blue blood.",
    searchMapping: {
      key: 'blood',
      phrase: '"blood red"',
      required: ['blood', 'red', 'hemoglobin'],
      excluded: ['deoxygenated']
    }
  },
  {
    question: "Your brain can feel pain",
    answer: false,
    category: "human body",
    explanation: "While you can get headaches, the brain tissue itself cannot feel pain because it lacks pain receptors (nociceptors). The pain we associate with headaches comes from blood vessels, muscles, and nerves in the surrounding tissues, meninges, and scalp.",
    searchMapping: {
      key: 'brain',
      phrase: '"brain pain"',
      required: ['brain', 'pain'],
      excluded: []
    }
  },
  {
    question: "Humans have the same number of bones their whole life",
    answer: false,
    category: "human body",
    explanation: "Babies are born with approximately 300 bones, while adults have 206. As children grow, many of these bones fuse together. For example, the skull starts as multiple bones that gradually join to protect the brain while still allowing for growth.",
    searchMapping: {
      key: 'humans',
      phrase: '"human bones"',
      required: ['human', 'bones'],
      excluded: []
    }
  },
  {
    question: "The human body has more than 600 muscles",
    answer: true,
    category: "human body",
    explanation: "The human body contains approximately 650 skeletal muscles, which make up about 40% of an adult's body weight. These muscles work together to provide movement, maintain posture, stabilize joints, and generate heat to maintain body temperature.",
    searchMapping: {
      key: 'human',
      phrase: '"human muscles"',
      required: ['human', 'muscle', 'skeletal'],
      excluded: []
    }
  },
  {
    question: "Your tongue is the strongest muscle in your body",
    answer: false,
    category: "human body",
    explanation: "The tongue is not the strongest muscle in the body. The masseter (jaw muscle) is actually the strongest by weight, capable of closing the teeth with a force as great as 55 pounds on the incisors or 200 pounds on the molars.",
    searchMapping: {
      key: 'tongue',
      phrase: '"tongue strength"',
      required: ['tongue', 'strength'],
      excluded: []
    }
  },
  {
    question: "Humans can breathe and swallow at the same time",
    answer: false,
    category: "human body",
    explanation: "Humans cannot breathe and swallow simultaneously due to our anatomy. The epiglottis, a flap of tissue, closes over the windpipe during swallowing to prevent food from entering the lungs. This temporarily blocks the airway, making it impossible to breathe while swallowing.",
    searchMapping: {
      key: 'humans',
      phrase: '"human breathe"',
      required: ['human', 'breathe', 'swallow'],
      excluded: []
    }
  },
  {
    question: "Your stomach acid is strong enough to dissolve metal",
    answer: true,
    category: "human body",
    explanation: "Stomach acid (hydrochloric acid) is extremely powerful with a pH between 1-3, strong enough to dissolve metals like zinc and even process nails if you accidentally swallowed them. It doesn't harm your stomach because your stomach lining produces a new protective layer of mucus every two weeks.",
    searchMapping: {
      key: 'stomach acid',
      phrase: '"stomach acid strength"',
      required: ['stomach', 'acid', 'strong'],
      excluded: []
    }
  },
  {
    question: "The average person produces enough saliva in their lifetime to fill two swimming pools",
    answer: true,
    category: "human body",
    explanation: "People typically produce 1-2 liters of saliva daily. Over an average lifetime, that's about 25,000 liters - enough to fill two backyard swimming pools! This saliva helps us taste food, begin digestion, and keep our mouths clean.",
    searchMapping: {
      key: 'saliva production',
      phrase: '"human saliva amount"',
      required: ['saliva', 'mouth', 'produce'],
      excluded: []
    }
  },
  {
    question: "Every time you blink, your brain temporarily shuts off",
    answer: true,
    category: "human body",
    explanation: "When you blink (about 15-20 times per minute), your brain momentarily 'switches off,' causing a short pause in your visual perception. Your brain does this to create a smooth visual experience without noticing all the tiny blackouts from blinking.",
    searchMapping: {
      key: 'blinking brain',
      phrase: '"brain activity blinking"',
      required: ['blink', 'brain', 'perception'],
      excluded: []
    }
  },
  {
    question: "Your body contains enough iron to make a 3-inch nail",
    answer: true,
    category: "human body",
    explanation: "The average adult has about 4 grams of iron in their body - enough to make a small 3-inch nail. Most of this iron is found in your blood's hemoglobin, which carries oxygen from your lungs to all your cells.",
    searchMapping: {
      key: 'body iron',
      phrase: '"human body iron content"',
      required: ['iron', 'body', 'blood'],
      excluded: []
    }
  },
  {
    question: "Humans are the only animals that can sleep on their backs",
    answer: false,
    category: "human body",
    explanation: "Many animals can and do sleep on their backs, including some domestic pets like dogs and cats. Sleeping position varies widely across species and even among individuals, based on comfort and safety needs.",
    searchMapping: {
      key: 'sleep position',
      phrase: '"human sleep position"',
      required: ['sleep', 'back', 'position'],
      excluded: []
    }
  },
  {
    question: "Your fingerprints can grow back if damaged",
    answer: true,
    category: "human body",
    explanation: "If you scrape or burn your fingertips superficially, your fingerprints will grow back. The pattern is formed in the deeper layer of skin (dermis), so unless there's very deep scarring that reaches this layer, your unique fingerprint pattern will regenerate.",
    searchMapping: {
      key: 'fingerprint regeneration',
      phrase: '"fingerprint healing"',
      required: ['fingerprint', 'grow', 'back'],
      excluded: []
    }
  },
  {
    question: "Babies have more bones than adults",
    answer: true,
    category: "human body",
    explanation: "Newborn babies have around 300 bones, while adults have only 206. As children grow, many of these bones fuse together. For example, the skull starts as multiple bones with gaps (fontanelles or 'soft spots') that allow the baby's head to compress during birth.",
    searchMapping: {
      key: 'baby bones',
      phrase: '"baby adult bones"',
      required: ['baby', 'bones', 'adult'],
      excluded: []
    }
  },
  {
    question: "Your body creates a complete new skeleton every 10 years",
    answer: true,
    category: "human body",
    explanation: "Through a process called remodeling, your body constantly breaks down old bone tissue and builds new bone tissue. About 10% of your skeleton is replaced each year, meaning you have a completely new skeleton roughly every decade!",
    searchMapping: {
      key: 'bone renewal',
      phrase: '"skeleton replacement"',
      required: ['bone', 'renewal', 'skeleton'],
      excluded: []
    }
  },
  {
    question: "You can sneeze while you're asleep",
    answer: false,
    category: "human body",
    explanation: "It's impossible to sneeze while you're sleeping. During sleep, the nerves involved in the sneeze reflex are also at rest. If an irritant is significant enough to trigger a sneeze, it will actually wake you up before you sneeze.",
    searchMapping: {
      key: 'sleep sneeze',
      phrase: '"sneeze during sleep"',
      required: ['sneeze', 'sleep', 'reflex'],
      excluded: []
    }
  },
  {
    question: "Every person has a unique tongue print, just like fingerprints",
    answer: true,
    category: "human body",
    explanation: "Like fingerprints, every person's tongue has a unique pattern of bumps, ridges, and marks. This 'tongue print' is so distinctive that it's sometimes used for identification, similar to fingerprints or iris scans.",
    searchMapping: {
      key: 'tongue print',
      phrase: '"tongue identification"',
      required: ['tongue', 'unique', 'pattern'],
      excluded: []
    }
  },
  {
    question: "You shed enough skin cells each year to fill a small suitcase",
    answer: true,
    category: "human body",
    explanation: "The average person sheds about 500 million skin cells every day - that's nearly 9 pounds (4 kg) of cells per year, enough to fill a small suitcase! Most of the dust in your home is actually dead skin cells.",
    searchMapping: {
      key: 'skin shedding',
      phrase: '"human skin cells shedding"',
      required: ['skin', 'cells', 'shed'],
      excluded: []
    }
  },
  {
    question: "The smallest bone in your body is the size of a grain of rice",
    answer: true,
    category: "human body",
    explanation: "The stapes (or stirrup) bone in your middle ear is the smallest bone in the human body. At just 3mm long, it's roughly the size of a grain of rice. Despite being tiny, it plays a crucial role in transmitting sound vibrations from your eardrum to your inner ear.",
    searchMapping: {
      key: 'smallest bone',
      phrase: '"smallest human bone"',
      required: ['stapes', 'ear', 'bone'],
      excluded: []
    }
  },
  {
    question: "Your eyes stay the same size from birth to adulthood",
    answer: false,
    category: "human body",
    explanation: "Your eyes grow significantly after birth. Babies are born with eyes about 16mm in diameter, while adult eyes measure around 24mm. Most eye growth occurs during the first two years of life, with some additional growth during puberty.",
    searchMapping: {
      key: 'eye growth',
      phrase: '"human eye development"',
      required: ['eye', 'growth', 'size'],
      excluded: []
    }
  },
  {
    question: "When you blush, your stomach lining also turns red",
    answer: true,
    category: "human body",
    explanation: "When you blush, the blood vessels in your face dilate, but this physiological response isn't limited to your face. The same reaction occurs in your stomach lining, which also turns redder due to increased blood flow when you're embarrassed.",
    searchMapping: {
      key: 'blushing effect',
      phrase: '"blush stomach reaction"',
      required: ['blush', 'stomach', 'red'],
      excluded: []
    }
  },
  {
    question: "Your heartbeat can synchronize with music you're listening to",
    answer: true,
    category: "human body",
    explanation: "Your heart rate naturally adjusts to match the tempo of music you're listening to. This phenomenon, called entrainment, explains why fast-paced music energizes you while slower songs can calm you down. Your body instinctively syncs with the rhythms it hears.",
    searchMapping: {
      key: 'heart music',
      phrase: '"heartbeat music synchronization"',
      required: ['heart', 'beat', 'music'],
      excluded: []
    }
  },
  {
    question: "Children can hear higher-pitched sounds than adults",
    answer: true,
    category: "human body",
    explanation: "Young children can hear frequencies as high as 20,000 Hz, while most adults lose the ability to hear anything above 16,000 Hz. This high-frequency hearing loss is called presbycusis and happens gradually as we age due to natural wear and tear on the hearing structures.",
    searchMapping: {
      key: 'child hearing',
      phrase: '"children hearing range"',
      required: ['child', 'hear', 'frequency'],
      excluded: []
    }
  },
  {
    question: "Every red blood cell in your body travels around your entire circulatory system in less than 60 seconds",
    answer: true,
    category: "human body",
    explanation: "Your red blood cells complete a full circuit through your entire body in just about 20-60 seconds. In their 120-day lifespan, each red blood cell makes approximately 1,440 trips around your entire body, traveling about 12,000 miles!",
    searchMapping: {
      key: 'blood circulation',
      phrase: '"blood cell circulation time"',
      required: ['blood', 'cell', 'circulation'],
      excluded: []
    }
  },
  {
    question: "Your brain is wrinkly to fit more brain cells in your skull",
    answer: true,
    category: "human body",
    explanation: "The wrinkles and folds in your brain (called gyri and sulci) dramatically increase its surface area. Without these folds, your brain would need to be about three times larger to fit all the same cells. These wrinkles are what enable humans to have such advanced thinking abilities.",
    searchMapping: {
      key: 'brain folds',
      phrase: '"brain wrinkles purpose"',
      required: ['brain', 'wrinkles', 'folds'],
      excluded: []
    }
  },
  {
    question: "Humans are the only animals that cry emotional tears",
    answer: true,
    category: "human body",
    explanation: "While many animals produce tears to keep their eyes moist, humans are the only species known to shed tears in response to emotional states like sadness, joy, or frustration. Scientists believe emotional crying may have evolved as a social signal to communicate feelings and elicit help from others.",
    searchMapping: {
      key: 'emotional tears',
      phrase: '"human tears emotions"',
      required: ['tears', 'emotional', 'crying'],
      excluded: []
    }
  },
  {
    question: "There are more bacteria in your mouth right now than there are people on Earth",
    answer: true,
    category: "human body",
    explanation: "Your mouth contains between 500-700 different species of bacteria, with a total population of about 20 billion microorganisms. That's more than twice the number of people on Earth! Most of these bacteria are harmless or even beneficial, helping with digestion and protecting against harmful microbes.",
    searchMapping: {
      key: 'mouth bacteria',
      phrase: '"human mouth microbiome"',
      required: ['mouth', 'bacteria', 'microorganisms'],
      excluded: []
    }
  }
]; 