// Questions about science
export const science = [
  {
    question: "It needs to be raining to see a rainbow",
    answer: true,
    category: "science",
    explanation: "Rainbows form when sunlight passes through water droplets, which act as tiny prisms that refract light into different colors. Rain is necessary because water droplets must be present in the air, and the sun must be behind the viewer.",
    searchMapping: {
      key: 'rainbow',
      phrase: '"rainbow formation"',
      required: ['rain', 'rainbow', 'sunlight'],
      excluded: []
    }
  },
  {
    question: "Lightning never strikes the same place twice",
    answer: false,
    category: "science",
    explanation: "Lightning can and often does strike the same place multiple times. Tall structures like the Empire State Building are struck by lightning dozens of times each year. Lightning tends to hit tall objects that are good conductors, regardless of whether they've been struck before.",
    searchMapping: {
      key: 'lightning storm',
      phrase: '"lightning strike"',
      required: ['storm', 'thunder'],
      excluded: []
    }
  },
  {
    question: "Diamonds are made from coal",
    answer: false,
    category: "science",
    explanation: "While both coal and diamonds are carbon-based, diamonds are not formed from coal. Diamonds form about 100 miles below Earth's surface under extreme heat and pressure from carbon that was never coal. Most natural diamonds are between 1 and 3 billion years old, much older than coal deposits.",
    searchMapping: {
      key: 'diamond',
      phrase: '"diamond coal"',
      required: ['diamond', 'coal'],
      excluded: []
    }
  },
  {
    question: "Sound travels faster in water than in air",
    answer: true,
    category: "science",
    explanation: "Sound waves travel approximately 4.3 times faster in water (about 1,480 meters per second) than in air (about 343 meters per second). This is because water molecules are closer together than air molecules, allowing sound waves to transfer more efficiently.",
    searchMapping: {
      key: 'sound',
      phrase: '"sound water air"',
      required: ['sound', 'water', 'air'],
      excluded: []
    }
  },
  {
    question: "Glass is a liquid that flows very slowly",
    answer: false,
    category: "science",
    explanation: "Glass is not a slow-flowing liquid but an amorphous solid. The misconception arose from observing old windows that are thicker at the bottom, which was actually due to manufacturing processes of the past. Glass molecules are fixed in place and do not flow at room temperature.",
    searchMapping: {
      key: 'glass',
      phrase: '"glass liquid"',
      required: ['glass', 'liquid'],
      excluded: []
    }
  },
  {
    question: "Water always freezes at 0 degrees Celsius",
    answer: false,
    category: "science",
    explanation: "Pure water can be 'supercooled' to temperatures well below 0°C without freezing if it's very clean and there are no nucleation sites (like dust or rough surfaces) for ice crystals to form. In certain laboratory conditions, water has remained liquid at temperatures as low as -42°C.",
    searchMapping: {
      key: 'water',
      phrase: '"water freeze"',
      required: ['water', 'freeze', '0°C'],
      excluded: []
    }
  },
  {
    question: "Humans share 50% of their DNA with bananas",
    answer: true,
    category: "science",
    explanation: "Humans and bananas share approximately 60% of the same DNA, demonstrating our common evolutionary origins. This shared genetic heritage includes genes for basic cellular functions that all living organisms need. This fact highlights how all life on Earth is related through evolution.",
    searchMapping: {
      key: 'humans',
      phrase: '"human banana"',
      required: ['human', 'banana', 'dna'],
      excluded: []
    }
  },
  {
    question: "People have grown plants in lunar soil",
    answer: true,
    category: "science",
    explanation: "In 2022, NASA scientists successfully grew plants in lunar regolith (moon soil) for the first time. Using samples collected during Apollo missions, they grew Arabidopsis plants, though they grew more slowly and showed stress responses compared to plants in Earth soil. This achievement has important implications for future lunar missions.",
    searchMapping: {
      key: 'moon',
      phrase: '"moon soil"',
      required: ['moon', 'soil'],
      excluded: []
    }
  },
  {
    question: "Water can boil and freeze at the same time under the right conditions",
    answer: true,
    category: "science",
    explanation: "At a specific combination of temperature and pressure called the 'triple point,' water can exist in all three states simultaneously: solid, liquid, and gas. In these conditions, which occur at approximately 0.01°C and a pressure of 0.006 atmospheres, water can indeed both boil and freeze at the same time.",
    searchMapping: {
      key: 'water states',
      phrase: '"water triple point"',
      required: ['water', 'boil', 'freeze'],
      excluded: []
    }
  },
  {
    question: "All metals are magnetic",
    answer: false,
    category: "science",
    explanation: "Only ferromagnetic metals like iron, nickel, and cobalt are naturally magnetic or can be magnetized. Many common metals, such as aluminum, copper, gold, and silver, are not magnetic at all. These non-magnetic metals may react to magnetic fields in other ways (like through eddy currents), but they don't exhibit the property of ferromagnetism.",
    searchMapping: {
      key: 'magnetic metals',
      phrase: '"metals magnetism"',
      required: ['metal', 'magnetic', 'iron'],
      excluded: []
    }
  },
  {
    question: "Sound can create light in a process called sonoluminescence",
    answer: true,
    category: "science",
    explanation: "Sonoluminescence is a real phenomenon where sound waves create tiny, imploding bubbles in a liquid that generate brief flashes of light. When powerful sound waves pass through water, they can form bubbles that collapse so violently that they create temperatures hotter than the surface of the sun for a fraction of a second, producing a small flash of light.",
    searchMapping: {
      key: 'sound light',
      phrase: '"sonoluminescence"',
      required: ['sound', 'light', 'bubble'],
      excluded: []
    }
  },
  {
    question: "Glass is actually a liquid that flows very slowly over time",
    answer: false,
    category: "science",
    explanation: "Despite the popular belief, glass is not a slow-flowing liquid but an amorphous solid. The misconception came from observing old windows that are thicker at the bottom, but this is due to historic glass manufacturing methods, not flow over time. Glass molecules are fixed in place and do not flow at room temperature even over centuries.",
    searchMapping: {
      key: 'glass liquid',
      phrase: '"glass flow myth"',
      required: ['glass', 'liquid', 'flow'],
      excluded: []
    }
  },
  {
    question: "Plants breathe in oxygen and breathe out carbon dioxide",
    answer: false,
    category: "science",
    explanation: "Plants primarily do the opposite: they take in carbon dioxide and release oxygen during photosynthesis. However, plants do respire like animals do, taking in oxygen and releasing carbon dioxide, but the amount of oxygen they produce through photosynthesis is much greater than what they consume through respiration, resulting in a net oxygen output.",
    searchMapping: {
      key: 'plant breathing',
      phrase: '"plant photosynthesis respiration"',
      required: ['plant', 'oxygen', 'carbon dioxide'],
      excluded: []
    }
  },
  {
    question: "Pure water doesn't conduct electricity",
    answer: true,
    category: "science",
    explanation: "Completely pure water (H₂O with no dissolved substances) is a poor electrical conductor. Electricity is carried by ions in solution, and pure water has very few ions. The water we encounter daily conducts electricity because it contains dissolved minerals and salts that create ions. That's why distilled water is a poor conductor, while tap water or seawater conduct electricity well.",
    searchMapping: {
      key: 'water electricity',
      phrase: '"water conductivity"',
      required: ['water', 'electricity', 'conduct'],
      excluded: []
    }
  },
  {
    question: "A bolt of lightning is five times hotter than the surface of the sun",
    answer: true,
    category: "science",
    explanation: "A lightning bolt can reach temperatures around 30,000°C (54,000°F), which is about five times hotter than the surface of the sun (approximately 5,500°C or 10,000°F). This extreme heat causes the air around the lightning to rapidly expand, creating the thunder sound we hear after seeing lightning.",
    searchMapping: {
      key: 'lightning temperature',
      phrase: '"lightning heat temperature"',
      required: ['lightning', 'temperature', 'hot'],
      excluded: []
    }
  },
  {
    question: "If you touch dry ice with your bare hands, it will stick to your skin",
    answer: true,
    category: "science",
    explanation: "Dry ice (solid carbon dioxide) is extremely cold at -78.5°C (-109.3°F) and can cause severe frostbite. If you touch it with bare skin, the moisture on your skin freezes instantly, causing the dry ice to stick to you, similar to how your tongue might stick to a frozen pole. This can tear your skin when you try to remove it, causing serious injuries.",
    searchMapping: {
      key: 'dry ice',
      phrase: '"dry ice skin danger"',
      required: ['dry ice', 'skin', 'cold'],
      excluded: []
    }
  },
  {
    question: "Magnets can lose their magnetism when heated",
    answer: true,
    category: "science",
    explanation: "When a magnet is heated to a certain temperature called the Curie point, its magnetic domains become randomized and it loses its magnetic properties. For example, iron loses its magnetism at about 770°C (1,418°F). This happens because the heat provides enough energy to disrupt the alignment of the magnetic domains within the material.",
    searchMapping: {
      key: 'magnet heat',
      phrase: '"magnet heat demagnetize"',
      required: ['magnet', 'heat', 'lose'],
      excluded: []
    }
  },
  {
    question: "A rainbow always has exactly 7 colors",
    answer: false,
    category: "science",
    explanation: "A rainbow contains a continuous spectrum of colors, not exactly seven distinct ones. The tradition of listing seven colors (red, orange, yellow, green, blue, indigo, and violet) was popularized by Isaac Newton, who wanted the number to match the seven notes in a musical scale. In reality, rainbows contain millions of colors that blend continuously into each other.",
    searchMapping: {
      key: 'rainbow colors',
      phrase: '"rainbow spectrum colors"',
      required: ['rainbow', 'colors', 'spectrum'],
      excluded: []
    }
  },
  {
    question: "Helium can change the sound of your voice permanently",
    answer: false,
    category: "science",
    explanation: "Inhaling helium temporarily changes your voice to a higher pitch because sound travels faster through helium than through regular air. However, this effect is completely temporary and lasts only seconds as you exhale the helium and breathe in normal air. Helium does not cause permanent changes to your voice, though inhaling it can be dangerous as it displaces oxygen.",
    searchMapping: {
      key: 'helium voice',
      phrase: '"helium voice effect"',
      required: ['helium', 'voice', 'change'],
      excluded: []
    }
  },
  {
    question: "The loudest sound ever recorded broke windows 50 miles away",
    answer: true,
    category: "science",
    explanation: "The 1883 eruption of Krakatoa volcano in Indonesia created one of the loudest sounds in recorded history. The sound was so powerful it broke windows 50 miles away, was heard clearly 3,000 miles away, and circled the Earth multiple times as detectable sound waves. The eruption had the explosive force of about 200 megatons of TNT.",
    searchMapping: {
      key: 'loudest sound',
      phrase: '"krakatoa eruption sound"',
      required: ['loudest', 'sound', 'heard'],
      excluded: []
    }
  },
  {
    question: "Blood in your veins is blue until it touches oxygen",
    answer: false,
    category: "science",
    explanation: "Blood is always red, both inside and outside the body. Deoxygenated blood (in veins) is darker red, while oxygenated blood (in arteries) is bright red. The bluish appearance of veins under the skin is due to how light interacts with skin and tissue - blue light reflects back to our eyes while other colors are absorbed, creating the visual illusion that veins are blue.",
    searchMapping: {
      key: 'blood color',
      phrase: '"blood color veins"',
      required: ['blood', 'blue', 'veins'],
      excluded: []
    }
  },
  {
    question: "Some metals can 'remember' their shape and return to it when heated",
    answer: true,
    category: "science",
    explanation: "Shape memory alloys, like nitinol (nickel-titanium), can 'remember' their original shape. If bent or deformed, these special metals will return to their pre-set shape when heated. This property is used in many applications including medical devices, eyeglass frames, and spacecraft components. The effect occurs because of a reversible shift in the crystal structure of the metal.",
    searchMapping: {
      key: 'memory metal',
      phrase: '"shape memory alloy"',
      required: ['metal', 'shape', 'memory'],
      excluded: []
    }
  },
  {
    question: "Hot water freezes faster than cold water",
    answer: true,
    category: "science",
    explanation: "Under certain conditions, hot water can freeze faster than cold water, a phenomenon known as the Mpemba effect. While seemingly counterintuitive, this may occur due to factors like evaporation, dissolved gases, or convection currents. Though not fully understood and not applicable in all circumstances, this effect has been observed and studied since Aristotle's time.",
    searchMapping: {
      key: 'hot water freeze',
      phrase: '"mpemba effect"',
      required: ['hot', 'water', 'freeze'],
      excluded: []
    }
  },
  {
    question: "Diamonds can be made from peanut butter",
    answer: true,
    category: "science",
    explanation: "Diamonds can theoretically be made from peanut butter because it contains carbon, the element diamonds are made of. Scientists have demonstrated this by subjecting peanut butter to extremely high pressures and temperatures that simulate conditions deep in Earth's mantle. The carbon atoms rearrange into diamond's crystal structure, though the process consumes all other elements in the peanut butter.",
    searchMapping: {
      key: 'diamond peanut butter',
      phrase: '"diamond from peanut butter"',
      required: ['diamond', 'peanut', 'carbon'],
      excluded: []
    }
  },
  {
    question: "Electricity always takes the path of least resistance",
    answer: false,
    category: "science",
    explanation: "Electricity takes all possible paths available to it, not just the path of least resistance. It's more accurate to say that more current flows through paths with less resistance. This is why birds can sit on power lines without getting shocked—the electricity still flows through the wire (path of least resistance) but some tiny amount also goes through the bird, just not enough to harm it.",
    searchMapping: {
      key: 'electricity path',
      phrase: '"electricity resistance path"',
      required: ['electricity', 'path', 'resistance'],
      excluded: []
    }
  },
  {
    question: "Rubber bands heat up when stretched and cool down when relaxed",
    answer: true,
    category: "science",
    explanation: "When a rubber band is stretched, its molecules become more ordered, decreasing entropy, which results in the release of heat. Conversely, when it's relaxed, the molecules become more disordered, increasing entropy and absorbing heat, making the rubber band feel cooler. You can test this by quickly stretching a rubber band and touching it to your lip to feel the temperature change.",
    searchMapping: {
      key: 'rubber band temperature',
      phrase: '"rubber band heat stretch"',
      required: ['rubber', 'band', 'temperature'],
      excluded: []
    }
  },
  {
    question: "Salt water conducts electricity better than fresh water",
    answer: true,
    category: "science",
    explanation: "Salt water is a much better conductor of electricity than fresh water because dissolved salt separates into ions (charged particles) that can carry electrical current. Pure water has very few ions and is a poor conductor. This is why ocean water conducts electricity well, making it dangerous to use electrical devices near salt water or when you're wet from swimming in the ocean.",
    searchMapping: {
      key: 'salt water electricity',
      phrase: '"salt water conductivity"',
      required: ['salt', 'water', 'electricity'],
      excluded: []
    }
  },
  {
    question: "Static electricity can make a neon light glow without plugging it in",
    answer: true,
    category: "science",
    explanation: "Static electricity can indeed cause a neon light to glow without being plugged in. If you rub a neon bulb with wool or silk to create static electricity, or bring it near a strong static electric field, the electrical energy can excite the neon gas atoms inside, causing them to release photons of light. This demonstrates how neon lights work, just using static instead of current from an outlet.",
    searchMapping: {
      key: 'static electricity neon',
      phrase: '"static electricity neon light"',
      required: ['static', 'electricity', 'neon'],
      excluded: []
    }
  }
]; 