// Questions about technology
export const technology = [
  {
    question: "The first computer bug was an actual bug",
    answer: true,
    category: "technology",
    explanation: "In 1947, Grace Hopper found a moth trapped in a relay of a Harvard Mark II computer. When she removed it, she noted they were 'debugging' the system. While the term 'bug' to describe technical issues predates this incident, it was the first actual computer 'bug' found.",
    searchMapping: {
      key: 'computer moth bug',
      phrase: '"first computer bug"',
      required: ['computer', 'bug', 'history'],
      excluded: []
    }
  },
  {
    question: "WiFi stands for Wireless Fidelity",
    answer: false,
    category: "technology",
    explanation: "Contrary to popular belief, WiFi doesn't stand for anything. It was created as a marketing term by the Wi-Fi Alliance, designed to sound catchy and vaguely resemble 'Hi-Fi' (High Fidelity), but the term itself isn't an acronym.",
    searchMapping: {
      key: 'wifi signal',
      phrase: '"wireless networking"',
      required: ['internet', 'wireless'],
      excluded: []
    }
  },
  {
    question: "Computers need the internet to work",
    answer: false,
    category: "technology",
    explanation: "Computers function independently of the internet. They can run software, process data, and perform calculations without an internet connection. The internet is simply a network that connects computers, allowing them to share information.",
    searchMapping: {
      key: 'computers internet',
      phrase: '"computer internet"',
      required: ['computer', 'internet', 'work'],
      excluded: []
    }
  },
  {
    question: "The sun can power cars",
    answer: true,
    category: "technology",
    explanation: "Solar-powered cars use photovoltaic cells (solar panels) to convert sunlight directly into electricity, which powers an electric motor. While most commercial vehicles use solar power as a supplementary energy source, specialized solar race cars can run entirely on solar energy.",
    searchMapping: {
      key: 'sun power',
      phrase: '"solar car"',
      required: ['sun', 'power', 'car'],
      excluded: []
    }
  },
  {
    question: "Phones can only make calls",
    answer: false,
    category: "technology",
    explanation: "Modern smartphones are sophisticated computing devices capable of much more than just making calls. They can send messages, browse the internet, take photos and videos, play games, run applications, navigate with GPS, and perform countless other functions.",
    searchMapping: {
      key: 'phones',
      phrase: '"phone calls"',
      required: ['phone', 'call', 'calls'],
      excluded: []
    }
  },
  {
    question: "Video games are older than television",
    answer: false,
    category: "technology",
    explanation: "Television technology was developed in the early 20th century, with public broadcasting beginning in the 1920s and 1930s. The first recognized video game, 'Tennis for Two,' wasn't created until 1958, with commercial video games emerging in the 1970s.",
    searchMapping: {
      key: 'video games',
      phrase: '"video game television"',
      required: ['video', 'game', 'television'],
      excluded: []
    }
  },
  {
    question: "The first computer mouse was made of wood",
    answer: true,
    category: "technology",
    explanation: "The first computer mouse was indeed made with a wooden shell. It was invented by Douglas Engelbart in 1964 at the Stanford Research Institute. This wooden prototype had two metal wheels and a single button, quite different from today's sleek designs.",
    searchMapping: {
      key: 'computer mouse',
      phrase: '"computer mouse wood"',
      required: ['computer', 'mouse', 'wood'],
      excluded: []
    }
  },
  {
    question: "Email was invented before the World Wide Web",
    answer: true,
    category: "technology",
    explanation: "Email was invented in 1971 by Ray Tomlinson, who implemented the first email system on the ARPANET. The World Wide Web wasn't created until 1989 by Tim Berners-Lee, making email nearly two decades older than the web as we know it today.",
    searchMapping: {
      key: 'email',
      phrase: '"email world wide"',
      required: ['email', 'world', 'wide'],
      excluded: []
    }
  },
  {
    question: "The first website is still online today",
    answer: true,
    category: "technology",
    explanation: "The first website, created by Tim Berners-Lee at CERN in 1991, has been restored at its original URL (info.cern.ch). While it went offline for years, CERN restored it in 2013 as part of a project to preserve the web's early history.",
    searchMapping: {
      key: 'website',
      phrase: '"website online"',
      required: ['website', 'online'],
      excluded: []
    }
  },
  {
    question: "QR codes were invented for tracking car parts",
    answer: true,
    category: "technology",
    explanation: "QR codes were invented in 1994 by a Japanese company called Denso Wave, a subsidiary of Toyota. They were created specifically to track automobile parts during manufacturing and distribution. The codes were designed to be quickly readable by scanners, hence the name 'Quick Response'.",
    searchMapping: {
      key: 'qr code',
      phrase: '"qr tracking"',
      required: ['qr', 'code', 'tracking'],
      excluded: []
    }
  },
  {
    question: "The first text message said 'Hello World'",
    answer: false,
    category: "technology",
    explanation: "The first text message was sent on December 3, 1992, by engineer Neil Papworth to Richard Jarvis at Vodafone. It read 'Merry Christmas,' not 'Hello World.' Papworth sent it from a computer to Jarvis's Orbitel 901 mobile phone as part of the SMS technology development.",
    searchMapping: {
      key: 'text message',
      phrase: '"text message hello"',
      required: ['text', 'message', 'hello'],
      excluded: ['merry', 'christmas']
    }
  },
  {
    question: "The shortest commercial flight in the world takes 1.5 minutes",
    answer: true,
    category: "technology",
    explanation: "The shortest scheduled commercial flight in the world operates between the islands of Westray and Papa Westray in Scotland's Orkney Islands. The flight, operated by Loganair, covers a distance of just 1.7 miles and takes approximately 1.5 minutes in good weather conditions.",
    searchMapping: {
      key: 'flight',
      phrase: '"flight shortest"',
      required: ['flight', 'shortest'],
      excluded: []
    }
  },
  {
    question: "LEGO is the world's largest tire manufacturer",
    answer: true,
    category: "technology",
    explanation: "LEGO produces approximately 318 million tiny rubber tires each year for its various sets, making it the world's largest tire manufacturer by volume. This surpasses major tire companies like Bridgestone, Goodyear, and Michelin, though of course, LEGO's tires are significantly smaller.",
    searchMapping: {
      key: 'lego',
      phrase: '"lego tire"',
      required: ['lego', 'tire'],
      excluded: []
    }
  },
  {
    question: "Play-Doh was originally created as wallpaper cleaner",
    answer: true,
    category: "technology",
    explanation: "Play-Doh was initially developed in the 1930s as a wallpaper cleaner to remove soot and dirt from wallpaper. When washable vinyl wallpapers became popular and the original product became obsolete, the company repurposed the non-toxic putty as a children's modeling compound in the 1950s.",
    searchMapping: {
      key: 'play-doh',
      phrase: '"play-doh wallpaper"',
      required: ['play-doh', 'wallpaper'],
      excluded: []
    }
  },
  {
    question: "Rubik's Cube was invented by a carpenter",
    answer: false,
    category: "technology",
    explanation: "The Rubik's Cube was invented in 1974 by Ernő Rubik, who was a professor of architecture in Budapest, Hungary, not a carpenter. He originally created the puzzle as a teaching tool to help his students understand 3D objects and spatial relationships.",
    searchMapping: {
      key: 'rubik',
      phrase: '"rubik carpenter"',
      required: ['rubik', 'carpenter'],
      excluded: []
    }
  },
  {
    question: "The first video game console was called the Nintendo",
    answer: false,
    category: "technology",
    explanation: "The first home video game console was the Magnavox Odyssey, released in 1972. Created by Ralph Baer, it predated the Atari Pong home console and Nintendo's first console (the Color TV-Game) by several years. The Odyssey used circuit cards to change games and featured plastic overlays for the TV screen.",
    searchMapping: {
      key: 'video game',
      phrase: '"video game console"',
      required: ['video', 'game', 'console'],
      excluded: []
    }
  },
  {
    question: "The most popular color for LEGO bricks is blue",
    answer: false,
    category: "technology",
    explanation: "The most popular LEGO brick color is red, not blue. Red was one of the original LEGO colors along with white, yellow, and blue when the modern brick design was patented in 1958. Red remains the most produced and recognized color in LEGO sets worldwide.",
    searchMapping: {
      key: 'lego',
      phrase: '"lego color"',
      required: ['lego', 'color'],
      excluded: []
    }
  },
  {
    question: "The biggest selling toy in history is the Rubik's Cube",
    answer: true,
    category: "technology",
    explanation: "The Rubik's Cube is indeed one of the best-selling toys in history, with over 350 million cubes sold worldwide since its international release in 1980. Created by Hungarian professor Ernő Rubik in 1974, this 3D combination puzzle gained massive popularity during the 1980s and continues to be a popular puzzle toy today.",
    searchMapping: {
      key: 'rubik',
      phrase: '"rubik cube"',
      required: ['rubik', 'cube'],
      excluded: []
    }
  },
  {
    question: "Scientists can now 3D print human organs",
    answer: true,
    category: "technology",
    explanation: "Scientists have developed 3D bioprinting technology that can create functional human tissue complete with blood vessels. While full organ printing for transplants is still developing, researchers have successfully printed heart tissue, kidney structures, and other organ components that function like natural tissue.",
    searchMapping: {
      key: 'scientists',
      phrase: '"scientists 3D"',
      required: ['scientists', '3D'],
      excluded: []
    }
  },
  {
    question: "Humans have made diamonds from the air",
    answer: true,
    category: "technology",
    explanation: "Companies have developed technology to capture carbon dioxide from the atmosphere and transform it into diamonds that are chemically identical to mined diamonds. These 'air diamonds' or 'carbon-negative diamonds' remove more CO2 from the atmosphere than is emitted during their production process.",
    searchMapping: {
      key: 'humans',
      phrase: '"humans diamonds"',
      required: ['humans', 'diamonds'],
      excluded: []
    }
  },
  {
    question: "Humans have created artificial rain",
    answer: true,
    category: "technology",
    explanation: "Humans can indeed create artificial rain through a process called cloud seeding. This involves dispersing substances like silver iodide or dry ice into clouds to encourage water droplets to form and fall as rain. Countries including China, the UAE, and the US use this technology to combat drought or ensure rainfall for agriculture.",
    searchMapping: {
      key: 'humans',
      phrase: '"humans rain"',
      required: ['humans', 'rain'],
      excluded: []
    }
  },
  {
    question: "People have built a hotel made entirely of ice",
    answer: true,
    category: "technology",
    explanation: "The ICEHOTEL in Jukkasjärvi, Sweden, is built entirely of ice and snow each winter. First constructed in 1989, it features rooms, furniture, decorations, and even drinking glasses made of ice. The hotel melts completely each spring and is rebuilt with a new design the following winter, making it a unique architectural achievement.",
    searchMapping: {
      key: 'hotel',
      phrase: '"hotel ice"',
      required: ['hotel', 'ice'],
      excluded: []
    }
  },
  {
    question: "Scientists can control robots with human thoughts",
    answer: true,
    category: "technology",
    explanation: "Brain-computer interfaces (BCIs) allow people to control robots using only their thoughts. These systems translate brain signals into commands that robots can understand and execute. This technology has been used to help paralyzed individuals control robotic limbs and has potential applications in various fields including medicine and manufacturing.",
    searchMapping: {
      key: 'scientists',
      phrase: '"scientists robots"',
      required: ['scientists', 'robots'],
      excluded: []
    }
  },
  {
    question: "Humans have made buildings that can heal themselves",
    answer: true,
    category: "technology",
    explanation: "Self-healing concrete contains bacteria (usually Bacillus species) that remain dormant until cracks form. When water enters these cracks, the bacteria activate and produce limestone, effectively 'healing' the concrete. This innovation extends the life of structures and reduces maintenance costs for infrastructure worldwide.",
    searchMapping: {
      key: 'humans',
      phrase: '"humans buildings"',
      required: ['humans', 'buildings'],
      excluded: []
    }
  },
  {
    question: "People have created paint that can generate electricity",
    answer: true,
    category: "technology",
    explanation: "Scientists have developed solar paint or 'photovoltaic paint' that can generate electricity from sunlight and even moisture in the air. These paints contain semiconductor particles that convert light energy into electrical energy, potentially turning any painted surface into a source of renewable energy.",
    searchMapping: {
      key: 'people',
      phrase: '"people paint"',
      required: ['people', 'paint'],
      excluded: []
    }
  },
  {
    question: "The first computer mouse had only one button",
    answer: false,
    category: "technology",
    explanation: "The first computer mouse, invented by Douglas Engelbart in 1964, actually had three buttons. This wooden prototype was quite different from modern mice, featuring a cord at the front (resembling a mouse's tail) and two metal wheels on the bottom for tracking movement. The multi-button design was intended to allow more complex interactions than a single button could provide.",
    searchMapping: {
      key: 'mouse',
      phrase: '"first computer mouse"',
      required: ['mouse', 'button', 'first'],
      excluded: []
    }
  },
  {
    question: "The word 'robot' comes from a Czech word meaning 'forced labor'",
    answer: true,
    category: "technology",
    explanation: "The word 'robot' was first introduced in the 1920 Czech play 'R.U.R.' (Rossum's Universal Robots) by Karel Čapek. It derives from the Czech word 'robota,' which means 'forced labor' or 'drudgery.' The play featured humanoid machines that performed work for humans but eventually rebelled, establishing many of the themes that continue in robot fiction today.",
    searchMapping: {
      key: 'robot',
      phrase: '"robot etymology"',
      required: ['robot', 'word', 'origin'],
      excluded: []
    }
  },
  {
    question: "The QWERTY keyboard was designed to slow down typing",
    answer: true,
    category: "technology",
    explanation: "The QWERTY keyboard layout was indeed designed in the 1870s to slow down typing. On early mechanical typewriters, keys would jam if adjacent ones were pressed too quickly. By separating commonly used letter pairs, the QWERTY layout reduced jamming by forcing typists to slow down. Despite more efficient layouts being developed since, QWERTY remains standard due to widespread adoption.",
    searchMapping: {
      key: 'keyboard',
      phrase: '"QWERTY design"',
      required: ['keyboard', 'typing', 'slow'],
      excluded: []
    }
  },
  {
    question: "AI can detect depression from Instagram photos",
    answer: true,
    category: "technology",
    explanation: "Research has shown that artificial intelligence algorithms can identify markers of depression in Instagram photos with surprising accuracy. These AIs analyze factors like color preferences (with depressed individuals often posting more blue, gray, or dark-toned images), face frequency, and filter choices. One study found AI was more accurate than general practitioners in identifying depression through these visual cues.",
    searchMapping: {
      key: 'AI',
      phrase: '"AI depression detection"',
      required: ['artificial', 'intelligence', 'depression'],
      excluded: []
    }
  },
  {
    question: "USB cables can only be plugged in one way",
    answer: true,
    category: "technology",
    explanation: "Traditional USB Type-A connectors (the rectangular ones) can only be inserted one way. This design limitation—requiring users to flip the connector an average of 2.8 times before successful connection—was so frustrating it led to the development of USB-C, which features a symmetrical design allowing insertion in either orientation.",
    searchMapping: {
      key: 'USB',
      phrase: '"USB cable design"',
      required: ['USB', 'plug', 'connector'],
      excluded: []
    }
  },
  {
    question: "Humans have implanted microchips in their bodies to open doors",
    answer: true,
    category: "technology",
    explanation: "Some people have voluntarily had RFID or NFC microchips implanted, typically in their hands between the thumb and index finger. These chips can be programmed to unlock doors, start cars, log into computers, or make payments. Sweden has become a hub for this technology, with thousands of citizens opting for implants for convenience despite privacy and security concerns.",
    searchMapping: {
      key: 'implant',
      phrase: '"microchip implant"',
      required: ['human', 'chip', 'implant'],
      excluded: []
    }
  },
  {
    question: "The first emoji was a smiley face",
    answer: false,
    category: "technology",
    explanation: "The first emoji set was created in 1999 by Japanese designer Shigetaka Kurita for NTT DoCoMo's mobile internet platform. The original set contained 176 characters, including weather symbols, traffic signs, and various pictograms—not just a smiley face. These 12x12 pixel images were much simpler than today's detailed emoji but laid the groundwork for the global pictorial language.",
    searchMapping: {
      key: 'emoji',
      phrase: '"first emoji"',
      required: ['emoji', 'first', 'Japan'],
      excluded: []
    }
  },
  {
    question: "Electric cars have existed since the 1800s",
    answer: true,
    category: "technology",
    explanation: "Electric vehicles were actually among the first practical automobiles, with electric cars holding land speed and distance records in the late 1800s. By 1900, electric cars accounted for around a third of all vehicles on the road. They fell out of favor when gasoline became affordable and the electric starter motor eliminated the difficult hand-cranking of gasoline engines.",
    searchMapping: {
      key: 'electric car',
      phrase: '"electric car history"',
      required: ['electric', 'vehicle', 'history'],
      excluded: []
    }
  },
  {
    question: "The first alarm clock could only ring at 4 AM",
    answer: true,
    category: "technology",
    explanation: "The first mechanical alarm clock, invented by American Levi Hutchins in 1787, could only ring at 4 AM—the time he needed to wake for work. Unlike modern alarms, it wasn't adjustable because Hutchins designed it solely for his personal use. The first patent for an adjustable alarm clock wasn't issued until 1847, when French inventor Antoine Redier created a clock that allowed users to set their preferred wake-up time.",
    searchMapping: {
      key: 'alarm clock',
      phrase: '"first alarm clock"',
      required: ['alarm', 'clock', 'history'],
      excluded: []
    }
  },
  {
    question: "The first YouTube video was uploaded in 2005",
    answer: true,
    category: "technology",
    explanation: "The first video on YouTube, titled 'Me at the zoo,' was uploaded on April 23, 2005, by co-founder Jawed Karim. The 18-second clip shows Karim at the San Diego Zoo discussing elephants' trunks. This modest video launched what would become one of the world's largest platforms, with over 500 hours of content now uploaded every minute.",
    searchMapping: {
      key: 'YouTube',
      phrase: '"first YouTube video"',
      required: ['YouTube', 'first', 'video'],
      excluded: []
    }
  },
  {
    question: "Smartphones have more computing power than NASA used for the moon landing",
    answer: true,
    category: "technology",
    explanation: "The Apollo 11 mission's guidance computer operated at 0.043 MHz with 64 kilobytes of memory. Modern smartphones typically run at 2-3 GHz (about 100,000 times faster) with 4-12 GB of RAM (about 100,000 times more memory). This means your smartphone isn't just slightly more powerful—it's orders of magnitude more capable than the technology that successfully landed humans on the moon.",
    searchMapping: {
      key: 'smartphone',
      phrase: '"smartphone NASA comparison"',
      required: ['smartphone', 'NASA', 'moon'],
      excluded: []
    }
  },
  {
    question: "The Firefox logo is actually a red panda, not a fox",
    answer: true,
    category: "technology",
    explanation: "The Firefox browser was named after the red panda, not the fox. 'Firefox' is actually an alternative name for the red panda (Ailurus fulgens). While the current stylized logo resembles a fox circling the globe, it was originally inspired by red pandas. The name was chosen after the browser's original name 'Phoenix' faced trademark issues.",
    searchMapping: {
      key: 'Firefox',
      phrase: '"Firefox logo meaning"',
      required: ['Firefox', 'logo', 'meaning'],
      excluded: []
    }
  },
  {
    question: "The first message sent over the internet was 'hello'",
    answer: false,
    category: "technology",
    explanation: "The first message sent over ARPANET (the precursor to the internet) on October 29, 1969, was intended to be 'LOGIN' but the system crashed after sending just 'LO'. The message was sent from UCLA to Stanford Research Institute, marking the first host-to-host communication of what would eventually become the global internet.",
    searchMapping: {
      key: 'internet',
      phrase: '"first internet message"',
      required: ['internet', 'first', 'message'],
      excluded: []
    }
  },
  {
    question: "The sound of a dial-up modem is actually a language",
    answer: true,
    category: "technology",
    explanation: "The distinctive sounds made by dial-up modems are actually a form of communication protocol, often called 'handshaking.' Different tones represent specific stages of negotiation between modems, including identification, connection speed capabilities, error correction protocols, and successful connection confirmation. This audio 'language' allowed modems to establish optimal connection parameters before data transmission began.",
    searchMapping: {
      key: 'modem',
      phrase: '"dial-up modem sound"',
      required: ['modem', 'sound', 'dial-up'],
      excluded: []
    }
  },
  {
    question: "The hashtag symbol was originally called an 'octothorpe'",
    answer: true,
    category: "technology",
    explanation: "The # symbol, now commonly known as a hashtag, was originally called an 'octothorpe' by engineers at Bell Laboratories in the 1960s when it was added to telephone keypads. The name combines 'octo' (referring to the eight points) with 'thorpe' (possibly named after Olympic athlete Jim Thorpe or simply a nonsense suffix). The symbol has also been called the pound sign, number sign, and sharp in different contexts.",
    searchMapping: {
      key: 'hashtag',
      phrase: '"hashtag original name"',
      required: ['hashtag', 'octothorpe', 'symbol'],
      excluded: []
    }
  },
  {
    question: "The inventor of the pop-up ad has apologized for creating it",
    answer: true,
    category: "technology",
    explanation: "Ethan Zuckerman, who created the first pop-up advertisement in the late 1990s while working at Tripod.com, has publicly apologized for his invention. In a 2014 essay, he called it 'one of the most hated tools in the advertiser's toolkit' and expressed regret for the role it played in creating the advertising-based revenue model that dominates the internet today.",
    searchMapping: {
      key: 'pop-up',
      phrase: '"pop-up ad inventor"',
      required: ['pop-up', 'ad', 'inventor'],
      excluded: []
    }
  },
  {
    question: "Video games can help surgeons perform better",
    answer: true,
    category: "technology",
    explanation: "Multiple studies have found that surgeons who play video games regularly make fewer errors and work more efficiently in laparoscopic and robotic surgical procedures. One study from Beth Israel Medical Center found that doctors who played video games for more than three hours per week made 37% fewer errors and performed tasks 27% faster in simulated surgery tests than non-gaming colleagues.",
    searchMapping: {
      key: 'games',
      phrase: '"video games surgeons"',
      required: ['games', 'surgeons', 'skills'],
      excluded: []
    }
  },
  {
    question: "The average person touches their phone 2,617 times a day",
    answer: true,
    category: "technology",
    explanation: "Research by dscout found that the average smartphone user touches their phone 2,617 times daily, with heavy users exceeding 5,400 touches per day. These interactions include taps, swipes, and clicks across apps, messaging, and scrolling. The study tracked 94 Android users over 5 days, with specialized software logging each micro-interaction, revealing our intense physical relationship with these devices.",
    searchMapping: {
      key: 'phone',
      phrase: '"smartphone touches"',
      required: ['phone', 'touch', 'day'],
      excluded: []
    }
  },
  {
    question: "The @ symbol was in danger of disappearing before email was invented",
    answer: true,
    category: "technology",
    explanation: "Before email adoption in the 1970s, the @ symbol was so rarely used that it was actually in danger of being removed from typewriter keyboards and early computer character sets. The symbol dates back to the 16th century for commercial accounting, but had fallen into obscurity until Ray Tomlinson chose it in 1971 to separate usernames from host names in email addresses, giving it new life and eventual cultural prominence.",
    searchMapping: {
      key: 'symbol',
      phrase: '"at symbol history"',
      required: ['@', 'symbol', 'history'],
      excluded: []
    }
  },
  {
    question: "The original name for Bluetooth technology was 'RadioWire'",
    answer: false,
    category: "technology",
    explanation: "Bluetooth wasn't originally called 'RadioWire.' It was actually codenamed 'MCLink' during early development. The name 'Bluetooth' was adopted later as a reference to King Harald 'Bluetooth' Gormsson, a 10th-century Danish king who united dissonant parts of Denmark and Norway—similar to how the technology unites different devices. The distinctive Bluetooth logo combines the Nordic runes for Harald's initials.",
    searchMapping: {
      key: 'Bluetooth',
      phrase: '"Bluetooth name origin"',
      required: ['Bluetooth', 'name', 'origin'],
      excluded: []
    }
  },
  {
    question: "The first iPhone didn't have copy and paste functionality",
    answer: true,
    category: "technology",
    explanation: "Despite revolutionizing smartphones, the original iPhone released in 2007 lacked copy and paste functionality. This basic feature wasn't added until the release of iPhone OS 3.0 in 2009, almost two years after the original iPhone launch. Apple focused first on core functionality and user interface, adding this now-essential feature only after perfecting other aspects of the experience.",
    searchMapping: {
      key: 'iPhone',
      phrase: '"first iPhone features"',
      required: ['iPhone', 'copy', 'paste'],
      excluded: []
    }
  },
  {
    question: "Every Google search uses the same energy as a 60-watt light bulb for 17 seconds",
    answer: true,
    category: "technology",
    explanation: "Google has disclosed that a typical search query requires about 0.0003 kWh of energy, equivalent to running a 60-watt light bulb for approximately 17 seconds. This includes the energy used by your device, network infrastructure, and Google's data centers. While seemingly small, with billions of searches daily, the cumulative energy usage is significant, prompting Google's investments in renewable energy for their operations.",
    searchMapping: {
      key: 'Google',
      phrase: '"Google search energy"',
      required: ['Google', 'search', 'energy'],
      excluded: []
    }
  },
  {
    question: "Virtual reality was first invented in the 1960s",
    answer: true,
    category: "technology",
    explanation: "The first VR headset, called the 'Sword of Damocles,' was invented in 1968 by computer scientist Ivan Sutherland. This pioneering device was so heavy it had to be suspended from the ceiling (hence the name), but it successfully displayed simple wireframe rooms that changed perspective as the user moved their head. While primitive by today's standards, it established the fundamental principles that modern VR still uses.",
    searchMapping: {
      key: 'VR',
      phrase: '"virtual reality history"',
      required: ['virtual', 'reality', 'invention'],
      excluded: []
    }
  },
  {
    question: "Digital cameras were invented by Kodak, who then shelved the technology",
    answer: true,
    category: "technology",
    explanation: "The first digital camera was invented in 1975 by Kodak engineer Steven Sasson. His prototype captured black-and-white images at 0.01 megapixels, storing them on cassette tape. Kodak's management largely shelved the technology, fearing it would threaten their profitable film business. This decision ironically contributed to Kodak's eventual bankruptcy in 2012, as digital photography revolutionized the industry they once dominated.",
    searchMapping: {
      key: 'camera',
      phrase: '"digital camera invention"',
      required: ['digital', 'camera', 'Kodak'],
      excluded: []
    }
  },
  {
    question: "Sending a tweet consumes more electricity than refrigerating a beer for an hour",
    answer: false,
    category: "technology",
    explanation: "According to energy research, sending a tweet consumes approximately 0.02 watt-hours of electricity when accounting for device power, network infrastructure, and data center energy usage. In contrast, refrigerating a beer for an hour typically requires about 5-15 watt-hours depending on refrigerator efficiency. While all digital activities have an energy footprint, social media actions require significantly less power than most physical appliances.",
    searchMapping: {
      key: 'tweet',
      phrase: '"tweet energy consumption"',
      required: ['tweet', 'energy', 'electricity'],
      excluded: []
    }
  }
]; 