// Questions about environment
export const environment = [
  {
    question: "There is more water than land on Earth",
    answer: true,
    category: "environment",
    explanation: "Approximately 71% of Earth's surface is covered by water, with oceans holding about 96.5% of all Earth's water. The remaining 29% is land, including continents and islands.",
    searchMapping: {
      key: 'water land',
      phrase: '"water land ratio"',
      required: ['water', 'land', 'earth'],
      excluded: []
    }
  },
  {
    question: "The ozone layer absorbs harmful UV radiation",
    answer: true, 
    category: "environment",
    explanation: "The ozone layer in Earth's stratosphere absorbs about 97-99% of the Sun's medium-frequency ultraviolet light, which would otherwise be harmful to most forms of life on Earth. It acts as a protective shield for our planet.",
    searchMapping: {
      key: 'ozone layer',
      phrase: '"ozone protection"',
      required: ['atmosphere', 'radiation'],
      excluded: []
    }
  },
  {
    question: "Recycling paper helps save trees",
    answer: true,
    category: "environment",
    explanation: "Recycling one ton of paper saves approximately 17 trees, 7,000 gallons of water, 380 gallons of oil, and 3.3 cubic yards of landfill space. Paper recycling reduces the need for new timber and the energy required for production.",
    searchMapping: {
      key: 'recycling paper',
      phrase: '"recycled paper"',
      required: ['recycling', 'paper', 'trees'],
      excluded: []
    }
  },
  {
    question: "The Amazon rainforest produces 20% of Earth's oxygen",
    answer: true,
    category: "environment",
    explanation: "The Amazon rainforest, often called 'Earth's lungs,' produces approximately 20% of the world's oxygen through photosynthesis. It contains about 390 billion trees and countless other plants that convert carbon dioxide to oxygen, playing a crucial role in our planet's climate regulation.",
    searchMapping: {
      key: 'amazon',
      phrase: '"amazon oxygen"',
      required: ['amazon', 'rainforest', 'oxygen'],
      excluded: []
    }
  },
  {
    question: "Deserts are always hot",
    answer: false,
    category: "environment",
    explanation: "Deserts are defined by their low precipitation (less than 10 inches annually), not by temperature. While some deserts like the Sahara are hot, others like the Gobi Desert in Asia or Antarctica (a polar desert) can experience extremely cold temperatures, especially at night.",
    searchMapping: {
      key: 'desert',
      phrase: '"desert heat"',
      required: ['desert', 'heat'],
      excluded: ['cold']
    }
  },
  {
    question: "Plants need sunlight to grow",
    answer: true,
    category: "environment",
    explanation: "Plants use sunlight to create their food through a process called photosynthesis. Sunlight provides the energy plants need to convert water and carbon dioxide into glucose (sugar) and oxygen. Without sunlight, most plants cannot grow properly or survive.",
    searchMapping: {
      key: 'plants sunlight',
      phrase: '"plants need sunlight"',
      required: ['plants', 'sunlight', 'grow'],
      excluded: []
    }
  },
  {
    question: "Plastic takes 1,000 years to decompose in landfills",
    answer: true,
    category: "environment",
    explanation: "Most plastic items take between 500-1,000 years to break down in landfills. Plastic bottles can take 450 years, while plastic bags may take up to 1,000 years to decompose. During this time, plastics can release harmful chemicals into soil and water.",
    searchMapping: {
      key: 'plastic decompose',
      phrase: '"plastic decomposition time"',
      required: ['plastic', 'decompose', 'landfill'],
      excluded: []
    }
  },
  {
    question: "Wind turbines kill more birds than cats do",
    answer: false,
    category: "environment",
    explanation: "While wind turbines do cause some bird fatalities (estimated 234,000 per year in the US), domestic and feral cats kill billions of birds annually (estimated 2.4 billion in the US alone). The impact of wind turbines on bird populations is significantly smaller than that of cats.",
    searchMapping: {
      key: 'wind turbines birds',
      phrase: '"wind turbine bird deaths"',
      required: ['wind', 'turbine', 'birds'],
      excluded: []
    }
  },
  {
    question: "Electric cars produce zero emissions",
    answer: false,
    category: "environment",
    explanation: "While electric vehicles produce no tailpipe emissions during operation, they do cause emissions through their manufacturing process and the generation of electricity used to charge them. The overall environmental impact depends on how the electricity is produced - renewable sources create fewer emissions than fossil fuels.",
    searchMapping: {
      key: 'electric cars',
      phrase: '"electric car emissions"',
      required: ['electric', 'car', 'emissions'],
      excluded: []
    }
  },
  {
    question: "All trash disappears once it's thrown away",
    answer: false,
    category: "environment",
    explanation: "When we throw trash 'away,' it doesn't disappear. It goes to landfills where many items take years or even centuries to break down. Some trash, especially plastics, can pollute the environment for hundreds of years. That's why reducing waste and recycling are important actions to help our planet.",
    searchMapping: {
      key: 'trash disappear',
      phrase: '"where does trash go"',
      required: ['trash', 'landfill', 'waste'],
      excluded: []
    }
  },
  {
    question: "Turning off lights when leaving a room helps the planet",
    answer: true,
    category: "environment",
    explanation: "Turning off lights when not in use saves energy. When we use less electricity, power plants don't need to produce as much energy, which means they burn less fuel and create less pollution. This simple action helps reduce greenhouse gas emissions and conserves natural resources.",
    searchMapping: {
      key: 'lights off',
      phrase: '"turning off lights"',
      required: ['lights', 'energy', 'save'],
      excluded: []
    }
  },
  {
    question: "The hole in the ozone layer is completely healed",
    answer: false,
    category: "environment",
    explanation: "The ozone hole is healing but is not completely closed. Thanks to the 1987 Montreal Protocol, which banned ozone-depleting chemicals like CFCs, the ozone layer is slowly recovering. Scientists predict it may fully heal by around 2060-2080, but it's still depleted, especially over Antarctica.",
    searchMapping: {
      key: 'ozone hole',
      phrase: '"ozone hole healing"',
      required: ['ozone', 'hole', 'heal'],
      excluded: []
    }
  },
  {
    question: "All types of radiation are harmful to humans",
    answer: false,
    category: "environment",
    explanation: "Many forms of radiation are harmless or even beneficial to humans. Non-ionizing radiation (like radio waves, visible light, and infrared) generally poses little health risk. We're constantly exposed to background radiation from natural sources. Only certain types, particularly high-energy ionizing radiation, are harmful in significant doses.",
    searchMapping: {
      key: 'radiation harm',
      phrase: '"radiation harmful"',
      required: ['radiation', 'human', 'harmful'],
      excluded: []
    }
  },
  {
    question: "Antarctica is gaining more ice than it's losing",
    answer: false,
    category: "environment",
    explanation: "While some parts of Antarctica experience increased snowfall, overall the continent is losing ice mass. Research shows Antarctica is losing about 150 billion tons of ice annually, primarily due to warming ocean waters melting ice shelves from below and accelerating glacier flow into the sea.",
    searchMapping: {
      key: 'antarctica ice',
      phrase: '"antarctica ice loss"',
      required: ['antarctica', 'ice', 'melting'],
      excluded: []
    }
  },
  {
    question: "Recycling a single aluminum can saves enough energy to power a TV for three hours",
    answer: true,
    category: "environment",
    explanation: "Recycling aluminum is highly energy-efficient, saving about 95% of the energy required to produce new aluminum from raw materials. The energy saved by recycling one aluminum can is indeed enough to power a television for about three hours, making aluminum recycling one of the most effective forms of resource conservation.",
    searchMapping: {
      key: 'aluminum recycling',
      phrase: '"aluminum can recycling"',
      required: ['aluminum', 'recycling', 'energy'],
      excluded: []
    }
  },
  {
    question: "Coral reefs cover more than 50% of the ocean floor",
    answer: false,
    category: "environment",
    explanation: "Coral reefs occupy less than 1% of the ocean floor, despite their outsized ecological importance. These diverse ecosystems host approximately 25% of all marine species while covering only a tiny fraction of the ocean. Most reefs are found in shallow, tropical waters where conditions are ideal for coral growth.",
    searchMapping: {
      key: 'coral reefs',
      phrase: '"coral reef coverage"',
      required: ['coral', 'reef', 'ocean'],
      excluded: []
    }
  },
  {
    question: "Composting food waste can reduce landfill methane emissions",
    answer: true,
    category: "environment",
    explanation: "When food waste decomposes in landfills without oxygen, it produces methane, a potent greenhouse gas 25 times more effective at trapping heat than carbon dioxide. Composting food waste in aerobic (oxygen-rich) conditions prevents methane formation, instead producing nutrient-rich soil amendment that benefits gardens and farms.",
    searchMapping: {
      key: 'composting',
      phrase: '"composting methane"',
      required: ['compost', 'methane', 'food'],
      excluded: []
    }
  },
  {
    question: "Nuclear power plants release more radiation than coal plants",
    answer: false,
    category: "environment",
    explanation: "Surprisingly, coal plants release more radiation into the environment than properly functioning nuclear plants. Coal contains trace amounts of naturally occurring radioactive materials that are released when coal is burned. While nuclear power has other environmental considerations, routine radiation emissions from nuclear plants are tightly controlled and minimal.",
    searchMapping: {
      key: 'nuclear coal radiation',
      phrase: '"nuclear coal radiation comparison"',
      required: ['nuclear', 'coal', 'radiation'],
      excluded: []
    }
  },
  {
    question: "Most of Earth's freshwater is in rivers and lakes",
    answer: false,
    category: "environment",
    explanation: "Only about 0.3% of Earth's freshwater is found in rivers and lakes. Approximately 69% of freshwater is locked in ice caps and glaciers, while 30% exists as groundwater. Surface freshwater in rivers and lakes, though vital to ecosystems and human societies, represents just a tiny fraction of Earth's total freshwater resources.",
    searchMapping: {
      key: 'freshwater',
      phrase: '"freshwater distribution"',
      required: ['freshwater', 'earth', 'location'],
      excluded: []
    }
  },
  {
    question: "Riding a bicycle helps keep the air cleaner than driving a car",
    answer: true,
    category: "environment",
    explanation: "Riding a bicycle produces no air pollution, unlike cars which emit greenhouse gases and other pollutants from burning fuel. When more people choose to ride bikes instead of driving, it helps reduce air pollution, making the air cleaner and healthier for everyone to breathe. Bicycles are a clean transportation option that's good for people and the planet.",
    searchMapping: {
      key: 'bicycle car',
      phrase: '"bicycle vs car pollution"',
      required: ['bicycle', 'car', 'air'],
      excluded: []
    }
  },
  {
    question: "Littering is good for animals",
    answer: false,
    category: "environment",
    explanation: "Littering is harmful to animals. When trash is left on the ground, in parks, or at beaches, animals can mistake it for food and get sick by eating it. Animals can also get tangled in trash like plastic rings or fishing line. Properly throwing away or recycling trash keeps wildlife safe and their habitats clean.",
    searchMapping: {
      key: 'littering animals',
      phrase: '"litter harm wildlife"',
      required: ['litter', 'animal', 'harm'],
      excluded: []
    }
  },
  {
    question: "Sea levels are rising at the same rate everywhere in the world",
    answer: false,
    category: "environment",
    explanation: "Sea level rise varies significantly around the world due to factors including ocean currents, gravitational effects from ice sheet changes, land rising or sinking (vertical land motion), and regional differences in ocean warming. Some coastal regions experience sea level rise three times faster than the global average, while others see slower rates.",
    searchMapping: {
      key: 'sea level rise',
      phrase: '"regional sea level rise"',
      required: ['sea', 'level', 'rise'],
      excluded: []
    }
  },
  {
    question: "Trees release carbon dioxide at night",
    answer: true,
    category: "environment",
    explanation: "During the day, trees perform photosynthesis, taking in carbon dioxide and releasing oxygen. At night, when photosynthesis stops, trees continue to respire, absorbing oxygen and releasing carbon dioxide. However, over a 24-hour period, trees absorb far more carbon dioxide than they release, making them net carbon sinks.",
    searchMapping: {
      key: 'trees night',
      phrase: '"trees release CO2"',
      required: ['tree', 'night', 'carbon'],
      excluded: []
    }
  },
  {
    question: "Solar panels work efficiently even on cloudy days",
    answer: true,
    category: "environment",
    explanation: "Modern solar panels can still generate electricity on cloudy days, typically operating at 10-25% of their rated capacity. Although direct sunlight produces optimal output, solar panels respond to all visible light, not just direct sunshine. In countries like Germany, which has relatively cloudy weather, solar power remains a significant energy source.",
    searchMapping: {
      key: 'solar panels clouds',
      phrase: '"solar panels cloudy"',
      required: ['solar', 'panel', 'cloud'],
      excluded: []
    }
  },
  {
    question: "The Arctic could be ice-free in summer within this century",
    answer: true,
    category: "environment",
    explanation: "Climate models consistently project that the Arctic Ocean could experience ice-free summers before the end of this century, with many studies suggesting this could happen as early as 2040-2050. Arctic sea ice has already declined by about 40% in extent and 70% in volume since satellite monitoring began in 1979.",
    searchMapping: {
      key: 'arctic ice',
      phrase: '"arctic ice-free"',
      required: ['arctic', 'ice', 'summer'],
      excluded: []
    }
  },
  {
    question: "Biodegradable plastics break down completely in the ocean",
    answer: false,
    category: "environment",
    explanation: "Most biodegradable plastics require specific industrial composting conditions (high temperature and humidity) to properly decompose. In ocean environments, they often break down too slowly to prevent environmental harm, or fragment into microplastics. Some may never fully decompose in marine environments and can still harm wildlife.",
    searchMapping: {
      key: 'biodegradable plastic',
      phrase: '"biodegradable plastics ocean"',
      required: ['biodegradable', 'plastic', 'ocean'],
      excluded: []
    }
  },
  {
    question: "Acid rain is no longer an environmental problem",
    answer: false,
    category: "environment",
    explanation: "While acid rain has decreased significantly in North America and Europe due to clean air legislation reducing sulfur dioxide and nitrogen oxide emissions, it remains a serious problem in parts of Asia, particularly China and India. In these regions, coal burning without adequate pollution controls continues to cause acid rain that damages ecosystems, buildings, and human health.",
    searchMapping: {
      key: 'acid rain',
      phrase: '"acid rain current"',
      required: ['acid', 'rain', 'pollution'],
      excluded: []
    }
  },
  {
    question: "Rain helps plants grow",
    answer: true,
    category: "environment",
    explanation: "Rain provides water that plants need to grow. When it rains, plants absorb water through their roots. This water helps move nutrients from the soil into the plant, and is used for photosynthesis—making food from sunlight. Rain also helps clean plant leaves and keeps the soil moist, which is important for healthy plant growth.",
    searchMapping: {
      key: 'rain plants',
      phrase: '"rain plant growth"',
      required: ['rain', 'plant', 'grow'],
      excluded: []
    }
  },
  {
    question: "Wind turbines spin to make electricity from the wind",
    answer: true,
    category: "environment",
    explanation: "Wind turbines convert the wind's kinetic energy into electricity. When the wind blows, it causes the large blades to rotate. This spinning motion turns a generator inside the turbine, creating electricity. This clean energy source doesn't create air pollution or greenhouse gases, making it a renewable alternative to burning fossil fuels.",
    searchMapping: {
      key: 'wind turbine',
      phrase: '"how wind turbines work"',
      required: ['wind', 'turbine', 'electricity'],
      excluded: []
    }
  },
  {
    question: "Plant-based diets always have a lower carbon footprint than meat-based diets",
    answer: true,
    category: "environment",
    explanation: "Plant-based diets consistently produce lower greenhouse gas emissions than meat-based diets. Livestock farming, particularly beef and dairy, generates significant methane emissions and requires large amounts of land, water, and feed. Even accounting for transportation, most plant foods have a carbon footprint 10-50 times smaller than animal products.",
    searchMapping: {
      key: 'diet carbon',
      phrase: '"plant based diet emissions"',
      required: ['diet', 'carbon', 'plant'],
      excluded: []
    }
  },
  {
    question: "Renewable energy is more expensive than fossil fuels",
    answer: false,
    category: "environment",
    explanation: "Renewable energy costs have fallen dramatically, with solar and wind now often cheaper than coal and natural gas in many regions. According to the International Renewable Energy Agency, over 60% of new renewable capacity installed in 2020 had lower costs than the cheapest new fossil fuel option. The price trend continues to favor renewables.",
    searchMapping: {
      key: 'renewable cost',
      phrase: '"renewable energy cost"',
      required: ['renewable', 'cost', 'fossil'],
      excluded: []
    }
  },
  {
    question: "Noise pollution can harm wildlife and ecosystems",
    answer: true,
    category: "environment",
    explanation: "Human-generated noise disrupts wildlife in numerous ways, including interfering with animal communication, altering predator-prey relationships, decreasing reproductive success, and causing physiological stress. Marine mammals, birds, and many other species are particularly affected by noise from shipping, traffic, industrial activity, and urban development.",
    searchMapping: {
      key: 'noise wildlife',
      phrase: '"noise pollution wildlife"',
      required: ['noise', 'wildlife', 'harm'],
      excluded: []
    }
  },
  {
    question: "Saving endangered species is purely for aesthetic reasons",
    answer: false,
    category: "environment",
    explanation: "Protecting endangered species is important for maintaining biodiversity, which provides essential ecosystem services humans depend on, including clean air and water, pollination, pest control, and medical discoveries. Each species plays a role in its ecosystem, and their loss can trigger cascading effects that destabilize natural systems.",
    searchMapping: {
      key: 'endangered species',
      phrase: '"importance endangered species"',
      required: ['endangered', 'species', 'importance'],
      excluded: []
    }
  },
  {
    question: "All plastic waste can be recycled",
    answer: false,
    category: "environment",
    explanation: "Not all plastics can be recycled economically or technically. While common plastics like PET (code #1) and HDPE (code #2) are widely recyclable, many others like thin films, mixed plastic products, and contaminated plastics often end up in landfills. Additionally, each time plastic is recycled, its quality degrades, limiting the number of times it can be reprocessed.",
    searchMapping: {
      key: 'plastic recycling',
      phrase: '"plastic recycling limitations"',
      required: ['plastic', 'recycle', 'waste'],
      excluded: []
    }
  },
  {
    question: "Worms help make soil healthy for plants",
    answer: true,
    category: "environment",
    explanation: "Earthworms improve soil health in many ways. As they move through soil, they create tunnels that allow air, water, and plant roots to travel more easily. Worms break down dead plant material into nutrients that plants can use. Their castings (worm poop) are rich in nutrients and good bacteria that help plants grow strong and healthy.",
    searchMapping: {
      key: 'worms soil',
      phrase: '"earthworms soil health"',
      required: ['worm', 'soil', 'healthy'],
      excluded: []
    }
  },
  {
    question: "Light pollution only affects astronomers' ability to view stars",
    answer: false,
    category: "environment",
    explanation: "Light pollution impacts much more than astronomy. It disrupts wildlife behavior, including bird migration, sea turtle nesting, and nocturnal animal activity. It also affects human health by disrupting circadian rhythms, which can lead to sleep disorders, increased risk of certain cancers, and other health issues. Light pollution also wastes energy and money.",
    searchMapping: {
      key: 'light pollution',
      phrase: '"light pollution effects"',
      required: ['light', 'pollution', 'impact'],
      excluded: []
    }
  },
  {
    question: "Oxygen levels in the ocean are decreasing",
    answer: true,
    category: "environment",
    explanation: "Ocean deoxygenation is occurring globally, with oxygen levels decreasing by about 2% since the 1950s. This is caused by warming ocean waters (warm water holds less dissolved oxygen) and nutrient pollution that leads to algal blooms and subsequent oxygen depletion. This trend threatens marine life and has expanded the size of oceanic 'dead zones.'",
    searchMapping: {
      key: 'ocean oxygen',
      phrase: '"ocean deoxygenation"',
      required: ['ocean', 'oxygen', 'decline'],
      excluded: []
    }
  },
  {
    question: "Soil can store more carbon than the atmosphere and all plants combined",
    answer: true,
    category: "environment",
    explanation: "Soil contains approximately 2,500 gigatons of carbon, more than the atmosphere (800 gigatons) and all plants (560 gigatons) combined. This makes soil the largest terrestrial carbon repository. Sustainable agricultural practices that build soil health can increase this carbon storage capacity, helping mitigate climate change.",
    searchMapping: {
      key: 'soil carbon',
      phrase: '"soil carbon storage"',
      required: ['soil', 'carbon', 'storage'],
      excluded: []
    }
  },
  {
    question: "Urban areas are always hotter than surrounding rural areas",
    answer: true,
    category: "environment",
    explanation: "The urban heat island effect causes cities to be warmer than surrounding rural areas, typically by 1-7°C (2-12°F). This temperature difference is caused by dark surfaces like asphalt and concrete that absorb heat, reduced vegetation, waste heat from buildings and vehicles, and urban geometry that traps heat. The effect is strongest during evenings and in dense city centers.",
    searchMapping: {
      key: 'urban heat',
      phrase: '"urban heat island"',
      required: ['urban', 'heat', 'rural'],
      excluded: []
    }
  },
  {
    question: "Electric vehicles have a larger carbon footprint than gas-powered cars",
    answer: false,
    category: "environment",
    explanation: "While electric vehicle production typically generates more emissions than conventional car manufacturing (primarily due to battery production), their lifetime carbon footprint is lower. Over their lifespan, EVs produce significantly fewer emissions, especially when charged with renewable electricity. This advantage continues to grow as electricity grids become cleaner.",
    searchMapping: {
      key: 'electric vehicle',
      phrase: '"electric car carbon footprint"',
      required: ['electric', 'vehicle', 'carbon'],
      excluded: []
    }
  },
  {
    question: "Microplastics have been found in human blood",
    answer: true,
    category: "environment",
    explanation: "A 2022 study published in Environment International detected microplastic particles in human blood samples for the first time. Researchers found particles from various common plastics in 80% of people tested. While the full health implications remain unclear, this discovery raises concerns about potential impacts on human health.",
    searchMapping: {
      key: 'microplastics blood',
      phrase: '"microplastics human blood"',
      required: ['microplastic', 'human', 'blood'],
      excluded: []
    }
  },
  {
    question: "Offshore wind farms harm marine ecosystems",
    answer: false,
    category: "environment",
    explanation: "Rather than harming marine ecosystems, offshore wind farm foundations typically function as artificial reefs, increasing marine biodiversity. Studies show increased numbers of fish, crabs, and lobsters around wind farm structures. While there are some impacts during construction, operational wind farms often create protected areas where fishing is limited, allowing marine life to thrive.",
    searchMapping: {
      key: 'offshore wind',
      phrase: '"offshore wind farm ecology"',
      required: ['offshore', 'wind', 'marine'],
      excluded: []
    }
  },
  {
    question: "Wildfires are always harmful to forest ecosystems",
    answer: false,
    category: "environment",
    explanation: "Many forest ecosystems evolved with and depend on periodic fires for ecological health. Natural fires clear undergrowth, return nutrients to soil, promote germination of fire-adapted species, and create habitat diversity. Problems arise when fires become too frequent or intense due to climate change, fire suppression policies, or human activities.",
    searchMapping: {
      key: 'wildfires forests',
      phrase: '"beneficial forest fires"',
      required: ['wildfire', 'forest', 'ecosystem'],
      excluded: []
    }
  },
  {
    question: "All biofuels are better for the environment than fossil fuels",
    answer: false,
    category: "environment",
    explanation: "While biofuels can reduce greenhouse gas emissions compared to fossil fuels, their environmental impact varies widely. Some first-generation biofuels (like corn ethanol) may have high land use impacts, compete with food production, and sometimes generate only modest emissions savings. Advanced biofuels from agricultural waste or algae typically offer greater environmental benefits.",
    searchMapping: {
      key: 'biofuels environment',
      phrase: '"biofuel environmental impact"',
      required: ['biofuel', 'environmental', 'impact'],
      excluded: []
    }
  },
  {
    question: "Plants grow better with higher CO2 levels in the atmosphere",
    answer: false,
    category: "environment",
    explanation: "While increased CO2 can initially boost plant growth (the 'CO2 fertilization effect'), this benefit is often offset by other factors in climate change. Higher temperatures, altered precipitation patterns, increased pests, and nutrient limitations can negate growth benefits. Studies show that crop nutritional quality often decreases in high-CO2 environments.",
    searchMapping: {
      key: 'plants CO2',
      phrase: '"plant growth CO2"',
      required: ['plant', 'growth', 'CO2'],
      excluded: []
    }
  },
  {
    question: "Single-use paper bags are more environmentally friendly than plastic bags",
    answer: false,
    category: "environment",
    explanation: "The environmental impact comparison between paper and plastic bags is complex. Paper bags have a higher carbon footprint, require more energy and water to produce, and generate more air and water pollution in manufacturing. Plastic bags use fewer resources to make but persist longer in the environment. Reusable bags of any material are typically the better choice.",
    searchMapping: {
      key: 'paper plastic bags',
      phrase: '"paper vs plastic bags"',
      required: ['paper', 'plastic', 'bag'],
      excluded: []
    }
  },
  {
    question: "Rainforests are Earth's oldest ecosystems",
    answer: true,
    category: "environment",
    explanation: "Some tropical rainforests have existed continuously for 60-100 million years, making them the oldest living ecosystems on Earth. The Daintree Rainforest in Australia, for example, is estimated to be about 180 million years old, containing flowering plant species that date back to when dinosaurs roamed the planet.",
    searchMapping: {
      key: 'rainforest age',
      phrase: '"oldest rainforest"',
      required: ['rainforest', 'old', 'ancient'],
      excluded: []
    }
  },
  {
    question: "All endangered species are protected by international law",
    answer: false,
    category: "environment",
    explanation: "Not all endangered species receive international legal protection. While CITES (Convention on International Trade in Endangered Species) protects many threatened species from international commercial trade, it doesn't cover all endangered species. Additionally, implementation and enforcement vary by country, and species may be threatened by factors besides trade that aren't addressed by international agreements.",
    searchMapping: {
      key: 'endangered protection',
      phrase: '"endangered species protection"',
      required: ['endangered', 'species', 'protection'],
      excluded: []
    }
  },
  {
    question: "Using both sides of paper helps forests",
    answer: true,
    category: "environment",
    explanation: "Using both sides of paper (double-sided printing or writing) reduces paper consumption by up to 50%. This means fewer trees need to be cut down to make paper. When we use less paper, we also save water and energy used in paper production, and reduce pollution from paper manufacturing. It's a simple way to help protect forests.",
    searchMapping: {
      key: 'paper use',
      phrase: '"double sided paper"',
      required: ['paper', 'both', 'sides'],
      excluded: []
    }
  },
  {
    question: "Planting trees is always good for the environment",
    answer: false,
    category: "environment",
    explanation: "While planting trees is often helpful, it's not always good for every environment. Some ecosystems like grasslands and meadows naturally have few trees. Planting trees in the wrong places can disrupt local wildlife, reduce water availability in dry regions, or even release more carbon from soil than the trees absorb. It's important to plant the right trees in the right places.",
    searchMapping: {
      key: 'trees environment',
      phrase: '"tree planting environment"',
      required: ['tree', 'plant', 'environment'],
      excluded: []
    }
  }
]; 