// Questions about animals
export const animals = [
  {
    question: "Dolphins sleep with one half of their brain awake",
    answer: true,
    category: "animals",
    explanation: "Dolphins engage in unihemispheric slow-wave sleep, keeping one brain hemisphere alert while the other sleeps. This allows them to continue breathing, watch for predators, and maintain vital functions.",
    searchMapping: {
      key: 'dolphins sleep',
      phrase: '"dolphin pod"',
      required: ['underwater', 'marine', 'sleeping'],
      excluded: []
    }
  },
  {
    question: "Penguins live at the North Pole",
    answer: false,
    category: "animals",
    explanation: "Penguins live exclusively in the Southern Hemisphere, primarily in Antarctica and surrounding islands. There are no penguins at the North Pole.",
    searchMapping: {
      key: 'penguins live',
      phrase: '"penguin colony"',
      required: ['antarctica', 'south', 'pole'],
      excluded: ['north']
    }
  },
  {
    question: "Bats are blind",
    answer: false,
    category: "animals",
    explanation: "Contrary to popular belief, bats are not blind. All bats can see, and many species have excellent vision. They use echolocation alongside vision, rather than as a replacement for it.",
    searchMapping: {
      key: 'bat flying',
      phrase: '"bat colony"',
      required: ['nocturnal', 'flying'],
      excluded: []
    }
  },
  {
    question: "Butterflies taste food with their feet",
    answer: true,
    category: "animals",
    explanation: "Butterflies have taste receptors (chemoreceptors) on their feet, which they use to find food and suitable places to lay eggs by standing on leaves and other surfaces.",
    searchMapping: {
      key: 'butterflies taste',
      phrase: '"butterfly feet"',
      required: ['butterfly', 'taste', 'food'],
      excluded: []
    }
  },
  {
    question: "Cats can see clearly in complete darkness",
    answer: false,
    category: "animals",
    explanation: "While cats have excellent night vision, they still need some light to see. Their eyes have a higher concentration of rod cells and a reflective layer called the tapetum lucidum that enhances available light.",
    searchMapping: {
      key: 'cats see',
      phrase: '"cat eyes"',
      required: ['cat', 'see', 'dark'],
      excluded: ['light']
    }
  },
  {
    question: "All bears sleep through the entire winter",
    answer: false,
    category: "animals",
    explanation: "While some bears do hibernate during winter, not all bears do. Species like polar bears remain active year-round, and even hibernating bears like black bears can wake up and move around during winter.",
    searchMapping: {
      key: 'bears sleep',
      phrase: '"bear hibernation"',
      required: ['bear', 'sleep', 'winter'],
      excluded: ['hibernation']
    }
  },
  {
    question: "A giraffe's tongue is blue",
    answer: true,
    category: "animals",
    explanation: "Giraffes have blue-purple tongues that are 18-21 inches long. The dark color contains melanin, which protects their tongues from sun damage while they spend hours each day feeding on leaves.",
    searchMapping: {
      key: 'giraffe tongue',
      phrase: '"giraffe tongue color"',
      required: ['giraffe', 'tongue', 'blue'],
      excluded: []
    }
  },
  {
    question: "Rabbits love eating carrots more than any other food",
    answer: false,
    category: "animals",
    explanation: "Contrary to popular belief, rabbits naturally prefer grass, hay, and leafy greens. Carrots are high in sugar and should only be given as occasional treats. The carrot-loving rabbit myth was popularized by Bugs Bunny cartoons.",
    searchMapping: {
      key: 'rabbits eat',
      phrase: '"rabbit carrot"',
      required: ['rabbit', 'eat', 'carrot'],
      excluded: ['grass', 'hay']
    }
  },
  {
    question: "Octopuses have three hearts",
    answer: true,
    category: "animals",
    explanation: "Octopuses have three hearts: two branchial hearts that pump blood through the gills to oxygenate it, and one systemic heart that pumps the oxygenated blood to the rest of the body. This unique circulatory system helps them adapt to underwater life.",
    searchMapping: {
      key: 'octopuses',
      phrase: '"octopus heart"',
      required: ['octopus', 'heart', 'gills'],
      excluded: []
    }
  },
  {
    question: "Sloths take two weeks to digest their food",
    answer: true,
    category: "animals",
    explanation: "Sloths have the slowest digestive system of any mammal, taking up to two weeks to fully digest a meal. This extremely slow metabolism helps them conserve energy, which is crucial for their low-calorie diet of leaves, twigs, and sometimes fruits.",
    searchMapping: {
      key: 'sloths',
      phrase: '"sloth digestion"',
      required: ['sloth', 'digest', 'food'],
      excluded: []
    }
  },
  {
    question: "Hummingbirds can fly backwards",
    answer: true,
    category: "animals",
    explanation: "Hummingbirds are the only birds that can truly fly backwards. Their unique wing structure allows for incredible flight maneuverability, including hovering in place, flying upside down, and moving backwards with precision and control.",
    searchMapping: {
      key: 'hummingbirds',
      phrase: '"hummingbird fly"',
      required: ['hummingbird', 'fly', 'backwards'],
      excluded: []
    }
  },
  {
    question: "All birds can fly",
    answer: false,
    category: "animals",
    explanation: "Not all birds can fly. Flightless birds include ostriches, emus, penguins, kiwis, and several others. These birds evolved to live without flight, often developing other adaptations like powerful legs for running or wings modified for swimming.",
    searchMapping: {
      key: 'birds',
      phrase: '"bird fly"',
      required: ['bird', 'fly'],
      excluded: ['penguin', 'ostrich']
    }
  },
  {
    question: "Chameleons change color to match their surroundings",
    answer: false,
    category: "animals",
    explanation: "Contrary to popular belief, chameleons don't change color primarily to match their surroundings. Their color changes are mostly triggered by mood, temperature, health, and communication with other chameleons. The color shifts are caused by specialized cells containing pigments.",
    searchMapping: {
      key: 'chameleons',
      phrase: '"chameleon color"',
      required: ['chameleon', 'color', 'surroundings'],
      excluded: []
    }
  },
  {
    question: "An ostrich's eye is bigger than its brain",
    answer: true,
    category: "animals",
    explanation: "An ostrich's eye measures approximately 2 inches in diameter, making it larger than its brain. These large eyes help the ostrich spot predators from great distances in their open habitat environments.",
    searchMapping: {
      key: 'ostrich',
      phrase: '"ostrich eye"',
      required: ['ostrich', 'eye', 'brain'],
      excluded: []
    }
  },
  {
    question: "Kangaroos can't hop backwards",
    answer: true,
    category: "animals",
    explanation: "Kangaroos cannot hop backwards due to their unique anatomy. Their large feet and powerful tail are designed for forward momentum, making backward movement physically impossible. This fact is represented on Australia's coat of arms, symbolizing a nation that only moves forward.",
    searchMapping: {
      key: 'kangaroos',
      phrase: '"kangaroo hop"',
      required: ['kangaroo', 'hop', 'backwards'],
      excluded: []
    }
  },
  {
    question: "A snail can sleep for three years at a time",
    answer: true,
    category: "animals",
    explanation: "During unfavorable conditions like drought, some species of snails can enter a dormant state called estivation. In this state, they seal themselves inside their shells with mucus and can remain inactive for up to three years until conditions improve.",
    searchMapping: {
      key: 'snail',
      phrase: '"snail sleep"',
      required: ['snail', 'sleep', 'estivation'],
      excluded: []
    }
  },
  {
    question: "Hippos produce pink milk",
    answer: false,
    category: "animals",
    explanation: "Hippos produce white milk like other mammals, not pink milk. The myth of pink milk might have originated because hippos secrete a reddish oily substance from their skin that acts as sunscreen and moisturizer, but this is entirely separate from their milk.",
    searchMapping: {
      key: 'hippos',
      phrase: '"hippo milk"',
      required: ['hippo', 'milk', 'pink'],
      excluded: []
    }
  },
  {
    question: "Polar bears are left-handed",
    answer: false,
    category: "animals",
    explanation: "Polar bears are not predominantly left-handed. Studies show they use both paws equally and don't show a consistent preference for one paw over the other, unlike some other species that do demonstrate 'handedness.'",
    searchMapping: {
      key: 'polar bears',
      phrase: '"polar bear paw"',
      required: ['polar', 'bear', 'paw'],
      excluded: []
    }
  },
  {
    question: "A tiger's stripes are like human fingerprints",
    answer: true,
    category: "animals",
    explanation: "Every tiger has a unique pattern of stripes, similar to human fingerprints. No two tigers have the same stripe pattern, making it possible for researchers to identify individual tigers by their distinctive markings.",
    searchMapping: {
      key: 'tiger',
      phrase: '"tiger stripes"',
      required: ['tiger', 'stripes', 'fingerprint'],
      excluded: []
    }
  },
  {
    question: "Elephants are the only mammals that can't jump",
    answer: false,
    category: "animals",
    explanation: "While elephants cannot jump due to their weight and leg structure, they're not the only mammals with this limitation. Sloths, rhinos, and adult hippos also cannot jump. These large or slow-moving animals have evolved different survival strategies that don't require jumping.",
    searchMapping: {
      key: 'elephants',
      phrase: '"elephant jump"',
      required: ['elephant', 'jump'],
      excluded: ['sloth', 'hippo']
    }
  },
  {
    question: "Crocodiles cry real tears",
    answer: true,
    category: "animals",
    explanation: "Crocodiles do produce tears, but not from emotion. They secrete tears to clean and lubricate their eyes, particularly when they've been out of water for a long time. This physiological process is the origin of the phrase 'crocodile tears,' meaning insincere expressions of emotion.",
    searchMapping: {
      key: 'crocodile',
      phrase: '"crocodile cry"',
      required: ['crocodile', 'cry', 'tears'],
      excluded: []
    }
  },
  {
    question: "A group of flamingos is called a flamboyance",
    answer: true,
    category: "animals",
    explanation: "A group of flamingos is indeed called a 'flamboyance,' which perfectly reflects their vibrant pink color and showy appearance. Other collective terms for flamingos include 'colony,' 'stand,' and 'regiment.'",
    searchMapping: {
      key: 'flamingos',
      phrase: '"flamingo group"',
      required: ['flamingo', 'group', 'flamboyance'],
      excluded: []
    }
  },
  {
    question: "Dogs can look up at the sky",
    answer: true,
    category: "animals",
    explanation: "Despite the popular myth that dogs cannot look up, they absolutely can. While their neck structure might limit the extent compared to humans, dogs can and do look upward regularly. The myth was popularized by the movie 'Shaun of the Dead.'",
    searchMapping: {
      key: 'dogs look',
      phrase: '"dog sky"',
      required: ['dog', 'look', 'sky'],
      excluded: ['myth']
    }
  },
  {
    question: "Goldfish have a memory span of only three seconds",
    answer: false,
    category: "animals",
    explanation: "Contrary to popular belief, goldfish have a memory span of at least three months, not just three seconds. They can learn to recognize their owners, respond to certain sounds, and even be trained to perform simple tricks, demonstrating their ability to form long-term memories.",
    searchMapping: {
      key: 'goldfish',
      phrase: '"goldfish memory"',
      required: ['goldfish', 'memory', 'span'],
      excluded: []
    }
  },
  {
    question: "Wombats are the only animals that produce cube-shaped poop",
    answer: true,
    category: "animals",
    explanation: "Wombats are indeed the only known animals that produce cube-shaped feces. This unique shape is formed in the last 8% of their intestines, where the waste is compressed by alternating areas of elastic and stiff tissue. The cubic shape prevents the droppings from rolling away, helping wombats mark their territory more effectively.",
    searchMapping: {
      key: 'wombats',
      phrase: '"wombat poop"',
      required: ['wombat', 'poop'],
      excluded: []
    }
  },
  {
    question: "Penguins propose to their mates with pebbles",
    answer: true,
    category: "animals",
    explanation: "Male penguins search for the perfect pebble to give to their intended mates as part of their courtship ritual. The smoother and more unique the pebble, the more impressive the gift. These pebbles are also used to build nests, but the presentation of a special pebble is similar to offering an engagement ring.",
    searchMapping: {
      key: 'penguins',
      phrase: '"penguin pebble"',
      required: ['penguin', 'pebble'],
      excluded: []
    }
  },
  {
    question: "Squirrels can't remember where they hide most of their nuts",
    answer: true,
    category: "animals",
    explanation: "Gray squirrels can bury thousands of nuts each season, and they often forget where they've hidden them. They find their caches primarily by smell rather than memory. This forgetfulness is actually beneficial for forest ecosystems, as the unfound nuts often grow into new trees, helping with forest regeneration.",
    searchMapping: {
      key: 'squirrels',
      phrase: '"squirrel hide"',
      required: ['squirrel', 'hide'],
      excluded: []
    }
  },
  {
    question: "Jellyfish have survived longer than dinosaurs",
    answer: true,
    category: "animals",
    explanation: "Jellyfish have been on Earth for over 650 million years, predating dinosaurs by more than 400 million years and surviving all five mass extinction events. Their simple body structure and adaptability have allowed them to thrive across eons, making them one of the oldest surviving animal groups on the planet.",
    searchMapping: {
      key: 'jellyfish',
      phrase: '"jellyfish dinosaur"',
      required: ['jellyfish', 'dinosaur'],
      excluded: []
    }
  },
  {
    question: "Sloths can hold their breath for 40 minutes underwater",
    answer: true,
    category: "animals",
    explanation: "Sloths can slow their heart rate to one-third of its normal rate, which allows them to hold their breath underwater for up to 40 minutes. This ability helps them survive during the rainy season in tropical forests when areas flood, allowing them to swim across rivers and avoid predators.",
    searchMapping: {
      key: 'sloths',
      phrase: '"sloth breath"',
      required: ['sloth', 'breath'],
      excluded: []
    }
  },
  {
    question: "Koalas have fingerprints almost identical to humans",
    answer: true,
    category: "animals",
    explanation: "Koalas have fingerprints nearly indistinguishable from human fingerprints, even under microscopic examination. This is a remarkable example of convergent evolution, as koalas and humans evolved these similar traits independently. Their fingerprints are so similar that they have occasionally confused crime scene investigators.",
    searchMapping: {
      key: 'koalas',
      phrase: '"koala fingerprint"',
      required: ['koala', 'fingerprint'],
      excluded: []
    }
  },
  {
    question: "A group of pandas is called an embarrassment",
    answer: true,
    category: "animals",
    explanation: "A group of pandas is indeed called an 'embarrassment.' This collective noun was chosen because of pandas' playful, clumsy behavior and seemingly awkward movements. Other collective terms sometimes used for pandas include a 'bamboo' or a 'cupboard' of pandas.",
    searchMapping: {
      key: 'pandas',
      phrase: '"pandas embarrassment"',
      required: ['pandas', 'embarrassment'],
      excluded: []
    }
  },
  {
    question: "Cows have best friends and get stressed when separated",
    answer: true,
    category: "animals",
    explanation: "Studies show that cows form strong social bonds with other cows, essentially developing 'best friends.' When separated from these preferred partners, cows exhibit increased heart rates and stress hormone levels. They also display reduced milk production when separated from their bonded companions.",
    searchMapping: {
      key: 'cows',
      phrase: '"cow friends"',
      required: ['cow', 'friends'],
      excluded: []
    }
  },
  {
    question: "Dolphins give each other names",
    answer: true,
    category: "animals",
    explanation: "Dolphins develop unique signature whistles that function similarly to names. Each dolphin creates its own distinctive whistle pattern by its first year of life, and other dolphins use these specific whistles to call to individuals. This demonstrates their complex social structure and communication abilities.",
    searchMapping: {
      key: 'dolphins',
      phrase: '"dolphins names"',
      required: ['dolphins', 'names'],
      excluded: []
    }
  },
  {
    question: "Some jellyfish are immortal",
    answer: true,
    category: "animals",
    explanation: "The Turritopsis dohrnii jellyfish, often called the 'immortal jellyfish,' can revert to an earlier stage of its life cycle when facing environmental stress or physical damage. This ability to transform from an adult back to a polyp stage effectively resets its biological clock, allowing it to potentially live forever if not killed by predators or disease.",
    searchMapping: {
      key: 'jellyfish',
      phrase: '"jellyfish immortal"',
      required: ['jellyfish', 'immortal'],
      excluded: []
    }
  },
  {
    question: "Some ants can survive being underwater for two weeks",
    answer: true,
    category: "animals",
    explanation: "Fire ants can form living rafts by linking their bodies together, trapping air bubbles in the process. This allows them to survive floods and remain underwater for up to two weeks. The air pockets they create provide oxygen to the colony, showing remarkable adaptation to survive in flood-prone environments.",
    searchMapping: {
      key: 'ants',
      phrase: '"ants underwater"',
      required: ['ants', 'underwater'],
      excluded: []
    }
  },
  {
    question: "Some birds can remember thousands of hiding places for their food",
    answer: true,
    category: "animals",
    explanation: "Clark's Nutcrackers have extraordinary spatial memory, able to remember the locations of up to 10,000 seed caches they've hidden across their territory. They can retrieve these seeds months later, even when the landscape is covered in snow. This remarkable memory adaptation helps them survive harsh winters.",
    searchMapping: {
      key: 'birds',
      phrase: '"birds hiding"',
      required: ['birds', 'hiding'],
      excluded: []
    }
  },
  {
    question: "Some frogs can freeze solid and survive",
    answer: true,
    category: "animals",
    explanation: "Wood frogs can survive being frozen solid during winter. Their bodies produce a natural antifreeze compound (glucose and urea) that protects their vital organs while up to 65% of their body water turns to ice. Their hearts actually stop beating, and they resume normal functions when they thaw in spring.",
    searchMapping: {
      key: 'frogs',
      phrase: '"frogs freeze"',
      required: ['frogs', 'freeze'],
      excluded: []
    }
  },
  {
    question: "Otters hold hands while sleeping to avoid drifting apart",
    answer: true,
    category: "animals",
    explanation: "Sea otters often hold hands (or paws) when sleeping to prevent drifting away from each other in the water. They also wrap themselves in seaweed to anchor themselves during rest. This behavior, called 'rafting,' helps keep their social groups together and ensures they don't float away with the currents while sleeping.",
    searchMapping: {
      key: 'otters',
      phrase: '"otters holding hands"',
      required: ['otter', 'sleep', 'hands'],
      excluded: []
    }
  },
  {
    question: "Snails can sleep for up to three years at a time",
    answer: true,
    category: "animals",
    explanation: "Some species of snails can enter a hibernation-like state called estivation during unfavorable conditions such as drought. During this period, they seal their shells with a layer of mucus called an epiphragm, slowing their metabolism dramatically. In this state, they can remain dormant for up to three years until conditions improve.",
    searchMapping: {
      key: 'snails',
      phrase: '"snail hibernation"',
      required: ['snail', 'sleep', 'dormant'],
      excluded: []
    }
  },
  {
    question: "Cows have four stomachs",
    answer: false,
    category: "animals",
    explanation: "While commonly believed, cows don't actually have four stomachs. They have one stomach with four compartments: rumen, reticulum, omasum, and abomasum. This specialized digestive system allows them to efficiently break down plant material through a process called rumination, where food is regurgitated and rechewed.",
    searchMapping: {
      key: 'cows',
      phrase: '"cow stomach"',
      required: ['cow', 'stomach', 'digestion'],
      excluded: []
    }
  },
  {
    question: "Horses can't vomit",
    answer: true,
    category: "animals",
    explanation: "Horses physically cannot vomit due to their unique anatomy. They have an extremely strong lower esophageal sphincter and their esophagus joins the stomach at a sharp angle, making it nearly impossible for food to travel back up. This is why conditions like colic can be so dangerous for horses.",
    searchMapping: {
      key: 'horses',
      phrase: '"horse vomit"',
      required: ['horse', 'vomit', 'stomach'],
      excluded: []
    }
  },
  {
    question: "Flamingos are naturally white",
    answer: true,
    category: "animals",
    explanation: "Flamingos are born with gray-white feathers and only develop their pink coloration from pigments called carotenoids in their diet, primarily from algae, shrimp, and other crustaceans. The intensity of their pink color depends on the quality of their diet—flamingos in captivity can lose their color if not fed a proper diet containing these pigments.",
    searchMapping: {
      key: 'flamingos',
      phrase: '"flamingo color"',
      required: ['flamingo', 'color', 'pink'],
      excluded: []
    }
  },
  {
    question: "All bees die after they sting",
    answer: false,
    category: "animals",
    explanation: "Only honey bees die after stinging humans because their stingers have barbs that get caught in our skin. When the bee flies away, it tears out part of its abdomen, resulting in its death. However, honey bees can sting other insects without dying, and other bee species (like bumble bees) and wasps have smooth stingers that allow them to sting repeatedly.",
    searchMapping: {
      key: 'bees',
      phrase: '"bee sting"',
      required: ['bee', 'sting', 'die'],
      excluded: []
    }
  },
  {
    question: "Male seahorses give birth to their young",
    answer: true,
    category: "animals",
    explanation: "Male seahorses have a specialized pouch on their front where females deposit eggs during mating. The male then fertilizes the eggs inside his pouch and carries them through pregnancy, providing oxygen and nutrients. After a gestation period of 2-4 weeks, the male experiences contractions and births fully-formed baby seahorses, which is unique among animal species.",
    searchMapping: {
      key: 'seahorses',
      phrase: '"seahorse birth"',
      required: ['seahorse', 'male', 'birth'],
      excluded: []
    }
  },
  {
    question: "A rattlesnake can control how much venom it injects",
    answer: true,
    category: "animals",
    explanation: "Rattlesnakes have remarkable control over their venom delivery system and can choose to deliver a dry bite (no venom), a partial dose, or a full dose of venom. This control allows them to conserve their metabolically expensive venom when biting defensively, while using full doses when hunting prey.",
    searchMapping: {
      key: 'rattlesnake',
      phrase: '"rattlesnake venom"',
      required: ['rattlesnake', 'venom', 'control'],
      excluded: []
    }
  },
  {
    question: "Sharks can blink",
    answer: false,
    category: "animals",
    explanation: "Sharks do not have eyelids that can blink. Instead, some shark species have a nictitating membrane, a translucent third eyelid that can cover and protect the eye when the shark is feeding or in danger. Other sharks without this membrane can roll their eyes back into the socket for protection.",
    searchMapping: {
      key: 'sharks',
      phrase: '"shark eyes"',
      required: ['shark', 'eye', 'blink'],
      excluded: []
    }
  },
  {
    question: "Pigs can look up at the sky",
    answer: false,
    category: "animals",
    explanation: "Due to the anatomy of their neck and spine, pigs physically cannot look directly upward at the sky. Their neck muscles and joint structure limit their range of motion, keeping their snouts more or less pointed toward the ground. This adaptation suits their natural foraging behavior of rooting in soil for food.",
    searchMapping: {
      key: 'pigs',
      phrase: '"pig neck"',
      required: ['pig', 'look', 'up'],
      excluded: []
    }
  },
  {
    question: "Rats can laugh when tickled",
    answer: true,
    category: "animals",
    explanation: "Rats produce high-frequency ultrasonic vocalizations (around 50 kHz) when tickled, which researchers have identified as a form of laughter. These rats will seek out this interaction, demonstrating they enjoy it. This finding shows that play and joy are not uniquely human experiences but extend to other mammals.",
    searchMapping: {
      key: 'rats',
      phrase: '"rat tickle"',
      required: ['rat', 'laugh', 'tickle'],
      excluded: []
    }
  },
  {
    question: "Cats can't taste sweetness",
    answer: true,
    category: "animals",
    explanation: "Unlike most mammals, cats lack the taste receptor for sweetness due to a mutation in the Tas1r2 gene. This is why cats show no preference for sweet foods. As obligate carnivores, they evolved to focus on tasting proteins and fats rather than carbohydrates like sugars, which weren't important in their natural diet.",
    searchMapping: {
      key: 'cats',
      phrase: '"cat taste"',
      required: ['cat', 'taste', 'sweet'],
      excluded: []
    }
  },
  {
    question: "Rabbits are born blind",
    answer: true,
    category: "animals",
    explanation: "Baby rabbits (kits) are born hairless, deaf, and blind. Their eyes remain closed for about 10 days after birth. This altricial development strategy allows for shorter gestation periods, which can be advantageous for prey animals like rabbits that need to reproduce quickly and in large numbers.",
    searchMapping: {
      key: 'rabbits',
      phrase: '"rabbit baby"',
      required: ['rabbit', 'born', 'blind'],
      excluded: []
    }
  }
]; 