import { animals } from './animals';
import { astronomy } from './astronomy';
import { environment } from './environment';
import { food } from './food';
import { history } from './history';
import { humanBody } from './humanBody';
import { music } from './music';
import { nature } from './nature';
import { science } from './science';
import { sports } from './sports';
import { technology } from './technology';

// Combine all categories into a single array for backward compatibility
export const allQuestions = [
  ...animals,
  ...astronomy,
  ...environment,
  ...food,
  ...history,
  ...humanBody,
  ...music,
  ...nature,
  ...science,
  ...sports,
  ...technology
];

// For potential future use, also export by category
export const questionsByCategory = {
  animals,
  astronomy,
  environment,
  food,
  history,
  'human body': humanBody,
  music,
  nature,
  science,
  sports,
  technology
}; 