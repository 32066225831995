// Questions about history
export const history = [
  {
    question: "The Great Wall of China is visible from space",
    answer: false,
    category: "history",
    explanation: "Despite popular belief, the Great Wall of China cannot be seen with the naked eye from space or the Moon. Astronauts have reported that it's only visible in low Earth orbit with magnification. Many other human structures are more visible from space than the Wall.",
    searchMapping: {
      key: 'great wall china',
      phrase: '"great wall of china"',
      required: ['ancient', 'wall', 'china'],
      excluded: []
    }
  },
  {
    question: "Vikings wore horned helmets",
    answer: false,
    category: "history",
    explanation: "There is no archaeological evidence that Vikings wore horned helmets in battle. This popular image comes from 19th-century Romanticism and Wagner's operas. Actual Viking helmets were typically simple, conical, or rounded caps made of iron or leather.",
    searchMapping: {
      key: 'viking helmet',
      phrase: '"viking warriors"',
      required: ['norse', 'scandinavia'],
      excluded: []
    }
  },
  {
    question: "The shortest war in history lasted 38 minutes",
    answer: true,
    category: "history",
    explanation: "The Anglo-Zanzibar War, fought between the United Kingdom and the Sultanate of Zanzibar on August 27, 1896, lasted just 38-45 minutes. It began when the British bombarded the palace after Sultan Khalid bin Barghash refused to step down, making it the shortest recorded war in history.",
    searchMapping: {
      key: 'war',
      phrase: '"war shortest"',
      required: ['war', 'shortest'],
      excluded: []
    }
  },
  {
    question: "The inventor of the Frisbee was turned into a Frisbee after death",
    answer: true,
    category: "history",
    explanation: "Walter Frederick Morrison, the inventor of the Frisbee, requested that after his death, his ashes be turned into a Frisbee. Following his death in 2010 at the age of 90, his family honored his wish and had some of his cremated remains molded into a limited number of memorial Frisbees.",
    searchMapping: {
      key: 'frisbee',
      phrase: '"frisbee inventor"',
      required: ['frisbee', 'inventor'],
      excluded: []
    }
  },
  {
    question: "The first Barbie doll was a teenage fashion model",
    answer: true,
    category: "history",
    explanation: "The first Barbie doll, launched in 1959, was indeed marketed as a teenage fashion model. Creator Ruth Handler was inspired by the German Bild Lilli doll, which was originally based on a risqué adult comic character. Handler redesigned the concept for children, creating the now-iconic Barbie doll.",
    searchMapping: {
      key: 'barbie',
      phrase: '"barbie toy"',
      required: ['barbie', 'toy'],
      excluded: []
    }
  },
  {
    question: "Teddy bears were named after President Theodore Roosevelt",
    answer: true,
    category: "history",
    explanation: "Teddy bears were indeed named after President Theodore 'Teddy' Roosevelt. In 1902, Roosevelt refused to shoot a bear cub during a hunting trip in Mississippi. This act of mercy was depicted in a political cartoon, which inspired Morris Michtom to create the 'Teddy's bear' toy after getting Roosevelt's permission.",
    searchMapping: {
      key: 'teddy',
      phrase: '"teddy bear"',
      required: ['teddy', 'bear'],
      excluded: []
    }
  },
  {
    question: "The original Monopoly game pieces were made from watch parts",
    answer: true,
    category: "history",
    explanation: "The original Monopoly game pieces from the 1930s were indeed fashioned from small metal parts from watches and other items. The Atlantic City-themed game's first tokens included items like a top hat, thimble, and boot, with materials sourced from the founder's children, who used items from their father's watch repair business.",
    searchMapping: {
      key: 'monopoly',
      phrase: '"monopoly watch"',
      required: ['monopoly', 'watch'],
      excluded: []
    }
  },
  {
    question: "Hot Wheels cars were invented by a Mattel designer for his son",
    answer: true,
    category: "history",
    explanation: "Hot Wheels were indeed created by Mattel co-founder Elliot Handler in 1968 after he watched his son play with toy cars and wanted to create better versions with more speed and style. Handler challenged his design team to create die-cast cars that would outperform existing toys, leading to the iconic brand.",
    searchMapping: {
      key: 'hot wheels',
      phrase: '"hot wheels son"',
      required: ['hot', 'wheels', 'son'],
      excluded: []
    }
  },
  {
    question: "The original Slinky was discovered by accident",
    answer: true,
    category: "history",
    explanation: "The Slinky was accidentally invented in 1943 by naval engineer Richard James when he knocked over a tension spring that 'walked' instead of falling. Fascinated by its movement, James and his wife Betty refined the design and demonstrated it at Gimbels department store in 1945, selling all 400 units in 90 minutes.",
    searchMapping: {
      key: 'slinky',
      phrase: '"slinky accident"',
      required: ['slinky', 'accident'],
      excluded: []
    }
  },
  {
    question: "Jigsaw puzzles were originally created to teach geography",
    answer: true,
    category: "history",
    explanation: "Jigsaw puzzles were invented around 1760 by British mapmaker and engraver John Spilsbury, who mounted maps on wood and cut them into pieces to teach geography to children. These 'dissected maps' became popular educational tools in wealthy households and schools before evolving into the recreational puzzles we know today.",
    searchMapping: {
      key: 'jigsaw',
      phrase: '"jigsaw geography"',
      required: ['jigsaw', 'geography'],
      excluded: []
    }
  },
  {
    question: "The Yo-Yo was first used as a weapon",
    answer: true,
    category: "history",
    explanation: "The yo-yo originated in the Philippines around 500 BCE where it was used as a hunting weapon. Early versions had long cords and sharp edges or points, allowing hunters to stun or wound prey from a distance before retrieving the weapon by its cord. The toy version emerged centuries later and was popularized globally in the 1920s.",
    searchMapping: {
      key: 'yo-yo',
      phrase: '"yo-yo weapon"',
      required: ['yo-yo', 'weapon'],
      excluded: []
    }
  },
  {
    question: "The first toy in space was a teddy bear",
    answer: false,
    category: "history",
    explanation: "The first toy in space was not a teddy bear but a small wooden car taken aboard the Mercury-Atlas 9 mission in 1962 by astronaut Wally Schirra. He brought it as a humorous response to regulations requiring astronauts to report any 'loose objects' observed during the flight.",
    searchMapping: {
      key: 'teddy bear',
      phrase: '"teddy bear"',
      required: ['teddy', 'bear'],
      excluded: []
    }
  },
  {
    question: "The first batteries were made over 2,000 years ago",
    answer: true,
    category: "history",
    explanation: "The 'Baghdad Battery,' discovered in Iraq and dating to around 250 BCE, is believed to be one of the oldest examples of a battery. Made from a clay jar containing a copper cylinder and iron rod, it could produce a small electric current when filled with an acidic liquid like vinegar. While its original purpose is debated, this shows that battery technology is much older than many people think.",
    searchMapping: {
      key: 'ancient battery',
      phrase: '"Baghdad Battery"',
      required: ['battery', 'ancient', 'history'],
      excluded: []
    }
  },
  {
    question: "Dinosaurs and humans lived at the same time",
    answer: false,
    category: "history",
    explanation: "Dinosaurs and humans never lived together. Dinosaurs went extinct about 65 million years ago during a mass extinction event, while modern humans (Homo sapiens) only evolved about 300,000 years ago. That means dinosaurs were gone for more than 64 million years before humans appeared. The only dinosaurs alive today are birds, which evolved from small, feathered dinosaurs.",
    searchMapping: {
      key: 'dinosaur human',
      phrase: '"dinosaur human coexist"',
      required: ['dinosaur', 'human', 'time'],
      excluded: []
    }
  },
  {
    question: "Knights in armor were so heavy they couldn't get up if they fell",
    answer: false,
    category: "history",
    explanation: "Medieval knights in full armor could move quite freely and get up if they fell. Well-made armor was carefully designed to distribute weight across the body. Knights trained from childhood to move and fight while wearing armor. Videos of modern people wearing historically accurate armor show them doing cartwheels, running, and easily standing up after falling.",
    searchMapping: {
      key: 'knight armor',
      phrase: '"knight armor mobility"',
      required: ['knight', 'armor', 'movement'],
      excluded: []
    }
  },
  {
    question: "Christopher Columbus discovered that the Earth is round",
    answer: false,
    category: "history",
    explanation: "Educated people in Columbus's time already knew the Earth was round. Ancient Greek mathematicians calculated Earth's circumference with remarkable accuracy around 240 BCE. What Columbus incorrectly believed was that Earth was much smaller than it actually is, which is why he thought he could reach Asia by sailing west from Europe.",
    searchMapping: {
      key: 'columbus',
      phrase: '"columbus earth round"',
      required: ['columbus', 'earth', 'round'],
      excluded: []
    }
  },
  {
    question: "People in the Middle Ages thought tomatoes were poisonous",
    answer: true,
    category: "history",
    explanation: "Europeans in the Middle Ages and Renaissance did believe tomatoes were poisonous. Tomatoes belong to the nightshade family, which includes many poisonous plants. Additionally, the acidic tomato juice would sometimes leach lead from pewter plates, causing lead poisoning. It wasn't until the 1800s that tomatoes became widely accepted as safe to eat in Europe.",
    searchMapping: {
      key: 'tomato',
      phrase: '"tomato poison history"',
      required: ['tomato', 'poison', 'history'],
      excluded: []
    }
  },
  {
    question: "The oldest known musical instrument is a flute",
    answer: true,
    category: "history",
    explanation: "The oldest confirmed musical instrument is indeed a type of flute. Discovered in Germany's Hohle Fels cave, this flute made from a vulture's wing bone is approximately 40,000-45,000 years old. It was created by early Homo sapiens during the Upper Paleolithic era and could play multiple notes, showing that music was important even to our ancient ancestors.",
    searchMapping: {
      key: 'ancient flute',
      phrase: '"oldest musical instrument"',
      required: ['flute', 'oldest', 'instrument'],
      excluded: []
    }
  },
  {
    question: "Albert Einstein failed math as a student",
    answer: false,
    category: "history",
    explanation: "Einstein never failed math. This is a popular myth with no basis in fact. Einstein showed mathematical talent from an early age and mastered differential and integral calculus by age 15. His school records show he excelled in physics and mathematics throughout his education. The myth may have originated to encourage struggling students.",
    searchMapping: {
      key: 'einstein',
      phrase: '"einstein math student"',
      required: ['einstein', 'math', 'fail'],
      excluded: []
    }
  },
  {
    question: "Napoleon Bonaparte was extremely short",
    answer: false,
    category: "history",
    explanation: "Napoleon wasn't unusually short. He was approximately 5'7\" (170 cm), which was average height for men in his time. The misconception comes from British propaganda and confusion between French and British measuring units. The French inch (pouce) was longer than the British inch, making his reported height of 5'2\" in French units seem much shorter when not converted.",
    searchMapping: {
      key: 'napoleon',
      phrase: '"napoleon height"',
      required: ['napoleon', 'height', 'short'],
      excluded: []
    }
  },
  {
    question: "The Great Fire of London ended the plague",
    answer: true,
    category: "history",
    explanation: "The Great Fire of London in 1666 did help end the plague that had devastated the city for years. The fire destroyed many of the cramped, unsanitary buildings where rats carrying plague-infected fleas lived. After the fire, buildings were reconstructed with better materials and wider streets, reducing the rat population and making future outbreaks less severe.",
    searchMapping: {
      key: 'fire london',
      phrase: '"great fire london plague"',
      required: ['fire', 'london', 'plague'],
      excluded: []
    }
  },
  {
    question: "Cleopatra was Egyptian",
    answer: false,
    category: "history",
    explanation: "Despite ruling Egypt, Cleopatra VII was not ethnically Egyptian. She was a member of the Ptolemaic dynasty, which was of Greek/Macedonian origin. The Ptolemies ruled Egypt after Alexander the Great's conquest and maintained their Greek identity, with Cleopatra being the first ruler of her dynasty to learn the Egyptian language.",
    searchMapping: {
      key: 'cleopatra',
      phrase: '"cleopatra egyptian greek"',
      required: ['cleopatra', 'origin'],
      excluded: []
    }
  },
  {
    question: "The Titanic was the first ship to use SOS",
    answer: false,
    category: "history",
    explanation: "The Titanic was not the first ship to use the SOS distress signal. SOS was adopted internationally in 1908, four years before the Titanic sank in 1912. Several ships had already used it by then. Interestingly, the Titanic's radio operators initially used the older CQD distress call before switching to SOS during the emergency.",
    searchMapping: {
      key: 'titanic',
      phrase: '"titanic SOS signal"',
      required: ['titanic', 'SOS', 'signal'],
      excluded: []
    }
  },
  {
    question: "The Aztecs invented popcorn",
    answer: false,
    category: "history",
    explanation: "While the Aztecs did eat popcorn, they didn't invent it. Archaeological evidence shows that people in Peru were making popcorn at least 6,700 years ago, thousands of years before the Aztec civilization formed around the 14th century. Native Americans throughout the Americas knew about popcorn, which was one of the first ways humans consumed corn.",
    searchMapping: {
      key: 'popcorn',
      phrase: '"popcorn history aztec"',
      required: ['popcorn', 'history', 'aztec'],
      excluded: []
    }
  },
  {
    question: "People in medieval times thought the Earth was flat",
    answer: false,
    category: "history",
    explanation: "Most educated people in medieval times knew the Earth was round. This knowledge had been established by ancient Greek astronomers and was widely accepted in medieval Europe. The myth that medieval people believed in a flat Earth originated in the 19th century and was popularized by works like Washington Irving's biography of Columbus.",
    searchMapping: {
      key: 'medieval',
      phrase: '"medieval flat earth"',
      required: ['medieval', 'flat', 'earth'],
      excluded: []
    }
  },
  {
    question: "Mount Rushmore originally included full bodies",
    answer: false,
    category: "history",
    explanation: "Mount Rushmore was always designed to feature only the heads of the presidents, not their full bodies. The original design by sculptor Gutzon Borglum called for head-and-shoulder busts of the four presidents. The monument was planned this way from the beginning, though funding issues prevented even the planned busts from being fully completed.",
    searchMapping: {
      key: 'rushmore',
      phrase: '"mount rushmore design"',
      required: ['rushmore', 'design'],
      excluded: []
    }
  },
  {
    question: "The first computer was built in the 1940s",
    answer: true,
    category: "history",
    explanation: "The first fully electronic, programmable computer was the ENIAC (Electronic Numerical Integrator and Computer), unveiled in 1946. It was built at the University of Pennsylvania for the U.S. Army to calculate artillery firing tables. While earlier mechanical computing devices existed, ENIAC represents the beginning of modern electronic computing.",
    searchMapping: {
      key: 'computer',
      phrase: '"first computer history"',
      required: ['first', 'computer', 'ENIAC'],
      excluded: []
    }
  },
  {
    question: "Ancient Egyptian pyramid builders were slaves",
    answer: false,
    category: "history",
    explanation: "Archaeological evidence shows that the pyramids were built by paid workers, not slaves as commonly believed. These workers were skilled craftsmen and temporary laborers who worked in rotating shifts. They were paid, housed in nearby villages, given medical care, and buried with honor near the pyramids, indicating their respected status in society.",
    searchMapping: {
      key: 'pyramid',
      phrase: '"pyramid workers slaves"',
      required: ['pyramid', 'workers'],
      excluded: []
    }
  },
  {
    question: "The Wright brothers built the first airplane",
    answer: false,
    category: "history",
    explanation: "The Wright brothers didn't build the first airplane, but they did make the first controlled, sustained, powered flight in 1903. Earlier inventors like Otto Lilienthal, Clément Ader, and others built aircraft that briefly left the ground before the Wrights. What made the Wright Flyer revolutionary was its three-axis control system that made controlled flight possible.",
    searchMapping: {
      key: 'wright',
      phrase: '"wright brothers airplane"',
      required: ['wright', 'brothers', 'airplane'],
      excluded: []
    }
  },
  {
    question: "The Aztecs ate chocolates",
    answer: true,
    category: "history",
    explanation: "The Aztecs did consume chocolate, but not as the sweet treat we know today. They prepared cacao as a bitter, frothy drink called 'xocolātl' often mixed with chili peppers, vanilla, and other spices. It was considered sacred and was consumed during religious ceremonies, by warriors, and by nobility. The Aztecs even used cacao beans as currency.",
    searchMapping: {
      key: 'aztec',
      phrase: '"aztec chocolate"',
      required: ['aztec', 'chocolate', 'cacao'],
      excluded: []
    }
  },
  {
    question: "The longest war in history lasted over 300 years",
    answer: true,
    category: "history",
    explanation: "The longest war in history was the 335-Year War between the Netherlands and the Isles of Scilly (off the coast of England), which lasted from 1651 to 1986. However, it was a war in name only - no battles were fought after the initial declaration of war, and the two sides simply forgot to sign a peace treaty until 1986, when the treaty was signed with great ceremony.",
    searchMapping: {
      key: 'war',
      phrase: '"longest war history"',
      required: ['longest', 'war', 'history'],
      excluded: []
    }
  },
  {
    question: "The Colosseum in Rome was originally painted in bright colors",
    answer: true,
    category: "history",
    explanation: "The Roman Colosseum was indeed painted in vibrant colors when it was built, not the plain stone color we see today. Archaeologists have found traces of red, ochre, blue, and other pigments on the monument. Most ancient Roman and Greek buildings and statues were brightly painted, though weathering over centuries has removed most of the original color.",
    searchMapping: {
      key: 'colosseum',
      phrase: '"colosseum colors paint"',
      required: ['colosseum', 'color', 'paint'],
      excluded: []
    }
  },
  {
    question: "The first person to see a cell was Isaac Newton",
    answer: false,
    category: "history",
    explanation: "Robert Hooke, not Isaac Newton, was the first person to observe and name cells in 1665. Using one of the earliest compound microscopes, Hooke examined thin slices of cork and noticed tiny box-like structures that reminded him of monks' cells in a monastery. He published his findings with detailed illustrations in his book 'Micrographia.'",
    searchMapping: {
      key: 'cell',
      phrase: '"cell discovery history"',
      required: ['cell', 'discovery', 'hooke'],
      excluded: []
    }
  },
  {
    question: "The Eiffel Tower was supposed to be temporary",
    answer: true,
    category: "history",
    explanation: "The Eiffel Tower was indeed designed as a temporary structure. Built for the 1889 World's Fair in Paris, it was originally planned to stand for just 20 years before being dismantled. Many Parisians initially disliked it, calling it an eyesore. It was saved because it proved valuable as a radio transmission tower, especially during World War I.",
    searchMapping: {
      key: 'eiffel',
      phrase: '"eiffel tower temporary"',
      required: ['eiffel', 'tower', 'temporary'],
      excluded: []
    }
  },
  {
    question: "Benjamin Franklin invented the lightning rod",
    answer: true,
    category: "history",
    explanation: "Benjamin Franklin did invent the lightning rod in 1752 after his famous kite experiment confirmed that lightning was electrical in nature. His design for the 'lightning attractor' featured a pointed iron rod connected to the ground by a wire, which safely directed lightning away from buildings. This invention significantly reduced fire damage to structures during thunderstorms.",
    searchMapping: {
      key: 'franklin',
      phrase: '"franklin lightning rod"',
      required: ['franklin', 'lightning', 'rod'],
      excluded: []
    }
  },
  {
    question: "The first Olympic Games included swimming events",
    answer: false,
    category: "history",
    explanation: "The ancient Olympic Games, which began around 776 BCE in Greece, did not include swimming as an event. The ancient Olympics primarily featured land-based competitions like running, wrestling, discus, javelin, long jump, and chariot racing. Swimming was only introduced to the modern Olympic Games when they were revived in 1896 in Athens.",
    searchMapping: {
      key: 'olympics',
      phrase: '"ancient olympics swimming"',
      required: ['ancient', 'olympics', 'swimming'],
      excluded: []
    }
  },
  {
    question: "Carrots help you see in the dark",
    answer: false,
    category: "history",
    explanation: "While carrots contain vitamin A which is good for eye health, they don't actually improve night vision. This myth originated during World War II when the British Royal Air Force spread a rumor that their pilots ate lots of carrots to explain their success in shooting down German aircraft at night. In reality, they were using newly developed radar technology they wanted to keep secret.",
    searchMapping: {
      key: 'carrot',
      phrase: '"carrot night vision myth"',
      required: ['carrot', 'vision', 'war'],
      excluded: []
    }
  },
  {
    question: "The Statue of Liberty was a gift from France",
    answer: true,
    category: "history",
    explanation: "The Statue of Liberty was indeed a gift from the people of France to the United States. It was designed by French sculptor Frédéric Auguste Bartholdi and built by Gustave Eiffel (who later built the Eiffel Tower). The statue was dedicated on October 28, 1886, and symbolized the friendship between the two nations and their shared ideals of liberty.",
    searchMapping: {
      key: 'statue liberty',
      phrase: '"statue liberty france gift"',
      required: ['statue', 'liberty', 'france'],
      excluded: []
    }
  },
  {
    question: "Walt Disney drew Mickey Mouse",
    answer: false,
    category: "history",
    explanation: "While Walt Disney created the character concept for Mickey Mouse and provided his voice for over 20 years, the iconic mouse was actually drawn by animator Ub Iwerks. Disney had originally created a character called Oswald the Lucky Rabbit, but lost the rights to it. Iwerks then helped Disney design Mickey as a replacement character in 1928.",
    searchMapping: {
      key: 'mickey',
      phrase: '"mickey mouse creator"',
      required: ['mickey', 'mouse', 'draw'],
      excluded: []
    }
  },
  {
    question: "The first video game was created in the 1970s",
    answer: false,
    category: "history",
    explanation: "The first video game predates the 1970s by decades. In 1958, physicist William Higinbotham created 'Tennis for Two' on an oscilloscope. Even earlier, in 1947, Thomas T. Goldsmith Jr. and Estle Ray Mann created a missile simulation game. Popular video games like Pong (1972) only appeared decades after these early examples.",
    searchMapping: {
      key: 'video game',
      phrase: '"first video game history"',
      required: ['first', 'video', 'game'],
      excluded: []
    }
  },
  {
    question: "Ancient Romans used urine to brush their teeth",
    answer: true,
    category: "history",
    explanation: "Ancient Romans did use urine as a teeth-cleaning agent. Specifically, they used Portuguese urine because it was supposedly the strongest. The ammonia in urine was an effective cleaner. Romans would collect urine in public pots, bring it to fulleries, and not only use it for dental care but also for laundering clothes and tanning leather.",
    searchMapping: {
      key: 'roman',
      phrase: '"roman urine teeth"',
      required: ['roman', 'urine', 'teeth'],
      excluded: []
    }
  },
  {
    question: "Fortune cookies were invented in China",
    answer: false,
    category: "history",
    explanation: "Fortune cookies were not invented in China but in the United States. They likely originated with Japanese immigrants to America and became popular in Chinese restaurants after World War II when many Japanese-Americans sold their businesses to Chinese-Americans due to anti-Japanese sentiment. Fortune cookies remain rare in China and are viewed as an American curiosity.",
    searchMapping: {
      key: 'fortune cookie',
      phrase: '"fortune cookie origin"',
      required: ['fortune', 'cookie', 'origin'],
      excluded: []
    }
  },
  {
    question: "The first car was invented by Henry Ford",
    answer: false,
    category: "history",
    explanation: "Henry Ford did not invent the first car. The first practical automobile was built by Karl Benz in 1885 in Germany. Ford's innovation was the assembly line production method that made cars affordable for the average American. His Model T, introduced in 1908, transformed cars from luxury items to everyday necessities, but it was not the first automobile.",
    searchMapping: {
      key: 'first car',
      phrase: '"first car inventor"',
      required: ['first', 'car', 'inventor'],
      excluded: []
    }
  },
  {
    question: "Coffee was discovered by dancing goats",
    answer: true,
    category: "history",
    explanation: "According to a popular legend, coffee was discovered in Ethiopia when a goat herder named Kaldi noticed his goats dancing excitedly after eating berries from a certain tree. Curious about this effect, Kaldi tried the berries himself and experienced a similar burst of energy. While the story may be more myth than fact, it represents one of the oldest accounts of coffee's discovery.",
    searchMapping: {
      key: 'coffee',
      phrase: '"coffee discovery goats"',
      required: ['coffee', 'discovery', 'goats'],
      excluded: []
    }
  },
  {
    question: "Chewing gum loses its flavor because you swallow the sugar",
    answer: true,
    category: "history",
    explanation: "Chewing gum does lose its flavor primarily because you swallow the sugar and flavoring ingredients. Traditional gum has a gum base that's not digestible, which is why you shouldn't swallow it. The sweeteners and flavorings dissolve in your saliva and are gradually swallowed as you chew, leaving only the tasteless gum base behind.",
    searchMapping: {
      key: 'gum',
      phrase: '"chewing gum flavor"',
      required: ['gum', 'flavor', 'sugar'],
      excluded: []
    }
  },
  {
    question: "Pirates wore eye patches to help them see in the dark",
    answer: true,
    category: "history",
    explanation: "Pirates likely wore eye patches not because of eye injuries, but as a practical way to keep one eye adapted to darkness. When going below deck where it was dark, they could switch the patch to the other eye, allowing them to see immediately without waiting for their eyes to adjust. This maritime practice helped them move quickly between bright decks and dark holds.",
    searchMapping: {
      key: 'pirate',
      phrase: '"pirate eye patch"',
      required: ['pirate', 'eye', 'patch'],
      excluded: []
    }
  },
  {
    question: "George Washington had wooden teeth",
    answer: false,
    category: "history",
    explanation: "George Washington never had wooden teeth. He did have several sets of dentures made from various materials including hippopotamus ivory, gold, lead, human and animal teeth (horse and donkey), but never wood. His dental problems began in his twenties and by his inauguration as president, he had only one natural tooth remaining.",
    searchMapping: {
      key: 'washington',
      phrase: '"washington wooden teeth"',
      required: ['washington', 'teeth'],
      excluded: []
    }
  },
  {
    question: "Ancient Egyptians mummified dogs",
    answer: true,
    category: "history",
    explanation: "Ancient Egyptians did mummify animals, including millions of dogs. They mummified various animals as offerings to specific gods - dogs were often mummified as offerings to Anubis, the jackal-headed god of the afterlife. Archaeologists have found vast catacombs filled with mummified animals including dogs, cats, birds, and even crocodiles.",
    searchMapping: {
      key: 'mummy',
      phrase: '"egyptian dog mummies"',
      required: ['egypt', 'mummy', 'dog'],
      excluded: []
    }
  },
  {
    question: "The first photograph took 8 hours to capture",
    answer: true,
    category: "history",
    explanation: "The world's first photograph, taken by Joseph Nicéphore Niépce in 1826 or 1827, did indeed require an extremely long exposure time of about 8 hours. Titled 'View from the Window at Le Gras,' the image was created using a camera obscura and a pewter plate coated with bitumen. The long exposure time explains why the buildings are lit from both sides - the sun moved across the sky during exposure.",
    searchMapping: {
      key: 'photograph',
      phrase: '"first photograph history"',
      required: ['first', 'photograph', 'exposure'],
      excluded: []
    }
  },
  {
    question: "Viking warriors were buried with their horses",
    answer: true,
    category: "history",
    explanation: "Viking warriors were indeed sometimes buried with their horses, especially high-ranking warriors and nobles. Archaeological excavations of Viking burial sites have uncovered remains of horses alongside human remains. The practice reflected the belief that the deceased would need transportation in the afterlife. Other grave goods often included weapons, tools, and personal belongings.",
    searchMapping: {
      key: 'viking',
      phrase: '"viking burial horse"',
      required: ['viking', 'burial', 'horse'],
      excluded: []
    }
  }
]; 