// Questions about astronomy
export const astronomy = [
  {
    question: "The Moon makes its own light like the Sun",
    answer: false,
    category: "astronomy",
    explanation: "The Moon doesn't produce its own light. What we see as moonlight is actually sunlight reflecting off the Moon's surface. The Moon only reflects about 12% of the sunlight that hits it.",
    searchMapping: {
      key: 'moon light',
      phrase: '"moon sun reflection"',
      required: ['moon', 'light', 'sun'],
      excluded: []
    }
  },
  {
    question: "The Sun is perfectly round",
    answer: false,
    category: "astronomy",
    explanation: "The Sun is not perfectly round but slightly flattened. This shape, called an oblate spheroid, results from its rotation. The equatorial diameter is about 10 kilometers larger than the diameter measured through the poles, though this difference is very small relative to the Sun's overall size.",
    searchMapping: {
      key: 'sun',
      phrase: '"sun shape"',
      required: ['sun', 'shape', 'round'],
      excluded: []
    }
  },
  {
    question: "The Sun goes around the Earth",
    answer: false,
    category: "astronomy",
    explanation: "The Earth orbits around the Sun, not the other way around. This heliocentric model was proposed by Copernicus in the 16th century, replacing the geocentric model that had dominated astronomy for centuries.",
    searchMapping: {
      key: 'sun earth',
      phrase: '"sun earth orbit"',
      required: ['sun', 'earth', 'orbit'],
      excluded: []
    }
  },
  {
    question: "Mars is called the Red Planet because it has red grass",
    answer: false,
    category: "astronomy",
    explanation: "Mars appears red due to iron oxide (rust) in its soil and atmosphere, not because of vegetation. The planet's surface contains iron-rich minerals that have oxidized over time, giving it the distinctive reddish-orange color visible from Earth.",
    searchMapping: {
      key: 'mars',
      phrase: '"red planet"',
      required: ['mars', 'red', 'grass'],
      excluded: []
    }
  },
  {
    question: "A day on Venus is longer than its year",
    answer: true,
    category: "astronomy",
    explanation: "A day on Venus (one complete rotation) takes about 243 Earth days, while its year (one orbit around the Sun) takes about 225 Earth days. This means a day on Venus is longer than its year, a unique feature in our solar system.",
    searchMapping: {
      key: 'venus',
      phrase: '"venus rotation"',
      required: ['venus', 'rotation', 'orbit'],
      excluded: []
    }
  },
  {
    question: "Saturn is the only planet with rings",
    answer: false,
    category: "astronomy",
    explanation: "While Saturn has the most visible and spectacular rings, all four gas giants in our solar system (Jupiter, Saturn, Uranus, and Neptune) have ring systems. Saturn's rings are simply the largest and most visible from Earth.",
    searchMapping: {
      key: 'saturn',
      phrase: '"saturn rings"',
      required: ['saturn', 'rings'],
      excluded: []
    }
  },
  {
    question: "There are more stars in the universe than grains of sand on Earth",
    answer: true,
    category: "astronomy",
    explanation: "Scientists estimate there are approximately 10^24 (1 septillion) stars in the observable universe, while Earth's beaches and deserts contain roughly 10^21 to 10^23 grains of sand. This means there are likely at least 10 times more stars than grains of sand on Earth.",
    searchMapping: {
      key: 'stars',
      phrase: '"star grains"',
      required: ['stars', 'grains', 'earth'],
      excluded: []
    }
  },
  {
    question: "All planets in our solar system rotate clockwise",
    answer: false,
    category: "astronomy",
    explanation: "Not all planets in our solar system rotate in the same direction. Venus and Uranus rotate in the opposite direction (retrograde rotation) compared to the other planets. Venus rotates clockwise when viewed from above its north pole, opposite to most planets.",
    searchMapping: {
      key: 'planets',
      phrase: '"planet rotation"',
      required: ['planet', 'rotation', 'clockwise'],
      excluded: ['venus', 'uranus']
    }
  },
  {
    question: "The Great Red Spot on Jupiter is shrinking",
    answer: true,
    category: "astronomy",
    explanation: "Jupiter's Great Red Spot, a massive storm system, has been continuously observed since the 1830s. Astronomers have documented that it's been gradually shrinking. Once wide enough to fit three Earths across, it's now less than 1.3 times Earth's diameter.",
    searchMapping: {
      key: 'jupiter',
      phrase: '"jupiter red spot"',
      required: ['jupiter', 'red', 'spot'],
      excluded: []
    }
  },
  {
    question: "Mercury is the hottest planet in our solar system",
    answer: false,
    category: "astronomy",
    explanation: "Despite being closer to the Sun, Mercury is not the hottest planet in our solar system—Venus is. Venus has an average surface temperature of about 462°C (864°F), compared to Mercury's average of 167°C (332°F). Venus' extreme heat is due to its dense atmosphere trapping heat in a runaway greenhouse effect.",
    searchMapping: {
      key: 'mercury',
      phrase: '"mercury heat"',
      required: ['mercury', 'heat'],
      excluded: ['venus']
    }
  },
  {
    question: "A day on Pluto is shorter than a day on Earth",
    answer: false,
    category: "astronomy",
    explanation: "A day on Pluto (one full rotation) takes about 6.4 Earth days or 153 hours, which is much longer than Earth's 24-hour day. Pluto rotates much more slowly on its axis compared to Earth, making its days significantly longer, not shorter.",
    searchMapping: {
      key: 'pluto',
      phrase: '"pluto earth"',
      required: ['pluto', 'earth'],
      excluded: []
    }
  },
  {
    question: "A day on Earth is exactly 24 hours",
    answer: false,
    category: "astronomy",
    explanation: "A day on Earth is not exactly 24 hours. Earth's rotation on its axis (a sidereal day) takes approximately 23 hours, 56 minutes, and 4 seconds. The 24-hour day we use (solar day) accounts for Earth's orbit around the Sun, which requires the extra ~4 minutes to complete a full day-night cycle.",
    searchMapping: {
      key: 'earth',
      phrase: '"earth 24 hours"',
      required: ['earth', '24', 'hours'],
      excluded: []
    }
  }
]; 