// Pixabay API service for fetching images

// Enhanced cache with expiration time (24 hours)
class ImageCache {
  constructor() {
    this.cache = new Map();
    this.maxSize = 100;
    this.loadFromLocalStorage();
  }

  set(key, value) {
    // Set with timestamp for expiration checking
    this.cache.set(key, {
      data: value,
      timestamp: Date.now()
    });
    
    // Trim cache if it gets too big
    if (this.cache.size > this.maxSize) {
      const keysIterator = this.cache.keys();
      this.cache.delete(keysIterator.next().value);
    }
    
    // Save to localStorage for persistence
    this.saveToLocalStorage();
    
    return value;
  }

  get(key) {
    const item = this.cache.get(key);
    if (!item) return null;
    
    // Check if item is expired (24 hours)
    const isExpired = Date.now() - item.timestamp > 24 * 60 * 60 * 1000;
    if (isExpired) {
      this.cache.delete(key);
      return null;
    }
    
    return item.data;
  }

  has(key) {
    if (!this.cache.has(key)) return false;
    
    // Check if item is expired
    const item = this.cache.get(key);
    const isExpired = Date.now() - item.timestamp > 24 * 60 * 60 * 1000;
    
    if (isExpired) {
      this.cache.delete(key);
      return false;
    }
    
    return true;
  }
  
  // Save cache to localStorage
  saveToLocalStorage() {
    try {
      const serializedCache = JSON.stringify(Array.from(this.cache.entries()));
      localStorage.setItem('pixabayImageCache', serializedCache);
    } catch (error) {
      console.error('Error saving image cache to localStorage:', error);
    }
  }
  
  // Load cache from localStorage
  loadFromLocalStorage() {
    try {
      const serializedCache = localStorage.getItem('pixabayImageCache');
      if (serializedCache) {
        const parsed = JSON.parse(serializedCache);
        this.cache = new Map(parsed);
        
        // Clean expired items
        const now = Date.now();
        for (const [key, item] of this.cache.entries()) {
          if (now - item.timestamp > 24 * 60 * 60 * 1000) {
            this.cache.delete(key);
          }
        }
      }
    } catch (error) {
      console.error('Error loading image cache from localStorage:', error);
      this.cache = new Map();
    }
  }
}

// Create cache instance
const imageCache = new ImageCache();

const PIXABAY_API_URL = 'https://pixabay.com/api/';
const API_KEY = process.env.REACT_APP_PIXABAY_API_KEY;

console.log('Pixabay API key loaded:', API_KEY ? 'API key found' : 'API key missing');

// Use the question's searchMapping to construct a search query
const getSearchTerms = (question) => {
  // Use the searchMapping structure from the question object
  if (question.searchMapping) {
    const { phrase, required, excluded } = question.searchMapping;
    
    // Extract the main query from the phrase (removing quotes)
    const mainQuery = phrase.replace(/"/g, '');
    
    // Build the search string with required and excluded terms
    let searchString = mainQuery;
    
    // Add required terms (Pixabay doesn't support + prefix, but we include the terms)
    if (required && required.length > 0) {
      searchString += ' ' + required.join(' ');
    }
    
    // Excluded terms will be handled separately with the exclude parameter
    
    console.log('Question:', question.question);
    console.log('Search Mapping:', question.searchMapping);
    console.log('Constructed Search String:', searchString);
    
    return {
      query: searchString,
      excluded: excluded
    };
  }

  // Fallback if no mapping exists - use the category and key terms from the question
  const keywords = question.question.toLowerCase()
    .split(' ')
    .filter(word => word.length > 3 && !['with', 'from', 'that', 'this', 'have', 'what', 'when', 'where', 'which', 'there', 'their', 'your'].includes(word))
    .slice(0, 3)
    .join(' ');

  const fallbackQuery = question.category ? `${question.category} ${keywords}` : keywords;
  
  console.log('No mapping found, using fallback:', fallbackQuery);
  return {
    query: fallbackQuery,
    excluded: []
  };
};

// Map question categories to Pixabay categories
const getRelevantCategory = (question) => {
  // Pixabay categories: backgrounds, fashion, nature, science, education, feelings, 
  // health, people, religion, places, animals, industry, computer, food, sports, transportation, travel, buildings, business, music
  
  const categoryMap = {
    animals: 'animals',
    nature: 'nature',
    science: 'science',
    history: 'buildings',
    geography: 'places',
    food: 'food',
    technology: 'computer',
    astronomy: 'science',
    environment: 'nature',
    'human body': 'health',
    music: 'music',
    sports: 'sports'
  };
  
  return question.category && categoryMap[question.category] 
    ? categoryMap[question.category] 
    : '';
};

// Fetch an appropriate image for the given question
export const getImageForQuestion = async (questionObj) => {
  try {
    const cacheKey = questionObj.question;
    
    // Check cache first
    if (imageCache.has(cacheKey)) {
      console.log('Serving from cache:', cacheKey);
      return imageCache.get(cacheKey);
    }

    const { query, excluded } = getSearchTerms(questionObj);
    
    console.log('Searching with terms:', query);

    // Build the API URL with parameters
    let url = `${PIXABAY_API_URL}?key=${API_KEY}&q=${encodeURIComponent(query)}&image_type=photo&orientation=horizontal&per_page=3&safesearch=true`;
    
    // Add category if available
    const category = getRelevantCategory(questionObj);
    if (category) {
      url += `&category=${category}`;
    }
    
    // Handle excluded terms
    if (excluded && excluded.length > 0) {
      const excludeQuery = excluded.join(' ');
      if (excludeQuery) {
        url += `&exclude=${encodeURIComponent(excludeQuery)}`;
      }
    }
    
    console.log('Pixabay API URL (masked key):', url.replace(API_KEY, 'API_KEY_MASKED'));
    
    // Make the request
    try {
      const response = await fetch(url);
      if (!response.ok) {
        console.error('Pixabay API response not ok:', response.status, response.statusText);
        throw new Error(`Pixabay API returned ${response.status}: ${response.statusText}`);
      }
      const data = await response.json();
      
      console.log('Pixabay API response:', data.totalHits ? `Found ${data.totalHits} images` : 'No images found');
      
      if (data.hits && data.hits.length > 0) {
        // Get a random image from the top 3 results
        const randomIndex = Math.floor(Math.random() * Math.min(3, data.hits.length));
        const photo = data.hits[randomIndex];
        
        const imageData = {
          url: photo.largeImageURL,
          photographer: photo.user,
          photographerUrl: `https://pixabay.com/users/${photo.user}-${photo.user_id}/`,
          // Include pixabay attribution info
          source: 'Pixabay',
          sourceUrl: 'https://pixabay.com'
        };
        
        console.log('Selected image:', photo.largeImageURL);
        // Store in cache
        return imageCache.set(cacheKey, imageData);
      }
    } catch (fetchError) {
      console.error('Fetch error when calling Pixabay API:', fetchError);
    }
    
    // Fallback: try a more general search if nothing was found
    console.log('Using fallback search:', questionObj.category || 'education');
    const fallbackQuery = questionObj.category || 'education';
    
    const fallbackUrl = `${PIXABAY_API_URL}?key=${API_KEY}&q=${encodeURIComponent(fallbackQuery)}&image_type=photo&orientation=horizontal&per_page=3&safesearch=true`;
    
    try {
      const fallbackResponse = await fetch(fallbackUrl);
      if (!fallbackResponse.ok) {
        console.error('Fallback Pixabay API response not ok:', fallbackResponse.status, fallbackResponse.statusText);
        return null;
      }
      
      const fallbackData = await fallbackResponse.json();
      console.log('Fallback API response:', fallbackData.totalHits ? `Found ${fallbackData.totalHits} images` : 'No images found');
      
      if (fallbackData.hits && fallbackData.hits.length > 0) {
        // Get a random image from the top 3 results
        const randomIndex = Math.floor(Math.random() * Math.min(3, fallbackData.hits.length));
        const photo = fallbackData.hits[randomIndex];
        
        const imageData = {
          url: photo.largeImageURL,
          photographer: photo.user,
          photographerUrl: `https://pixabay.com/users/${photo.user}-${photo.user_id}/`,
          source: 'Pixabay',
          sourceUrl: 'https://pixabay.com'
        };
        
        console.log('Selected fallback image:', photo.largeImageURL);
        // Store in cache
        return imageCache.set(cacheKey, imageData);
      }
    } catch (fallbackError) {
      console.error('Fetch error when calling fallback Pixabay API:', fallbackError);
    }
    
    console.error('No images found in primary or fallback searches');
    return null;
  } catch (error) {
    console.error('Error in getImageForQuestion:', error);
    return null;
  }
};

// No tracking needed for Pixabay (unlike Unsplash)
export const trackDownload = async () => {
  // This is a no-op function to maintain API compatibility with the previous service
  // Pixabay doesn't require download tracking
  return null;
}; 