// Questions about music
export const music = [
  {
    question: "Mozart composed his first symphony at the age of 8",
    answer: true,
    category: "music",
    explanation: "Wolfgang Amadeus Mozart composed his first symphony (Symphony No. 1 in E flat, K. 16) in 1764 when he was just 8 years old. This showcased his extraordinary musical genius at a young age. Mozart would go on to compose over 600 works before his death at age 35.",
    searchMapping: {
      key: 'mozart symphony',
      phrase: '"mozart composer"',
      required: ['mozart', 'symphony', 'composer'],
      excluded: []
    }
  },
  {
    question: "The piano has exactly 88 keys",
    answer: true,
    category: "music",
    explanation: "Standard modern pianos have 88 keys: 52 white keys (representing the notes of the C major scale) and 36 black keys (representing the sharps and flats). This range spans from A0 (the lowest note) to C8 (the highest note), covering seven full octaves plus a few additional notes.",
    searchMapping: {
      key: 'piano keys',
      phrase: '"piano keyboard"',
      required: ['piano', 'keys', 'instrument'],
      excluded: []
    }
  },
  {
    question: "Elvis Presley wrote all of his hit songs",
    answer: false,
    category: "music",
    explanation: "Elvis Presley didn't write his hit songs. Despite his iconic status as the 'King of Rock and Roll,' Elvis was primarily a performer rather than a songwriter. His songs were written by various professional songwriters, including teams like Jerry Leiber and Mike Stoller, who wrote hits like 'Hound Dog' and 'Jailhouse Rock.'",
    searchMapping: {
      key: 'elvis presley',
      phrase: '"elvis presley songs"',
      required: ['elvis', 'singer', 'songwriter'],
      excluded: []
    }
  },
  {
    question: "A flute is played by blowing across a hole rather than into it",
    answer: true,
    category: "music",
    explanation: "Unlike many wind instruments where you blow directly into the instrument, a flute is played by blowing air across the edge of the mouthpiece (embouchure hole). This creates a vibration that produces the flute's distinctive sound.",
    searchMapping: {
      key: 'flute playing',
      phrase: '"flute how to play"',
      required: ['flute', 'blow', 'hole'],
      excluded: []
    }
  },
  {
    question: "Mozart died before finishing his famous Requiem composition",
    answer: true,
    category: "music",
    explanation: "Mozart died in 1791 before he could complete his Requiem Mass in D minor. The piece was later completed by his student Franz Xaver Süssmayr based on Mozart's detailed sketches and instructions.",
    searchMapping: {
      key: 'mozart requiem',
      phrase: '"mozart requiem unfinished"',
      required: ['mozart', 'requiem', 'composition'],
      excluded: []
    }
  },
  {
    question: "The guitar typically has 6 strings",
    answer: true,
    category: "music",
    explanation: "The standard guitar has 6 strings, though there are variations with 7, 8, 10, or 12 strings. The 6 strings on a standard guitar are typically tuned to E, A, D, G, B, and E (from lowest to highest).",
    searchMapping: {
      key: 'guitar strings',
      phrase: '"guitar six strings"',
      required: ['guitar', 'strings', 'instrument'],
      excluded: []
    }
  },
  {
    question: "All pianos have black and white keys",
    answer: true,
    category: "music",
    explanation: "All standard pianos have black and white keys. The white keys represent the natural notes (A, B, C, D, E, F, G) while the black keys represent the sharps and flats. This color pattern helps musicians identify notes quickly while playing.",
    searchMapping: {
      key: 'piano keys',
      phrase: '"piano keyboard"',
      required: ['piano', 'black', 'white', 'keys'],
      excluded: []
    }
  },
  {
    question: "The harmonica is classified as a woodwind instrument",
    answer: false,
    category: "music",
    explanation: "The harmonica is actually classified as a free reed aerophone or simply a free reed instrument. Unlike woodwinds, which use a reed attached to a mouthpiece, harmonicas use metal reeds that vibrate freely when air passes through them.",
    searchMapping: {
      key: 'harmonica instrument',
      phrase: '"harmonica classification"',
      required: ['harmonica', 'instrument', 'classification'],
      excluded: []
    }
  },
  {
    question: "A violin has 5 strings",
    answer: false,
    category: "music",
    explanation: "A standard violin has 4 strings, not 5. These strings are typically tuned to G, D, A, and E. While some special violins might have 5 strings, the traditional and most common violin has just 4 strings.",
    searchMapping: {
      key: 'violin strings',
      phrase: '"violin strings number"',
      required: ['violin', 'strings', 'instrument'],
      excluded: []
    }
  },
  {
    question: "The saxophone was invented by Adolphe Sax",
    answer: true,
    category: "music",
    explanation: "The saxophone was indeed invented by Belgian instrument maker Adolphe Sax in the early 1840s. He patented the instrument in 1846, creating a hybrid that combined elements of the clarinet, oboe, and brass instruments.",
    searchMapping: {
      key: 'saxophone inventor',
      phrase: '"saxophone history"',
      required: ['saxophone', 'inventor', 'Sax'],
      excluded: []
    }
  },
  {
    question: "A conductor uses a baton to help keep time for the orchestra",
    answer: true,
    category: "music",
    explanation: "Orchestra conductors typically use a thin white stick called a baton to help keep time and direct the musicians. The baton helps make the conductor's hand movements more visible to all orchestra members and provides precise visual cues for timing and expression.",
    searchMapping: {
      key: 'conductor baton',
      phrase: '"orchestra conductor"',
      required: ['conductor', 'baton', 'orchestra'],
      excluded: []
    }
  },
  {
    question: "A standard drum kit typically has 8 drums",
    answer: false,
    category: "music",
    explanation: "A standard basic drum kit typically has 5 main components: bass drum, snare drum, hi-hat, and two tom-toms. Additional cymbals and toms can be added, but 8 drums would be considered an expanded or professional kit, not the standard configuration.",
    searchMapping: {
      key: 'drum kit',
      phrase: '"standard drum kit"',
      required: ['drums', 'kit', 'standard'],
      excluded: []
    }
  },
  {
    question: "A typical symphony orchestra has more than 100 musicians",
    answer: false,
    category: "music",
    explanation: "A full-size symphony orchestra typically has 70-100 musicians, not more than 100. The exact number varies depending on the composition being performed and the size of the venue, but most major orchestras maintain around 80-90 permanent musicians.",
    searchMapping: {
      key: 'symphony orchestra',
      phrase: '"orchestra size"',
      required: ['orchestra', 'musicians', 'symphony'],
      excluded: []
    }
  },
  {
    question: "In an orchestra, the trumpet is part of the string section",
    answer: false,
    category: "music",
    explanation: "Trumpets are part of the brass section in an orchestra, not the string section. The string section includes instruments like violins, violas, cellos, and double basses, while the brass section includes trumpets, trombones, French horns, and tubas.",
    searchMapping: {
      key: 'orchestra sections',
      phrase: '"orchestra instrument sections"',
      required: ['trumpet', 'orchestra', 'section'],
      excluded: []
    }
  },
  {
    question: "The world's most expensive violin is worth more than $10 million",
    answer: true,
    category: "music",
    explanation: "Several violins have sold for more than $10 million, particularly those made by Antonio Stradivari. The most expensive violin ever sold was the 'Messiah' Stradivarius, valued at approximately $20 million, though it was donated to a museum rather than sold.",
    searchMapping: {
      key: 'expensive violin',
      phrase: '"most expensive violin"',
      required: ['violin', 'expensive', 'Stradivarius'],
      excluded: []
    }
  },
  {
    question: "When you pluck a guitar string, it vibrates to make sound",
    answer: true,
    category: "music",
    explanation: "When a guitar string is plucked, it vibrates rapidly back and forth. These vibrations disturb the air molecules around the string, creating sound waves that we hear as music. The pitch of the note depends on factors like the string's length, thickness, and tension.",
    searchMapping: {
      key: 'guitar string',
      phrase: '"how guitar makes sound"',
      required: ['guitar', 'string', 'vibration'],
      excluded: []
    }
  },
  {
    question: "The shortest musical note is called a semiquaver",
    answer: false,
    category: "music",
    explanation: "The semiquaver (sixteenth note) is not the shortest musical note. There are even shorter notes like the demisemiquaver (32nd note), hemidemisemiquaver (64th note), and even 128th and 256th notes, though these extremely short notes are rarely used in practice.",
    searchMapping: {
      key: 'musical notes',
      phrase: '"musical note names"',
      required: ['semiquaver', 'musical', 'notes'],
      excluded: []
    }
  },
  {
    question: "Drums are considered percussion instruments",
    answer: true,
    category: "music",
    explanation: "Drums are indeed classified as percussion instruments. Percussion instruments are played by being struck, shaken, or scraped to produce sound. Drums specifically are membranophone percussion instruments, meaning they produce sound when a stretched membrane (drumhead) is struck.",
    searchMapping: {
      key: 'drums classification',
      phrase: '"percussion instruments"',
      required: ['drums', 'percussion', 'instruments'],
      excluded: []
    }
  },
  {
    question: "The oldest known musical instrument is over 40,000 years old",
    answer: true,
    category: "music",
    explanation: "Archaeologists have discovered flutes made from bird bone and mammoth ivory in a cave in Germany that are over 40,000 years old. These flutes are considered the oldest known musical instruments in the world, showing that music has been part of human culture since prehistoric times.",
    searchMapping: {
      key: 'oldest instrument',
      phrase: '"oldest musical instrument"',
      required: ['oldest', 'instrument', 'flute'],
      excluded: []
    }
  },
  {
    question: "Singing in a group is called a choir",
    answer: true,
    category: "music",
    explanation: "A group of people who sing together is indeed called a choir. Choirs can be found in schools, churches, communities, and professional settings. They typically sing in harmony with multiple vocal parts and are often led by a conductor or choir director.",
    searchMapping: {
      key: 'choir singing',
      phrase: '"group singing"',
      required: ['choir', 'singing', 'group'],
      excluded: []
    }
  }
]; 