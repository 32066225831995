// Questions about food
export const food = [
  {
    question: "Honey never spoils",
    answer: true,
    category: "food",
    explanation: "Archaeological evidence has shown that honey remains unspoiled even after thousands of years. Its low moisture content and acidic nature create an environment where bacteria cannot survive. Properly stored honey can last indefinitely.",
    searchMapping: {
      key: 'honey jar',
      phrase: '"honey preservation"',
      required: ['bees', 'sweet'],
      excluded: []
    }
  },
  {
    question: "French fries originated in France",
    answer: false,
    category: "food",
    explanation: "Despite their name, French fries likely originated in Belgium, not France. According to Belgian history, villagers along the River Meuse fried small fish as a staple. When the river froze in winter, they substituted potatoes cut into fish shapes. American soldiers in WWI called them 'French fries' because the Belgian soldiers spoke French.",
    searchMapping: {
      key: 'french fries',
      phrase: '"fried potatoes"',
      required: ['potatoes', 'food'],
      excluded: []
    }
  },
  {
    question: "Ice cream was invented in China",
    answer: true,
    category: "food",
    explanation: "The earliest form of ice cream dates back to ancient China around 200 BC, where a frozen mix of milk and rice was created. King Tang of Shang had people mix ice with milk, making something resembling what we now call ice cream.",
    searchMapping: {
      key: 'ice cream',
      phrase: '"ice cream China"',
      required: ['ice', 'cream', 'china'],
      excluded: []
    }
  },
  {
    question: "A pizza can be square-shaped",
    answer: true,
    category: "food",
    explanation: "While round pizzas are most common, square or rectangular pizzas are popular in many regions. Sicilian pizza (sfincione) is traditionally square, as is Detroit-style pizza. These shapes are practical for certain cooking methods and allow for more efficient slicing.",
    searchMapping: {
      key: 'pizza',
      phrase: '"pizza square"',
      required: ['pizza', 'square', 'sfincione'],
      excluded: []
    }
  },
  {
    question: "Chocolate comes from a fruit",
    answer: true,
    category: "food",
    explanation: "Chocolate is made from the seeds (beans) of the cacao tree's fruit pods. These colorful pods grow directly from the tree trunk and contain sweet pulp surrounding the beans, making chocolate technically derived from a fruit.",
    searchMapping: {
      key: 'chocolate',
      phrase: '"chocolate fruit"',
      required: ['chocolate', 'fruit', 'cacao'],
      excluded: []
    }
  },
  {
    question: "The first oranges weren't orange",
    answer: true,
    category: "food",
    explanation: "The first cultivated oranges were actually green. The original oranges from Southeast Asia were a tangerine-pomelo hybrid and remained green even when ripe. The orange color developed as the fruit was cultivated in less humid, cooler regions where the chlorophyll breaks down to reveal the orange pigments.",
    searchMapping: {
      key: 'oranges',
      phrase: '"oranges green"',
      required: ['oranges', 'green'],
      excluded: []
    }
  },
  {
    question: "Pineapples grow on trees",
    answer: false,
    category: "food",
    explanation: "Pineapples don't grow on trees but instead grow from a plant on the ground. Each pineapple plant produces just one fruit at a time, growing from the center of a leafy plant about 3 feet tall. The plant is actually a member of the bromeliad family.",
    searchMapping: {
      key: 'pineapples',
      phrase: '"pineapple tree"',
      required: ['pineapple', 'tree'],
      excluded: []
    }
  },
  {
    question: "A strawberry isn't actually a berry, but a banana is",
    answer: true,
    category: "food",
    explanation: "Botanically speaking, strawberries aren't berries because their seeds are on the outside and they develop from the flower's receptacle, not its ovary. Bananas, however, are true berries because they develop from a single flower's ovary and contain seeds inside the flesh.",
    searchMapping: {
      key: 'strawberry',
      phrase: '"strawberry banana"',
      required: ['strawberry', 'banana'],
      excluded: []
    }
  },
  {
    question: "Tomatoes have more genes than humans do",
    answer: true,
    category: "food",
    explanation: "The tomato genome contains about 31,760 genes, while humans have approximately 20,000-25,000 genes. This surprising fact highlights how genetic complexity doesn't necessarily correlate with organism complexity. Many plant species have larger genomes than humans because they've undergone genome duplication events throughout evolution.",
    searchMapping: {
      key: 'tomato genes',
      phrase: '"tomato genome"',
      required: ['tomato', 'genes', 'human'],
      excluded: []
    }
  },
  {
    question: "Carrots were originally purple, not orange",
    answer: true,
    category: "food",
    explanation: "The first domesticated carrots originated in Central Asia around 1,100 years ago and were purple or yellow. Orange carrots weren't developed until the 16th-17th century in the Netherlands, when Dutch growers selectively bred them to obtain the orange color as a tribute to William of Orange, who led the struggle for Dutch independence.",
    searchMapping: {
      key: 'carrot color',
      phrase: '"purple carrots"',
      required: ['carrot', 'purple', 'orange'],
      excluded: []
    }
  },
  {
    question: "Olives are technically fruits, not vegetables",
    answer: true,
    category: "food",
    explanation: "Olives are classified botanically as fruits because they form from the flower of the olive tree and contain seeds. Specifically, they're stone fruits or drupes, related to mangoes, cherries, peaches, almonds, and pistachios. Culinarily, however, they're typically used as vegetables due to their savory flavor profile.",
    searchMapping: {
      key: 'olives',
      phrase: '"olive fruit"',
      required: ['olive', 'fruit', 'drupe'],
      excluded: []
    }
  },
  {
    question: "Broccoli contains more protein than steak",
    answer: false,
    category: "food",
    explanation: "This claim is false. Per 100 grams, broccoli contains about 2.8 grams of protein, while lean beef steak contains about 25-30 grams of protein. While broccoli is nutritious and does contain protein, it would take about 10 cups of broccoli to equal the protein in a small steak.",
    searchMapping: {
      key: 'broccoli protein',
      phrase: '"broccoli protein"',
      required: ['broccoli', 'protein', 'steak'],
      excluded: []
    }
  },
  {
    question: "Ketchup was sold as medicine in the 1830s",
    answer: true,
    category: "food",
    explanation: "In the 1830s, Dr. John Cook Bennett claimed tomato ketchup could treat diarrhea, indigestion, jaundice, and rheumatism. He sold tomato pills for medicinal purposes. While his specific claims were false, tomatoes do contain beneficial nutrients like lycopene, which modern research suggests may have health benefits for heart disease and certain cancers.",
    searchMapping: {
      key: 'ketchup medicine',
      phrase: '"ketchup medicine history"',
      required: ['ketchup', 'medicine', 'tomato'],
      excluded: []
    }
  },
  {
    question: "Eating junk food makes children hyperactive",
    answer: false,
    category: "food",
    explanation: "Despite widespread belief, scientific studies have failed to establish a link between sugar or junk food consumption and hyperactivity in children. Research suggests that this perception may be due to confirmation bias—parents expecting children to become hyperactive after consuming sugar are more likely to notice energetic behavior. Special occasions where junk food is served (like parties) may also contribute to excitement.",
    searchMapping: {
      key: 'junk food hyperactive',
      phrase: '"sugar hyperactivity myth"',
      required: ['sugar', 'hyperactive', 'children'],
      excluded: []
    }
  },
  {
    question: "Cucumbers are fruits, not vegetables",
    answer: true,
    category: "food",
    explanation: "Botanically speaking, cucumbers are fruits because they develop from the flower of the cucumber plant and contain seeds. Specifically, they're a type of berry called a 'pepo' with a hard outer rind and no internal divisions. However, in culinary contexts, they're treated as vegetables due to their savory flavor and how they're used in cooking.",
    searchMapping: {
      key: 'cucumber',
      phrase: '"cucumber fruit"',
      required: ['cucumber', 'fruit', 'botanical'],
      excluded: []
    }
  },
  {
    question: "Eating spicy food can help you live longer",
    answer: true,
    category: "food",
    explanation: "Research suggests that regular consumption of spicy foods is associated with reduced mortality. A large study published in the BMJ found that people who ate spicy foods 6-7 times per week had a 14% reduced risk of death compared to those who ate spicy foods less than once a week. Capsaicin, the compound that makes chili peppers hot, may have anti-inflammatory and antioxidant properties.",
    searchMapping: {
      key: 'spicy food',
      phrase: '"spicy food longevity"',
      required: ['spicy', 'food', 'health'],
      excluded: []
    }
  },
  {
    question: "Watermelons were originally bitter and grown for their water content",
    answer: true,
    category: "food",
    explanation: "Ancient watermelons originated in Africa and were initially cultivated primarily for their water content, not sweetness. Archaeological evidence, including Egyptian tomb paintings and seeds found at settlements, suggests early watermelons were much smaller and bitter. Over thousands of years of selective breeding, they evolved into the sweet, red-fleshed fruits we enjoy today.",
    searchMapping: {
      key: 'watermelon',
      phrase: '"watermelon history"',
      required: ['watermelon', 'bitter', 'ancient'],
      excluded: []
    }
  },
  {
    question: "Red bell peppers have three times more vitamin C than oranges",
    answer: true,
    category: "food",
    explanation: "Red bell peppers contain approximately 95-157 mg of vitamin C per 100 grams, compared to oranges which have about 53 mg per 100 grams. This means red bell peppers can contain up to three times more vitamin C than oranges by weight. Red peppers contain more vitamin C than green peppers because they've been allowed to ripen longer on the plant.",
    searchMapping: {
      key: 'bell pepper',
      phrase: '"bell pepper vitamin C"',
      required: ['pepper', 'vitamin C', 'orange'],
      excluded: []
    }
  },
  {
    question: "Avocados have more fat than any other fruit",
    answer: true,
    category: "food",
    explanation: "Avocados contain about 15% fat by weight, significantly more than any other fruit. This fat is primarily monounsaturated fat (particularly oleic acid), which is considered heart-healthy. The high fat content gives avocados their creamy texture and is why they're sometimes called 'butter fruit' in some countries.",
    searchMapping: {
      key: 'avocado',
      phrase: '"avocado fat content"',
      required: ['avocado', 'fat', 'fruit'],
      excluded: []
    }
  },
  {
    question: "Most wasabi served in restaurants isn't real wasabi",
    answer: true,
    category: "food",
    explanation: "About 95% of wasabi served in restaurants globally is actually a mixture of horseradish, mustard, food coloring, and sometimes a small amount of actual wasabi. Real wasabi (Wasabia japonica) is difficult to grow, has a short shelf life once prepared, and can cost up to $160 per kilogram, making the substitute much more economical for restaurants.",
    searchMapping: {
      key: 'wasabi',
      phrase: '"fake wasabi"',
      required: ['wasabi', 'horseradish', 'restaurant'],
      excluded: []
    }
  },
  {
    question: "Some mushrooms can glow in the dark",
    answer: true,
    category: "food",
    explanation: "There are over 80 species of bioluminescent mushrooms that can glow in the dark. This phenomenon, called foxfire, is caused by a chemical reaction involving an enzyme called luciferase. The most famous glowing mushroom is Mycena chlorophos, which emits a greenish light. Scientists believe the glow may attract insects that help spread mushroom spores.",
    searchMapping: {
      key: 'mushroom glow',
      phrase: '"bioluminescent mushrooms"',
      required: ['mushroom', 'glow', 'dark'],
      excluded: []
    }
  },
  {
    question: "Spinach doesn't actually contain as much iron as originally thought",
    answer: true,
    category: "food",
    explanation: "The myth about spinach's high iron content originated from a decimal point error in the 1870s that overstated its iron content by a factor of 10. While spinach does contain iron (2.7mg per 100g), it's not exceptionally high compared to other leafy greens. Additionally, the iron is non-heme iron, which is less readily absorbed, and spinach contains oxalates that can further inhibit iron absorption.",
    searchMapping: {
      key: 'spinach',
      phrase: '"spinach iron myth"',
      required: ['spinach', 'iron', 'popeye'],
      excluded: []
    }
  },
  {
    question: "Onions can make you cry because they release a gas that turns into sulfuric acid",
    answer: true,
    category: "food",
    explanation: "When an onion is cut, it releases syn-propanethial-S-oxide, a volatile gas. When this gas reaches your eyes, it reacts with the water in your tears to form sulfuric acid, irritating the eyes and causing more tears as a protective response. This mechanism evolved as a defense against animals that might eat the onion.",
    searchMapping: {
      key: 'onion',
      phrase: '"onion crying"',
      required: ['onion', 'cry', 'sulfuric'],
      excluded: []
    }
  },
  {
    question: "Rhubarb leaves are poisonous",
    answer: true,
    category: "food",
    explanation: "Rhubarb leaves contain high amounts of oxalic acid, which can cause kidney damage and even be fatal in large quantities. While the stalks are safe and commonly used in pies and other desserts, the leaves should never be consumed. It would take about 11 pounds of leaves to reach a lethal dose for an average adult, but even smaller amounts can cause nausea, vomiting, and diarrhea.",
    searchMapping: {
      key: 'rhubarb',
      phrase: '"rhubarb leaves poison"',
      required: ['rhubarb', 'leaves', 'toxic'],
      excluded: []
    }
  },
  {
    question: "An average strawberry has around 200 seeds",
    answer: true,
    category: "food",
    explanation: "The small yellow specks on the outside of strawberries are actually their seeds (technically 'achenes'). An average-sized strawberry has between 150-200 of these seeds on its exterior. Strawberries are unique among fruits because they wear their seeds on the outside rather than having them encased within the flesh.",
    searchMapping: {
      key: 'strawberry',
      phrase: '"strawberry seeds"',
      required: ['strawberry', 'seeds', 'outside'],
      excluded: []
    }
  },
  {
    question: "Black olives in cans are actually green olives that have been processed",
    answer: true,
    category: "food",
    explanation: "Most canned black olives start as unripe green olives. They're treated with an iron solution called ferrous gluconate and exposed to air to oxidize, turning them black. This process is different from naturally black olives like Kalamata, which ripen naturally on the tree. The curing process also affects flavor - canned black olives are typically milder than naturally ripened varieties.",
    searchMapping: {
      key: 'black olives',
      phrase: '"black olive processing"',
      required: ['olive', 'black', 'green'],
      excluded: []
    }
  },
  {
    question: "Kiwi fruits have more vitamin C than oranges",
    answer: true,
    category: "food",
    explanation: "Kiwi fruits contain about 92.7 mg of vitamin C per 100 grams, while oranges contain approximately 53.2 mg per 100 grams. This means kiwis provide nearly 75% more vitamin C than oranges by weight. Kiwis are also rich in vitamin K, vitamin E, folate, and potassium, making them a highly nutritious fruit despite their small size.",
    searchMapping: {
      key: 'kiwi',
      phrase: '"kiwi vitamin C"',
      required: ['kiwi', 'vitamin C', 'orange'],
      excluded: []
    }
  },
  {
    question: "White chocolate isn't technically chocolate",
    answer: true,
    category: "food",
    explanation: "White chocolate doesn't contain cocoa solids, which give dark and milk chocolate their characteristic color and flavor. Instead, white chocolate contains cocoa butter (the fat from cocoa beans), sugar, milk solids, and flavorings like vanilla. According to FDA standards, to be called white chocolate, a product must contain at least 20% cocoa butter and no artificial colorings.",
    searchMapping: {
      key: 'white chocolate',
      phrase: '"white chocolate cocoa"',
      required: ['white', 'chocolate', 'cocoa'],
      excluded: []
    }
  },
  {
    question: "A single portobello mushroom contains more potassium than a banana",
    answer: true,
    category: "food",
    explanation: "A large portobello mushroom cap (about 85g) contains approximately 630mg of potassium, while a medium banana (about 118g) contains about 422mg. Mushrooms are one of the richest non-fruit, non-legume sources of potassium, which is essential for heart, muscle, and nerve function. Portobellos are actually mature cremini mushrooms that have been allowed to grow larger.",
    searchMapping: {
      key: 'portobello',
      phrase: '"mushroom potassium"',
      required: ['mushroom', 'potassium', 'banana'],
      excluded: []
    }
  },
  {
    question: "The most expensive spice in the world is saffron",
    answer: true,
    category: "food",
    explanation: "Saffron costs between $5,000-$10,000 per kilogram, making it the world's most expensive spice. The high price is due to its labor-intensive harvesting process - each flower produces only three stigmas (threads) which must be handpicked. It takes about 170,000 flowers to produce just one kilogram of saffron. The spice is valued for its distinct flavor, aroma, and the yellow color it imparts to foods.",
    searchMapping: {
      key: 'saffron',
      phrase: '"saffron expensive"',
      required: ['saffron', 'expensive', 'spice'],
      excluded: []
    }
  },
  {
    question: "Eating carrots improves your night vision",
    answer: false,
    category: "food",
    explanation: "While carrots are nutritious and contain vitamin A (from beta-carotene) which is essential for eye health, they don't actually improve night vision beyond normal levels. This myth originated from British propaganda during World War II. The British spread rumors that their pilots' exceptional night vision came from eating carrots, when in reality they were using radar technology they wanted to keep secret from the Germans.",
    searchMapping: {
      key: 'carrot',
      phrase: '"carrot night vision"',
      required: ['carrot', 'vision', 'myth'],
      excluded: []
    }
  },
  {
    question: "Fast food restaurants use additives to make food smell better than it tastes",
    answer: true,
    category: "food",
    explanation: "Fast food companies often use aromatic chemicals called 'flavor enhancers' to make food smell more appealing. Since much of what we perceive as taste actually comes from smell, these additives create an expectation of flavor that the actual food might not deliver. Chemicals like acetyl propionyl create buttery aromas, while others mimic freshly baked bread or grilled meat to trigger cravings.",
    searchMapping: {
      key: 'fast food',
      phrase: '"fast food smell additives"',
      required: ['fast food', 'smell', 'flavor'],
      excluded: []
    }
  },
  {
    question: "Grapes explode when you put them in the microwave",
    answer: true,
    category: "food",
    explanation: "When cut in half and placed side by side, grapes in a microwave can create plasma, a state of matter that appears as a bright flash and sometimes a small fireball. This occurs because the size and water content of grapes allow them to trap electromagnetic radiation at key points, creating an intense electric field between the grape halves that ionizes the air. Scientists only recently explained this phenomenon fully in 2019.",
    searchMapping: {
      key: 'grapes',
      phrase: '"grapes microwave"',
      required: ['grape', 'microwave', 'plasma'],
      excluded: []
    }
  },
  {
    question: "Olives grow on palm trees",
    answer: false,
    category: "food",
    explanation: "Olives grow on olive trees (Olea europaea), not palm trees. Olive trees are evergreen trees or shrubs that can live for hundreds or even thousands of years, with some ancient specimens still producing fruit after 2,000 years. They're native to the Mediterranean region and are short and squat with gnarled trunks, quite distinct from the tall, slender appearance of palm trees.",
    searchMapping: {
      key: 'olive tree',
      phrase: '"olive tree palm"',
      required: ['olive', 'tree', 'grow'],
      excluded: []
    }
  },
  {
    question: "Lemons contain more sugar than strawberries",
    answer: true,
    category: "food",
    explanation: "Despite their sour taste, lemons contain about 2.5 grams of sugar per 100 grams, while strawberries contain about 4.9 grams per 100 grams. However, lemons contain much more citric acid (about 5-6%), which overwhelms the sweetness and gives them their characteristically sour taste. Our perception of sweetness is influenced by the balance of sugars and acids in foods.",
    searchMapping: {
      key: 'lemon',
      phrase: '"lemon sugar strawberry"',
      required: ['lemon', 'sugar', 'sour'],
      excluded: []
    }
  },
  {
    question: "Nutmeg is a hallucinogen in large doses",
    answer: true,
    category: "food",
    explanation: "Nutmeg contains myristicin, a compound that can cause hallucinations, nausea, vomiting, and several days of discomfort when consumed in large amounts (typically more than 2 tablespoons). Despite this property, nutmeg poisoning is rare because the unpleasant effects and high dosage required make it an impractical recreational drug. In normal culinary amounts, nutmeg is perfectly safe.",
    searchMapping: {
      key: 'nutmeg',
      phrase: '"nutmeg hallucinogen"',
      required: ['nutmeg', 'hallucinogen', 'myristicin'],
      excluded: []
    }
  },
  {
    question: "Apples are more effective at waking you up than coffee",
    answer: false,
    category: "food",
    explanation: "While apples do provide a natural sugar boost and contain vitamins that promote energy, they don't contain caffeine, which is a central nervous system stimulant. Coffee has a more immediate and pronounced effect on alertness due to its caffeine content, which blocks adenosine receptors in the brain that make you feel tired. Apples provide more sustained energy without the crash, but aren't more effective as a wake-up aid.",
    searchMapping: {
      key: 'apple',
      phrase: '"apple coffee wake"',
      required: ['apple', 'wake', 'coffee'],
      excluded: []
    }
  },
  {
    question: "All bell peppers start out green before turning yellow, orange, or red",
    answer: true,
    category: "food",
    explanation: "Bell peppers are fruits that ripen on the plant, with green being the unripe state. As they mature, peppers change color from green to yellow, orange, or red depending on the variety. This ripening process increases both sugar content and nutrient levels - red peppers contain nearly twice as much vitamin C and over nine times more carotenoids than green peppers.",
    searchMapping: {
      key: 'bell pepper',
      phrase: '"bell pepper color"',
      required: ['pepper', 'color', 'ripen'],
      excluded: []
    }
  },
  {
    question: "Bananas are slightly radioactive",
    answer: true,
    category: "food",
    explanation: "Bananas contain potassium, and a small amount of natural potassium is radioactive (potassium-40). An average banana contains about 450 mg of potassium, giving off about 0.01 millirem of radiation. This amount is so tiny that it poses no health risk - you'd need to eat about 10 million bananas to suffer radiation poisoning. The 'Banana Equivalent Dose' is sometimes used to explain radiation exposure levels.",
    searchMapping: {
      key: 'banana',
      phrase: '"banana radioactive"',
      required: ['banana', 'radiation', 'potassium'],
      excluded: []
    }
  },
  {
    question: "Figs are not fruit but are inverted flowers",
    answer: true,
    category: "food",
    explanation: "Figs are technically not fruits but syconia - specialized structures containing hundreds of tiny flowers that grow inside the fig. The fig tree blooms inward, with its flowers enclosed within the fig itself. These flowers are pollinated by specialized fig wasps that enter through a small opening. As the flowers develop, they form the tiny crunchy seeds that give figs their unique texture.",
    searchMapping: {
      key: 'fig',
      phrase: '"fig flower"',
      required: ['fig', 'flower', 'inverted'],
      excluded: []
    }
  },
  {
    question: "Eating cheese before sleep gives you more vivid dreams",
    answer: false,
    category: "food",
    explanation: "The idea that cheese causes vivid dreams is largely a myth. While some people report this effect, scientific studies haven't found consistent evidence supporting it. Cheese contains tryptophan, which the body uses to make serotonin and melatonin that can affect sleep quality, but there's no proven mechanism by which cheese specifically would increase dream vividness compared to other foods containing similar nutrients.",
    searchMapping: {
      key: 'cheese',
      phrase: '"cheese dreams"',
      required: ['cheese', 'dreams', 'sleep'],
      excluded: []
    }
  },
  {
    question: "Dark chocolate contains more caffeine than milk chocolate",
    answer: true,
    category: "food",
    explanation: "Dark chocolate contains 2-3 times more caffeine than milk chocolate. An average dark chocolate bar (70-85% cocoa) contains about 20-25mg of caffeine per ounce, while milk chocolate contains only 5-9mg per ounce. This is because dark chocolate contains more cocoa solids, which are the source of caffeine in chocolate. However, even dark chocolate contains significantly less caffeine than coffee, which has about 95mg per 8oz cup.",
    searchMapping: {
      key: 'chocolate',
      phrase: '"chocolate caffeine"',
      required: ['chocolate', 'caffeine', 'dark'],
      excluded: []
    }
  },
  {
    question: "Garlic has antibacterial properties when crushed",
    answer: true,
    category: "food",
    explanation: "When garlic is crushed or chopped, it releases an enzyme called alliinase that converts alliin to allicin, the compound responsible for garlic's pungent odor and antibacterial properties. Allicin has been shown to be effective against a wide range of bacteria, including some antibiotic-resistant strains. For maximum health benefits, crushed garlic should be allowed to sit for 10-15 minutes before cooking to allow the allicin to form.",
    searchMapping: {
      key: 'garlic',
      phrase: '"garlic antibacterial"',
      required: ['garlic', 'antibacterial', 'allicin'],
      excluded: []
    }
  }
]; 