// Questions about nature
export const nature = [
  {
    question: "Bananas grow on trees",
    answer: false,
    category: "nature",
    explanation: "Despite being called 'banana trees,' banana plants are actually giant herbs. They don't have the woody tissue characteristic of trees, and their stem is made of leaf sheaths that overlap each other, forming a pseudostem.",
    searchMapping: {
      key: 'banana plant',
      phrase: '"banana plantation"',
      required: ['fruit', 'tropical'],
      excluded: []
    }
  },
  {
    question: "Trees help clean the air we breathe",
    answer: true,
    category: "nature",
    explanation: "Trees absorb carbon dioxide and release oxygen through photosynthesis. They also filter airborne pollutants including carbon monoxide and sulfur dioxide.",
    searchMapping: {
      key: 'trees clean',
      phrase: '"tree photosynthesis"',
      required: ['tree', 'clean', 'air'],
      excluded: []
    }
  },
  {
    question: "Plants only need water to grow",
    answer: false,
    category: "nature",
    explanation: "Plants need more than just water to grow. They require sunlight for photosynthesis, nutrients from soil (like nitrogen, phosphorus, and potassium), carbon dioxide from the air, and appropriate temperatures and space to develop properly.",
    searchMapping: {
      key: 'plants grow',
      phrase: '"plant growth"',
      required: ['plant', 'grow', 'water'],
      excluded: ['sunlight', 'nutrients']
    }
  },
  {
    question: "Bananas grow pointing up",
    answer: false,
    category: "nature",
    explanation: "Bananas actually grow pointing downward in bunches called 'hands.' The plant itself grows upward, but the fruit emerges from the stem pointing toward the ground. This is contrary to how they're often depicted in cartoons and illustrations.",
    searchMapping: {
      key: 'bananas grow',
      phrase: '"banana growth"',
      required: ['banana', 'grow', 'pointing'],
      excluded: ['down']
    }
  },
  {
    question: "There's a rainbow-colored river in Colombia",
    answer: true,
    category: "nature",
    explanation: "Caño Cristales in Colombia is often called the 'River of Five Colors' or 'Liquid Rainbow.' For a brief period each year (typically June to November), a unique aquatic plant called Macarenia clavigera turns bright red, creating stunning patterns alongside the river's yellow sand, green algae, and blue water.",
    searchMapping: {
      key: 'colombia',
      phrase: '"colombia rainbow"',
      required: ['colombia', 'rainbow'],
      excluded: []
    }
  },
  {
    question: "There are underwater lakes and rivers in the ocean",
    answer: true,
    category: "nature",
    explanation: "Underwater lakes and rivers do exist in the ocean. They form when seawater seeps through salt deposits, creating a super-salty brine that's denser than the surrounding water. This heavier brine collects in depressions on the seafloor, forming distinct pools with their own shorelines, waves, and even ripples.",
    searchMapping: {
      key: 'ocean',
      phrase: '"ocean underwater"',
      required: ['ocean', 'underwater'],
      excluded: []
    }
  },
  {
    question: "Some beaches have sand that glows in the dark",
    answer: true,
    category: "nature",
    explanation: "Several beaches around the world, including those in the Maldives, Puerto Rico, and Australia, feature bioluminescent phytoplankton that make the water appear to glow blue at night. When disturbed by movement, these microorganisms produce light through a chemical reaction, creating a magical glowing effect along the shoreline.",
    searchMapping: {
      key: 'beach',
      phrase: '"beach glow"',
      required: ['beach', 'glow'],
      excluded: []
    }
  },
  {
    question: "There's a place where lightning strikes almost every night",
    answer: true,
    category: "nature",
    explanation: "Lake Maracaibo in Venezuela experiences a phenomenon called 'Catatumbo Lightning,' where lightning strikes almost continuously for up to 10 hours a night, 260-300 nights per year. This unique weather pattern is caused by wind and topography creating perfect conditions for thunderstorm formation.",
    searchMapping: {
      key: 'lake',
      phrase: '"lake lightning"',
      required: ['lake', 'lightning'],
      excluded: []
    }
  },
  {
    question: "There are clouds that look like perfect waves",
    answer: true,
    category: "nature",
    explanation: "Morning Glory clouds are rare atmospheric phenomena that appear as long, horizontal, tube-shaped clouds that can extend for hundreds of kilometers. They look remarkably like ocean waves frozen in the sky. These clouds are most commonly observed in Northern Australia's Gulf of Carpentaria during spring months.",
    searchMapping: {
      key: 'clouds',
      phrase: '"clouds wave"',
      required: ['clouds', 'wave'],
      excluded: []
    }
  },
  {
    question: "Some rocks in Death Valley move by themselves",
    answer: true,
    category: "nature",
    explanation: "The 'sailing stones' of Death Valley's Racetrack Playa do indeed move on their own, leaving long tracks behind them. Scientists discovered that this occurs when thin ice sheets form after rare rain, and light winds push the rocks across the slippery surface. Some rocks have moved up to 1,500 feet this way.",
    searchMapping: {
      key: 'death valley',
      phrase: '"death valley rocks"',
      required: ['death', 'valley', 'rocks'],
      excluded: []
    }
  },
  {
    question: "There's a waterfall of blood-red water in Antarctica",
    answer: true,
    category: "nature",
    explanation: "Blood Falls in Antarctica's Taylor Glacier appears to be a flowing waterfall of blood-red liquid. The striking color comes from iron-rich salt water that oxidizes when exposed to air. This unusual feature originates from a subglacial lake that contains ancient microbes isolated for millions of years.",
    searchMapping: {
      key: 'antarctica',
      phrase: '"antarctica blood"',
      required: ['antarctica', 'blood'],
      excluded: []
    }
  },
  {
    question: "There are places where fire burns underwater",
    answer: true,
    category: "nature",
    explanation: "In certain areas of the ocean floor, methane gas seeps from vents, forming bubbles that can be ignited even underwater. These 'underwater fire' phenomena occur because methane remains a gas under water and carries enough oxygen to sustain combustion. Notable examples include the 'Eternal Flame Falls' in New York.",
    searchMapping: {
      key: 'fire',
      phrase: '"fire underwater"',
      required: ['fire', 'underwater'],
      excluded: []
    }
  },
  {
    question: "Some caves are filled with giant crystals taller than houses",
    answer: true,
    category: "nature",
    explanation: "Mexico's Cave of Crystals (Cueva de los Cristales) contains some of the largest natural crystals ever found. Some selenite crystals measure up to 39 feet in length and weigh up to 55 tons. These formations developed over 500,000 years in a hot, mineral-rich environment perfect for crystal growth.",
    searchMapping: {
      key: 'cave',
      phrase: '"cave crystals"',
      required: ['cave', 'crystals'],
      excluded: []
    }
  },
  {
    question: "There's a volcano that erupts blue flames",
    answer: true,
    category: "nature",
    explanation: "Kawah Ijen volcano in Indonesia is famous for its electric-blue flames. These occur when sulfuric gases emerging from cracks in the volcano ignite upon contact with air, reaching temperatures up to 600°C (1,112°F). The blue glow is especially visible at night, creating a surreal, otherworldly landscape.",
    searchMapping: {
      key: 'volcano',
      phrase: '"volcano blue"',
      required: ['volcano', 'blue'],
      excluded: []
    }
  },
  {
    question: "Chameleons change color to match their surroundings",
    answer: false,
    category: "nature",
    explanation: "While chameleons do change color, they don't do it to match their surroundings for camouflage. Their color changes are primarily for communication and mood expression. Their skin changes in response to temperature, light, and emotional state, allowing them to communicate with other chameleons and express emotions like aggression, fear, or mating interest.",
    searchMapping: {
      key: 'chameleon',
      phrase: '"chameleon color"',
      required: ['chameleon', 'color', 'change'],
      excluded: []
    }
  },
  {
    question: "The oldest tree on Earth is over 5,000 years old",
    answer: true,
    category: "nature",
    explanation: "The oldest known individual tree in the world is a Great Basin bristlecone pine named Methuselah, which is 4,854 years old. However, in 2013, scientists discovered another bristlecone pine in the same area estimated to be 5,067 years old. These ancient trees grow in the White Mountains of California and have adapted to survive in harsh conditions.",
    searchMapping: {
      key: 'tree',
      phrase: '"oldest tree"',
      required: ['tree', 'old', 'ancient'],
      excluded: []
    }
  },
  {
    question: "Polar bears have white fur",
    answer: false,
    category: "nature",
    explanation: "Polar bear fur isn't actually white—it's transparent and hollow. Each hair shaft is pigment-free and reflects visible light, giving the fur its white appearance. Under the transparent fur, polar bears have black skin that helps absorb heat from the sun, while the hollow hairs provide insulation against the Arctic cold.",
    searchMapping: {
      key: 'polar bear',
      phrase: '"polar bear fur"',
      required: ['polar', 'bear', 'fur'],
      excluded: []
    }
  },
  {
    question: "There's a forest where the trees are all connected by their roots",
    answer: true,
    category: "nature",
    explanation: "Pando, also known as the Trembling Giant, is a clonal colony of a single male quaking aspen tree in Utah. What appears to be a forest of individual trees is actually one organism connected by a massive root system. Pando covers 106 acres, weighs approximately 6,600 tons, and has been growing for an estimated 80,000 years, making it potentially the oldest living organism on Earth.",
    searchMapping: {
      key: 'forest',
      phrase: '"tree root network"',
      required: ['forest', 'connected', 'roots'],
      excluded: []
    }
  },
  {
    question: "Most of Earth's oxygen comes from the oceans",
    answer: true,
    category: "nature",
    explanation: "While rainforests are often called the 'lungs of the planet,' marine plants like phytoplankton, seaweed, and algae actually produce between 50-80% of Earth's oxygen. These microscopic marine organisms conduct photosynthesis just like land plants, but due to their abundance and rapid reproduction in oceans covering 71% of the planet, they generate most of our oxygen supply.",
    searchMapping: {
      key: 'ocean',
      phrase: '"ocean oxygen"',
      required: ['ocean', 'oxygen', 'phytoplankton'],
      excluded: []
    }
  },
  {
    question: "Sharks have existed longer than trees",
    answer: true,
    category: "nature",
    explanation: "Sharks have been swimming in Earth's oceans for about 450 million years, predating trees by around 90 million years. The first trees didn't appear until about 360 million years ago. Sharks have survived all five major mass extinction events, demonstrating remarkable evolutionary resilience and adaptability.",
    searchMapping: {
      key: 'shark',
      phrase: '"shark age"',
      required: ['shark', 'ancient', 'evolution'],
      excluded: []
    }
  },
  {
    question: "Mushrooms are plants",
    answer: false,
    category: "nature",
    explanation: "Mushrooms are fungi, not plants. They belong to a separate kingdom of life with characteristics distinct from plants. Unlike plants, fungi cannot produce their own food through photosynthesis, don't have chlorophyll, and are made of chitin (the same material in insect exoskeletons) rather than cellulose. Fungi are actually more closely related to animals than to plants.",
    searchMapping: {
      key: 'mushroom',
      phrase: '"mushroom fungi"',
      required: ['mushroom', 'fungi', 'plant'],
      excluded: []
    }
  },
  {
    question: "The Great Barrier Reef is the largest living structure on Earth",
    answer: true,
    category: "nature",
    explanation: "The Great Barrier Reef off the coast of Australia is the world's largest coral reef system and the largest living structure on Earth. Spanning approximately 1,400 miles (2,300 km) and covering an area of 133,000 square miles (344,400 sq km), it's composed of billions of tiny coral polyps. It's so large that it can be seen from outer space and is the only living thing visible from that distance.",
    searchMapping: {
      key: 'reef',
      phrase: '"great barrier reef"',
      required: ['reef', 'coral', 'Australia'],
      excluded: []
    }
  },
  {
    question: "Cacti only grow in deserts",
    answer: false,
    category: "nature",
    explanation: "While many cacti are adapted to desert environments, they can be found in a variety of habitats across the Americas. Some species grow in tropical regions, mountains, rainforests, and even in cold areas like Canada. For example, the Christmas cactus is native to the tropical rainforests of Brazil, while other species thrive in the Andes Mountains at elevations over 11,000 feet.",
    searchMapping: {
      key: 'cactus',
      phrase: '"cactus habitat"',
      required: ['cactus', 'desert', 'growth'],
      excluded: []
    }
  },
  {
    question: "The Sahara desert was once lush and green",
    answer: true,
    category: "nature",
    explanation: "Around 6,000-10,000 years ago, the Sahara was a lush, green region with lakes, vegetation, and diverse wildlife. Archaeological evidence shows thriving human settlements with rock art depicting swimming, fishing, and herding. This 'Green Sahara' or 'African Humid Period' ended due to changes in Earth's orbit that affected monsoon patterns, gradually transforming the region into the vast desert we know today.",
    searchMapping: {
      key: 'sahara',
      phrase: '"green sahara"',
      required: ['sahara', 'green', 'desert'],
      excluded: []
    }
  },
  {
    question: "There's a place where trees grow sideways",
    answer: true,
    category: "nature",
    explanation: "Poland's 'Crooked Forest' (Krzywy Las) contains about 400 pine trees that grow with a 90-degree bend at their base, all curving northward in the same C-shape. Planted around 1930, it's believed that humans manipulated these trees when they were young, possibly for making curved wood for furniture or boat building, though the exact technique and purpose remain a mystery.",
    searchMapping: {
      key: 'trees',
      phrase: '"crooked forest"',
      required: ['trees', 'bent', 'sideways'],
      excluded: []
    }
  },
  {
    question: "There is a flower that smells like rotting flesh",
    answer: true,
    category: "nature",
    explanation: "The corpse flower (Amorphophallus titanum) is famous for its putrid smell similar to rotting flesh. This rare plant, native to Indonesia, produces the world's largest unbranched inflorescence and blooms infrequently—sometimes with decades between flowerings. The awful odor attracts carrion beetles and flies that pollinate the plant, an evolutionary strategy called carrion mimicry.",
    searchMapping: {
      key: 'flower',
      phrase: '"corpse flower"',
      required: ['flower', 'smell', 'rotting'],
      excluded: []
    }
  },
  {
    question: "The world's oldest plant is a tree",
    answer: false,
    category: "nature",
    explanation: "The world's oldest known living plant is not a tree but a seagrass (Posidonia oceanica) in the Mediterranean Sea. This underwater meadow near Ibiza is estimated to be between 80,000 and 100,000 years old, spreading through clonal growth rather than sexual reproduction. It grows extremely slowly—only a few centimeters per year—which contributes to its extraordinary longevity.",
    searchMapping: {
      key: 'plant',
      phrase: '"oldest plant"',
      required: ['plant', 'old', 'ancient'],
      excluded: []
    }
  },
  {
    question: "Lightning can create glass when it strikes sand",
    answer: true,
    category: "nature",
    explanation: "When lightning strikes sand or certain types of soil, the extreme heat (over 30,000°C/54,000°F) can fuse sand particles together, creating glass tubes called fulgurites. These natural glass formations follow the path of the lightning strike into the ground and can extend several feet. Fulgurites preserve the exact shape of the lightning bolt's path through the earth, making each one unique.",
    searchMapping: {
      key: 'lightning',
      phrase: '"lightning glass sand"',
      required: ['lightning', 'glass', 'sand'],
      excluded: []
    }
  },
  {
    question: "Some plants can count",
    answer: true,
    category: "nature",
    explanation: "The Venus flytrap can actually 'count' to avoid wasting energy. Its trap only snaps shut when trigger hairs are touched multiple times within about 20 seconds. This counting mechanism helps the plant distinguish between an actual insect moving inside the trap and non-prey stimuli like raindrops. The trap requires at least two touches to close and more contacts to begin digestion.",
    searchMapping: {
      key: 'plant',
      phrase: '"venus flytrap count"',
      required: ['plant', 'count', 'venus'],
      excluded: []
    }
  },
  {
    question: "Avocados evolved to be eaten by giant ground sloths",
    answer: true,
    category: "nature",
    explanation: "Avocados evolved their large seeds and nutritious flesh to attract giant ground sloths and other megafauna that lived in the Americas until about 10,000 years ago. These huge animals would eat the fruit whole, travel long distances, and excrete the seeds, helping with dispersal. When these animals went extinct, avocados lost their primary seed dispersers but survived thanks to human cultivation.",
    searchMapping: {
      key: 'avocado',
      phrase: '"avocado evolution"',
      required: ['avocado', 'sloth', 'seed'],
      excluded: []
    }
  },
  {
    question: "The world's largest flower can weigh up to 24 pounds",
    answer: true,
    category: "nature",
    explanation: "Rafflesia arnoldii, native to the rainforests of Indonesia, produces the world's largest individual flower, reaching up to 3 feet (1 meter) in diameter and weighing up to 24 pounds (11 kg). This parasitic plant has no visible leaves, stems, or roots, and grows embedded within a host vine. Like the corpse flower, it emits a strong smell of rotting meat to attract pollinating insects.",
    searchMapping: {
      key: 'flower',
      phrase: '"largest flower"',
      required: ['flower', 'largest', 'rafflesia'],
      excluded: []
    }
  },
  {
    question: "There's a lake that turns animals into stone",
    answer: true,
    category: "nature",
    explanation: "Lake Natron in Tanzania has such high alkalinity (pH 9-10.5) and salt content that it calcifies animals that die in its waters, effectively turning them to 'stone.' The caustic waters preserve animals in lifelike poses, creating eerie, statue-like remains. Despite its deadly properties, the lake is home to some extremophile organisms like certain algae species and serves as the primary breeding ground for lesser flamingos.",
    searchMapping: {
      key: 'lake',
      phrase: '"lake natron"',
      required: ['lake', 'stone', 'animals'],
      excluded: []
    }
  },
  {
    question: "Redwoods are the world's tallest trees",
    answer: true,
    category: "nature",
    explanation: "Coast redwoods (Sequoia sempervirens) are the tallest trees on Earth. The current record holder is a tree named Hyperion, discovered in 2006 in California's Redwood National Park, standing at 379.7 feet (115.7 meters) tall. That's taller than a 35-story building. These ancient giants can live for over 2,000 years and have existed as a species for about 240 million years.",
    searchMapping: {
      key: 'redwood',
      phrase: '"tallest tree"',
      required: ['redwood', 'tall', 'tree'],
      excluded: []
    }
  },
  {
    question: "Trees communicate with each other through underground networks",
    answer: true,
    category: "nature",
    explanation: "Trees communicate through underground networks of mycorrhizal fungi, sometimes called the 'Wood Wide Web.' These fungal networks connect tree roots, allowing them to share resources, nutrients, and even warning signals about threats like insect attacks. Trees can recognize their offspring and preferentially send them more resources. This plant communication system was discovered by forest ecologist Suzanne Simard.",
    searchMapping: {
      key: 'tree',
      phrase: '"wood wide web"',
      required: ['tree', 'communicate', 'fungi'],
      excluded: []
    }
  },
  {
    question: "A single teaspoon of soil contains more living organisms than people on Earth",
    answer: true,
    category: "nature",
    explanation: "A single teaspoon of healthy soil can contain between 1 billion to 10 billion bacteria, representing thousands of species, along with fungi, protozoa, nematodes, and microarthropods. This microscopic biodiversity far exceeds the human population of approximately 8 billion. These soil organisms form complex food webs that break down organic matter, cycle nutrients, and support plant growth.",
    searchMapping: {
      key: 'soil',
      phrase: '"soil organisms"',
      required: ['soil', 'microbes', 'billions'],
      excluded: []
    }
  },
  {
    question: "The world's oldest living organism is a fungus",
    answer: true,
    category: "nature",
    explanation: "The largest and possibly oldest living organism is a honey fungus (Armillaria ostoyae) in Oregon's Blue Mountains. This single fungal organism covers 2,385 acres (3.7 square miles) and is estimated to be between 2,400 and 8,650 years old. It grows primarily underground as a vast network of mycelium, only visible on the surface when it produces mushrooms.",
    searchMapping: {
      key: 'fungus',
      phrase: '"giant fungus"',
      required: ['fungus', 'oldest', 'organism'],
      excluded: []
    }
  },
  {
    question: "Australia's Great Coral Reef is the only living structure visible from space",
    answer: false,
    category: "nature",
    explanation: "While the Great Barrier Reef is visible from space, it's not the only living structure that can be seen. Other visible living structures include large algal blooms, phytoplankton formations, and massive forest systems like the Amazon rainforest. Human structures like cities, highways, and the Great Wall of China are also visible from low Earth orbit with minimal magnification.",
    searchMapping: {
      key: 'reef',
      phrase: '"visible from space"',
      required: ['reef', 'space', 'visible'],
      excluded: []
    }
  },
  {
    question: "There's a plant that can solve mazes",
    answer: true,
    category: "nature",
    explanation: "Slime molds, particularly Physarum polycephalum, can solve mazes and find the most efficient routes to food sources despite lacking a brain or central nervous system. These organisms, which aren't plants but protists, create networks that mimic efficient transportation systems. Scientists have used them to model traffic networks, and in one experiment, a slime mold recreated Tokyo's rail system when food sources were placed at locations matching the city's major centers.",
    searchMapping: {
      key: 'slime mold',
      phrase: '"slime mold maze"',
      required: ['slime', 'mold', 'maze'],
      excluded: []
    }
  },
  {
    question: "The driest place on Earth hasn't seen rain in millions of years",
    answer: true,
    category: "nature",
    explanation: "The McMurdo Dry Valleys in Antarctica are considered the driest place on Earth, with some areas having received no precipitation in an estimated 2 million years. The extreme cold, lack of precipitation, and powerful katabatic winds that evaporate all moisture create hyper-arid conditions where even snow doesn't accumulate. These valleys are the closest Earth analog to the conditions on Mars.",
    searchMapping: {
      key: 'dry',
      phrase: '"driest place earth"',
      required: ['dry', 'valley', 'antarctica'],
      excluded: []
    }
  },
  {
    question: "There are plants that can move fast enough for humans to see",
    answer: true,
    category: "nature",
    explanation: "Several plants can move rapidly enough for humans to observe in real-time. The Venus flytrap closes its trap in about half a second. The sensitive plant (Mimosa pudica) folds its leaves when touched in just 1-2 seconds. The telegraph plant (Codariocalyx motorius) can rotate its small leaflets in circles. These fast movements are achieved through various mechanisms including rapid changes in cellular water pressure or mechanical tension.",
    searchMapping: {
      key: 'plants',
      phrase: '"fast moving plants"',
      required: ['plant', 'move', 'fast'],
      excluded: []
    }
  },
  {
    question: "Bamboo can grow more than a meter in a single day",
    answer: true,
    category: "nature",
    explanation: "Some species of bamboo can grow at an astonishing rate of up to 91 cm (35 inches) per day, or about 4 cm (1.5 inches) per hour. This makes bamboo the fastest-growing plant on Earth. This rapid growth is possible because bamboo has all its cells present when it sprouts—it just needs to expand them with water, rather than creating new cells like most plants.",
    searchMapping: {
      key: 'bamboo',
      phrase: '"bamboo growth"',
      required: ['bamboo', 'fast', 'grow'],
      excluded: []
    }
  },
  {
    question: "There's a rainforest that's older than the Amazon",
    answer: true,
    category: "nature",
    explanation: "The Daintree Rainforest in Australia is estimated to be about 180 million years old, making it the oldest continuous tropical rainforest in the world. The Amazon Rainforest, by comparison, is about 55 million years old. Daintree contains plant species that date back to when Australia was part of the supercontinent Gondwana, providing a living record of plant evolution.",
    searchMapping: {
      key: 'rainforest',
      phrase: '"oldest rainforest"',
      required: ['rainforest', 'old', 'ancient'],
      excluded: []
    }
  },
  {
    question: "Some plants can generate their own heat",
    answer: true,
    category: "nature",
    explanation: "Some plants, like skunk cabbage and certain Philodendron species, can generate their own heat through a process called thermogenesis. Skunk cabbage can warm itself to temperatures up to 70°F (21°C) above the surrounding air, allowing it to melt snow and bloom in late winter. This heat also helps release its distinctive odor to attract pollinators and protects its reproductive organs from frost damage.",
    searchMapping: {
      key: 'plants',
      phrase: '"plant heat"',
      required: ['plant', 'heat', 'warm'],
      excluded: []
    }
  },
  {
    question: "The largest living thing on Earth is a single tree",
    answer: false,
    category: "nature",
    explanation: "The largest living organism on Earth is not a single tree but a clonal colony of aspen trees named Pando in Utah. While each stem appears to be an individual tree, they're all connected by a single root system and are genetically identical. Pando covers 106 acres, weighs about 6,600 tons, and consists of approximately 47,000 tree trunks/stems that are all part of one organism.",
    searchMapping: {
      key: 'largest',
      phrase: '"largest living organism"',
      required: ['largest', 'living', 'organism'],
      excluded: []
    }
  },
  {
    question: "There's a lake that never freezes, even in -40°C temperatures",
    answer: true,
    category: "nature",
    explanation: "Lake Vostok, buried beneath 13,000 feet (4 km) of ice in Antarctica, never freezes despite temperatures reaching -40°C. The enormous pressure from the ice above lowers the freezing point of water, while geothermal heat and the insulating ice sheet maintain liquid conditions. This subglacial lake has been isolated from the surface for 15-25 million years and may harbor unique life forms adapted to its extreme environment.",
    searchMapping: {
      key: 'lake',
      phrase: '"lake antarctica"',
      required: ['lake', 'freeze', 'antarctica'],
      excluded: []
    }
  },
  {
    question: "Earth has a 'twin' made entirely of diamond",
    answer: false,
    category: "nature",
    explanation: "While the exoplanet 55 Cancri e was once thought to be a 'diamond planet' with a carbon-rich composition, more recent research suggests it likely has a similar composition to Earth. True diamond planets may exist, theoretically forming around carbon-rich stars, but none have been definitively confirmed. Carbon planets remain hypothetical, though some exoplanets might have diamond layers or diamond precipitation in their atmospheres.",
    searchMapping: {
      key: 'planet',
      phrase: '"diamond planet"',
      required: ['planet', 'diamond', 'carbon'],
      excluded: []
    }
  },
  {
    question: "There's a waterfall that appears to be on fire",
    answer: true,
    category: "nature",
    explanation: "Yosemite National Park's 'Firefall' is a natural phenomenon where Horsetail Fall glows orange and red at sunset for a brief period in February. When conditions are perfect—with clear skies and the right angle of sunlight—the setting sun illuminates the waterfall, making it look like flowing lava or fire cascading down El Capitan. This spectacular optical illusion only lasts for about 10 minutes on a few days each year.",
    searchMapping: {
      key: 'waterfall',
      phrase: '"firefall yosemite"',
      required: ['waterfall', 'fire', 'yosemite'],
      excluded: []
    }
  },
  {
    question: "Some plants can recognize their relatives",
    answer: true,
    category: "nature",
    explanation: "Plants can identify their genetic relatives and adjust their growth patterns accordingly. Studies show that plants grow their roots differently when near siblings versus unrelated plants of the same species. When near relatives, plants often compete less aggressively for resources, while they may grow more competitive root systems when surrounded by non-relatives. This 'kin recognition' helps maximize collective resource acquisition among related plants.",
    searchMapping: {
      key: 'plants',
      phrase: '"plant kin recognition"',
      required: ['plant', 'recognize', 'relative'],
      excluded: []
    }
  },
  {
    question: "There are more trees on Earth than stars in our galaxy",
    answer: true,
    category: "nature",
    explanation: "Earth has approximately 3 trillion trees, while the Milky Way galaxy contains an estimated 100-400 billion stars. This means there are about 10 times more trees on our planet than stars in our galaxy. This estimate came from a 2015 study that combined satellite imagery with ground surveys to create the most comprehensive tree census ever conducted.",
    searchMapping: {
      key: 'trees',
      phrase: '"trees earth count"',
      required: ['trees', 'stars', 'count'],
      excluded: []
    }
  },
  {
    question: "Clapping thunder and lightning happen at the same time",
    answer: false,
    category: "nature",
    explanation: "Thunder and lightning occur simultaneously, but we perceive them at different times because light travels much faster than sound. Lightning is visible almost instantly, traveling at about 300,000 km/second (the speed of light). Thunder, being sound, travels much slower at about 343 meters/second. This difference allows us to roughly calculate our distance from a lightning strike—count the seconds between flash and thunder and divide by 3 for an approximate distance in kilometers.",
    searchMapping: {
      key: 'thunder',
      phrase: '"thunder lightning timing"',
      required: ['thunder', 'lightning', 'time'],
      excluded: []
    }
  }
]; 