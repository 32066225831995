import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import { allQuestions } from './questions/index';
import { getImageForQuestion, trackDownload } from './services/pixabayService';

// Debug environment variables
console.log('Environment variables:', {
  PIXABAY_API_KEY: process.env.REACT_APP_PIXABAY_API_KEY,
  NODE_ENV: process.env.NODE_ENV,
  PUBLIC_URL: process.env.PUBLIC_URL
});

function App() {
  // Game state
  const [gameStarted, setGameStarted] = useState(false);
  const [gameMode, setGameMode] = useState('standard'); // 'standard' or 'timed'
  const [questionLimit, setQuestionLimit] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [timeLimit, setTimeLimit] = useState(15); // seconds per question
  
  // Anti-repetition state
  const [recentlyAskedQuestions, setRecentlyAskedQuestions] = useState(() => {
    const saved = localStorage.getItem('recentlyAskedQuestions');
    return saved ? JSON.parse(saved) : [];
  });
  
  // Question state
  const [randomizedQuestions, setRandomizedQuestions] = useState([]);
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [answerStreak, setAnswerStreak] = useState(0);
  const [showExplanation, setShowExplanation] = useState(false);
  const [remainingTime, setRemainingTime] = useState(timeLimit);
  
  // Visual state
  const [currentImage, setCurrentImage] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [animation, setAnimation] = useState('');

  // Get unique categories from questions
  const categories = React.useMemo(() => {
    const cats = new Set();
    allQuestions.forEach(q => {
      if (q.category) cats.add(q.category);
    });
    return ['all', ...Array.from(cats)];
  }, []);

  // Shuffle and filter questions based on category
  const shuffleQuestions = useCallback(() => {
    let questionsToUse = [...allQuestions];
    
    // Filter by category if needed
    if (selectedCategory !== 'all') {
      questionsToUse = questionsToUse.filter(q => q.category === selectedCategory);
    }
    
    // Step 1: Split questions into true and false groups
    const trueQuestions = questionsToUse.filter(q => q.answer === true);
    const falseQuestions = questionsToUse.filter(q => q.answer === false);
    
    console.log(`Category: ${selectedCategory}, True: ${trueQuestions.length}, False: ${falseQuestions.length}`);
    
    // Step 2: Calculate how many of each we should include
    const targetCount = Math.min(questionLimit, questionsToUse.length);
    const halfCount = Math.floor(targetCount / 2);
    
    // Ideal would be 50/50 split
    let targetTrueCount = halfCount;
    let targetFalseCount = targetCount - halfCount;
    
    // Step 3: Check if we have enough of each type
    const availableTrueCount = trueQuestions.length;
    const availableFalseCount = falseQuestions.length;
    
    // Adjust if we don't have enough of either type
    if (targetTrueCount > availableTrueCount) {
      // We need more true questions than available
      const deficit = targetTrueCount - availableTrueCount;
      targetTrueCount = availableTrueCount;
      targetFalseCount += deficit; // Add the deficit to false count
    }
    
    if (targetFalseCount > availableFalseCount) {
      // We need more false questions than available
      const deficit = targetFalseCount - availableFalseCount;
      targetFalseCount = availableFalseCount;
      targetTrueCount += deficit; // Add the deficit to true count
    }
    
    console.log(`Target counts - True: ${targetTrueCount}, False: ${targetFalseCount}`);
    
    // Step 4: Apply the recently asked filter, but maintain balance
    const recentIds = new Set(recentlyAskedQuestions);
    
    let filteredTrueQuestions = trueQuestions.filter(q => !recentIds.has(q.question));
    let filteredFalseQuestions = falseQuestions.filter(q => !recentIds.has(q.question));
    
    // Only use filtered questions if we have enough of both types
    if (filteredTrueQuestions.length >= targetTrueCount && 
        filteredFalseQuestions.length >= targetFalseCount) {
      console.log("Using filtered questions (excluding recently asked)");
      trueQuestions.length = 0;
      trueQuestions.push(...filteredTrueQuestions);
      
      falseQuestions.length = 0;
      falseQuestions.push(...filteredFalseQuestions);
    }
    
    // Step 5: Shuffle each group separately
    for (let i = trueQuestions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [trueQuestions[i], trueQuestions[j]] = [trueQuestions[j], trueQuestions[i]];
    }
    
    for (let i = falseQuestions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [falseQuestions[i], falseQuestions[j]] = [falseQuestions[j], falseQuestions[i]];
    }
    
    // Step 6: Select the desired number of each type
    const selectedTrueQuestions = trueQuestions.slice(0, targetTrueCount);
    const selectedFalseQuestions = falseQuestions.slice(0, targetFalseCount);
    
    // Step 7: Interleave the questions to avoid runs of same answer
    // This algorithm ensures no more than 2 of the same answer type in a row
    const selectedQuestions = [];
    let trueIndex = 0;
    let falseIndex = 0;
    let lastTwoSame = false;
    let lastType = null;
    
    while (selectedQuestions.length < targetCount) {
      // Determine which type to add next
      let addTrue;
      
      if (trueIndex >= targetTrueCount) {
        // No more true questions available
        addTrue = false;
      } else if (falseIndex >= targetFalseCount) {
        // No more false questions available
        addTrue = true;
      } else if (lastTwoSame) {
        // Last two questions were the same type, so switch
        addTrue = lastType === false;
      } else {
        // Randomly choose, but with slight bias against last type
        addTrue = Math.random() > (lastType === true ? 0.7 : 0.3);
      }
      
      // Add the question
      if (addTrue && trueIndex < targetTrueCount) {
        selectedQuestions.push(selectedTrueQuestions[trueIndex]);
        trueIndex++;
        lastTwoSame = lastType === true;
        lastType = true;
      } else if (falseIndex < targetFalseCount) {
        selectedQuestions.push(selectedFalseQuestions[falseIndex]);
        falseIndex++;
        lastTwoSame = lastType === false;
        lastType = false;
      } else {
        // Fallback - we ran out of one type
        if (trueIndex < targetTrueCount) {
          selectedQuestions.push(selectedTrueQuestions[trueIndex]);
          trueIndex++;
          lastType = true;
        }
      }
    }
    
    // Step 8: Final shuffle to hide the pattern (optional)
    // We avoid this to preserve the no-runs guarantee
    // Instead, just add a bit of randomness by swapping some pairs
    // while maintaining the "no more than 2 in a row" rule
    for (let i = 0; i < selectedQuestions.length - 3; i += 2) {
      if (Math.random() < 0.3) { // 30% chance of swap
        // Check if swap would create a run of 3
        const wouldCreateRun = 
          (selectedQuestions[i].answer === selectedQuestions[i+2].answer &&
           selectedQuestions[i].answer === selectedQuestions[i+3].answer) ||
          (selectedQuestions[i+1].answer === selectedQuestions[i-1]?.answer &&
           selectedQuestions[i+1].answer === selectedQuestions[i-2]?.answer);
        
        // Only swap if it wouldn't create a run
        if (!wouldCreateRun) {
          [selectedQuestions[i], selectedQuestions[i+1]] = 
            [selectedQuestions[i+1], selectedQuestions[i]];
        }
      }
    }
    
    // Count true/false in final selection for verification
    const finalTrueCount = selectedQuestions.filter(q => q.answer === true).length;
    const finalFalseCount = selectedQuestions.filter(q => q.answer === false).length;
    console.log(`Final counts - True: ${finalTrueCount}, False: ${finalFalseCount}`);
    
    // Check for runs of 3 or more of the same answer
    let maxRun = 1;
    let currentRun = 1;
    for (let i = 1; i < selectedQuestions.length; i++) {
      if (selectedQuestions[i].answer === selectedQuestions[i-1].answer) {
        currentRun++;
        maxRun = Math.max(maxRun, currentRun);
      } else {
        currentRun = 1;
      }
    }
    console.log(`Maximum run of same answer: ${maxRun}`);
    
    // Update recently asked list
    const newRecentList = [
      ...selectedQuestions.map(q => q.question),
      ...recentlyAskedQuestions
    ].slice(0, Math.min(allQuestions.length / 2, 100)); // Keep list to a reasonable size
    
    setRecentlyAskedQuestions(newRecentList);
    localStorage.setItem('recentlyAskedQuestions', JSON.stringify(newRecentList));
    
    setRandomizedQuestions(selectedQuestions);
    return selectedQuestions;
  }, [selectedCategory, questionLimit, recentlyAskedQuestions]);

  // Start the game with shuffled questions
  const startGame = () => {
    shuffleQuestions();
    setCurrentQuestion(0);
    setScore(0);
    setAnswerStreak(0);
    setGameStarted(true);
    setRemainingTime(timeLimit);
  };

  // Reset game to home screen
  const goToHome = () => {
    setGameStarted(false);
    setShowExplanation(false);
  };

  // Fetch image for the current question
  const fetchQuestionImage = useCallback(async (questionIndex = currentQuestion) => {
    if (questionIndex < randomizedQuestions.length) {
      if (questionIndex === currentQuestion) {
        setIsImageLoading(true);
      }
      const imageData = await getImageForQuestion(randomizedQuestions[questionIndex]);
      
      if (questionIndex === currentQuestion) {
        setCurrentImage(imageData);
        if (imageData?.downloadLocation) {
          trackDownload(imageData.downloadLocation);
        }
      } else {
        // If this is a prefetch, just store the image in browser cache by creating a hidden image
        if (imageData?.url) {
          const img = new Image();
          img.src = imageData.url;
          console.log(`Prefetching image for question ${questionIndex + 1}`);
        }
      }
    }
  }, [currentQuestion, randomizedQuestions]);

  // Prefetch next question's image
  const prefetchNextImage = useCallback(() => {
    if (currentQuestion + 1 < randomizedQuestions.length) {
      fetchQuestionImage(currentQuestion + 1);
    }
  }, [currentQuestion, randomizedQuestions, fetchQuestionImage]);

  // Handle user answer
  const handleAnswer = useCallback((userAnswer) => {
    // Stop the timer
    setRemainingTime(0);
    
    const currentQ = randomizedQuestions[currentQuestion];
    const correct = userAnswer === currentQ.answer;
    
    setIsAnswerCorrect(correct);
    setShowExplanation(true);
    
    if (correct) {
      setScore(score + 1);
      setAnswerStreak(answerStreak + 1);
      setAnimation('correct');
    } else {
      setAnswerStreak(0);
      setAnimation('incorrect');
    }
    
    // Prefetch next image when user answers the current question
    prefetchNextImage();
  }, [currentQuestion, randomizedQuestions, score, answerStreak, prefetchNextImage]);

  // Move to the next question
  const handleNextQuestion = () => {
    // Clear current state
    setCurrentImage(null);
    setIsImageLoading(true);
    setIsAnswerCorrect(null);
    setShowExplanation(false);
    setAnimation('');
    
    // Move to next question
    setCurrentQuestion(currentQuestion + 1);
    
    // Reset timer for next question
    setRemainingTime(timeLimit);
    
    // Prefetch the question after next
    if (currentQuestion + 2 < randomizedQuestions.length) {
      setTimeout(() => {
        fetchQuestionImage(currentQuestion + 2);
      }, 300);
    }
  };

  // Reset game with same settings
  const handlePlayAgain = () => {
    shuffleQuestions();
    setCurrentQuestion(0);
    setScore(0);
    setAnswerStreak(0);
    setShowExplanation(false);
    setAnimation('');
    setRemainingTime(timeLimit);
  };

  // Timer effect for timed mode
  useEffect(() => {
    let timer;
    if (gameStarted && gameMode === 'timed' && currentQuestion < randomizedQuestions.length && !showExplanation) {
      timer = setInterval(() => {
        setRemainingTime(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            handleAnswer(null); // Time's up, mark as incorrect
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    
    return () => clearInterval(timer);
  }, [gameStarted, gameMode, currentQuestion, randomizedQuestions.length, showExplanation, handleAnswer]);

  // Fetch image when question changes
  useEffect(() => {
    if (gameStarted && currentQuestion < randomizedQuestions.length) {
      fetchQuestionImage();
      
      // Prefetch the next question's image
      prefetchNextImage();
    }
  }, [gameStarted, currentQuestion, randomizedQuestions, fetchQuestionImage, prefetchNextImage]);

  // Initialize questions on category change
  useEffect(() => {
    if (!gameStarted) {
      shuffleQuestions();
    }
  }, [selectedCategory, shuffleQuestions, gameStarted]);

  // Calculate progress percentage
  const progressPercentage = randomizedQuestions.length > 0
    ? (currentQuestion / randomizedQuestions.length) * 100
    : 0;

  return (
    <div className={`App ${animation}`}>
      <header className="App-header">
        {/* Only show title on home screen or game over screen */}
        {(!gameStarted || (gameStarted && currentQuestion >= randomizedQuestions.length)) && (
          <h1 className="App-title">True or False?</h1>
        )}
        
        {!gameStarted ? (
          <div className="welcome-screen">
            <div className="game-options">
              <h2>Game Settings</h2>
              
              <div className="option-group">
                <label>Game Mode:</label>
                <div className="button-group">
                  <button 
                    className={gameMode === 'standard' ? 'selected' : ''} 
                    onClick={() => setGameMode('standard')}
                  >
                    Standard
                  </button>
                  <button 
                    className={gameMode === 'timed' ? 'selected' : ''} 
                    onClick={() => setGameMode('timed')}
                  >
                    Timed
                  </button>
                </div>
              </div>
              
              <div className="option-group">
                <label>Category:</label>
                <div className="category-button-grid">
                  {categories.map((cat, index) => (
                    <button 
                      key={cat} 
                      className={selectedCategory === cat ? 'selected' : ''} 
                      onClick={() => setSelectedCategory(cat)}
                    >
                      {cat.charAt(0).toUpperCase() + cat.slice(1)}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="option-group">
                <label>Number of Questions:</label>
                <input 
                  type="range" 
                  min="5" 
                  max="20" 
                  value={questionLimit}
                  onChange={(e) => setQuestionLimit(Number(e.target.value))}
                />
                <span>{questionLimit}</span>
              </div>
              
              <div className={`timed-option-wrapper ${gameMode === 'timed' ? 'visible' : 'hidden'}`}>
                {gameMode === 'timed' && (
                  <div className="option-group">
                    <label>Time per Question (seconds):</label>
                    <input 
                      type="range" 
                      min="5" 
                      max="30" 
                      value={timeLimit}
                      onChange={(e) => setTimeLimit(Number(e.target.value))}
                    />
                    <span>{timeLimit}s</span>
                  </div>
                )}
              </div>
              
              <button 
                className="start-button"
                onClick={startGame}
              >
                Start Game
              </button>
            </div>
          </div>
        ) : (
          <div className="content-wrapper">
            {currentQuestion < randomizedQuestions.length ? (
              <>
                {/* Progress bar */}
                <div className="progress-bar">
                  <div 
                    className="progress-fill" 
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
                
                <div className="stats-bar">
                  <div className="stats-item">
                    <span>Question</span>
                    <strong>{currentQuestion + 1}/{randomizedQuestions.length}</strong>
                  </div>
                  <div className="stats-item">
                    <span>Score</span>
                    <strong>{score}</strong>
                  </div>
                  <div className="stats-item">
                    <span>Streak</span>
                    <strong>{answerStreak}</strong>
                  </div>
                  {gameMode === 'timed' && (
                    <div className="stats-item timer">
                      <span>Time</span>
                      <strong>{remainingTime}s</strong>
                    </div>
                  )}
                </div>
                
                <div className={`question-card ${animation}`}>
                  {/* Question text */}
                  <p className="question-text">{randomizedQuestions[currentQuestion]?.question}</p>
                  
                  {/* Question image */}
                  <div className={`question-image ${isImageLoading ? 'loading' : 'loaded'}`}>
                    {currentImage ? (
                      <>
                        <img 
                          src={currentImage.url} 
                          alt="Question illustration" 
                          onLoad={() => setIsImageLoading(false)}
                        />
                        <div className="image-attribution">
                          Photo by <a href={currentImage.photographerUrl} target="_blank" rel="noopener noreferrer">
                            {currentImage.photographer}
                          </a> on <a href={currentImage.sourceUrl} target="_blank" rel="noopener noreferrer">
                            {currentImage.source}
                          </a>
                        </div>
                      </>
                    ) : (
                      <div className="no-image-placeholder">
                        <p>No image available</p>
                      </div>
                    )}
                    
                    {/* Overlay explanation on top of image when showing explanation */}
                    {showExplanation && (
                      <div className="explanation-overlay">
                        <div className={`answer-result ${isAnswerCorrect ? 'correct' : 'incorrect'}`}>
                          <h3>{isAnswerCorrect ? 'Correct!' : 'Incorrect!'}</h3>
                          {randomizedQuestions[currentQuestion].explanation && (
                            <div className="explanation-container">
                              <p className="explanation-text">{randomizedQuestions[currentQuestion].explanation}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {/* Answer buttons */}
                  {!showExplanation ? (
                    <div className="button-container">
                      <button 
                        className="answer-btn true-btn"
                        onClick={() => handleAnswer(true)}
                        disabled={showExplanation}
                      >
                        True
                      </button>
                      <button 
                        className="answer-btn false-btn"
                        onClick={() => handleAnswer(false)}
                        disabled={showExplanation}
                      >
                        False
                      </button>
                    </div>
                  ) : (
                    <div className="next-button-container">
                      <button 
                        className="next-btn"
                        onClick={handleNextQuestion}
                      >
                        Next Question
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="question-card game-over">
                <h2>Game Over!</h2>
                <div className="results-summary">
                  <p>Final Score: <span className="highlight">{score}</span> out of <span>{randomizedQuestions.length}</span></p>
                  <p>Percentage: <span className="highlight">{Math.round((score / randomizedQuestions.length) * 100)}%</span></p>
                </div>
                
                <div className="end-game-buttons">
                  <button 
                    onClick={handlePlayAgain}
                    className="play-again-button"
                  >
                    Play Again
                  </button>
                  <button 
                    onClick={goToHome}
                    className="home-button"
                  >
                    Home Screen
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
