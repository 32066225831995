// Questions about sports
export const sports = [
  {
    question: "The Olympic Games are held every 4 years",
    answer: true,
    category: "sports",
    explanation: "The modern Olympic Games are held every four years, following the tradition established in ancient Greece. This four-year period between Games is called an 'Olympiad'. However, since 1994, the Summer and Winter Olympic Games have been held on separate four-year cycles, two years apart from each other.",
    searchMapping: {
      key: 'olympic games',
      phrase: '"olympic games schedule"',
      required: ['olympics', 'games', 'years'],
      excluded: []
    }
  },
  {
    question: "A marathon is exactly 26 miles in length",
    answer: false,
    category: "sports",
    explanation: "A marathon is not exactly 26 miles in length. The official marathon distance is 26.2 miles (42.195 kilometers). This specific distance was standardized in 1921 but originated from the 1908 London Olympics, where the route was extended so the race could finish in front of the royal family's viewing box.",
    searchMapping: {
      key: 'marathon distance',
      phrase: '"marathon length"',
      required: ['marathon', 'miles', 'kilometers'],
      excluded: []
    }
  },
  {
    question: "Soccer (football) was invented in England",
    answer: true,
    category: "sports",
    explanation: "Modern soccer (football) as we know it today was indeed invented in England. The Football Association, formed in London in 1863, created the first standardized set of rules for the game. While various ball games have been played throughout history across different civilizations, the modern code of association football originated in England during the 19th century.",
    searchMapping: {
      key: 'soccer football',
      phrase: '"football history origin"',
      required: ['soccer', 'football', 'england'],
      excluded: []
    }
  },
  {
    question: "In the Olympic Games, gold medals are made of pure gold",
    answer: false,
    category: "sports",
    explanation: "Olympic gold medals are not made of pure gold. They are primarily made of silver (at least 92.5%) and then plated with a minimum of 6 grams of gold. The last Olympic medals made of solid gold were awarded in 1912.",
    searchMapping: {
      key: 'olympic medals',
      phrase: '"olympic gold medal"',
      required: ['olympic', 'gold', 'medal'],
      excluded: []
    }
  },
  {
    question: "Basketball was invented in Canada",
    answer: true,
    category: "sports",
    explanation: "Basketball was invented by James Naismith, a Canadian physical education instructor, in December 1891. He created the game while teaching at the International YMCA Training School (now Springfield College) in Springfield, Massachusetts, USA. Though the game was invented in the United States, its creator was Canadian.",
    searchMapping: {
      key: 'basketball invention',
      phrase: '"basketball history"',
      required: ['basketball', 'invented', 'Naismith'],
      excluded: []
    }
  },
  {
    question: "In tennis, players change sides after every set",
    answer: false,
    category: "sports",
    explanation: "In tennis, players change sides after every odd-numbered game (1, 3, 5, etc.) within a set, not after every set. This rule ensures that neither player has an advantage from court conditions like sun or wind for an extended period.",
    searchMapping: {
      key: 'tennis rules',
      phrase: '"tennis change sides"',
      required: ['tennis', 'sides', 'change'],
      excluded: []
    }
  },
  {
    question: "Swimming is the only sport that has been in every modern Olympic Games",
    answer: true,
    category: "sports",
    explanation: "Swimming is one of the few sports that has been featured in every modern Olympic Games since the first one in 1896. Athletics (track and field) and gymnastics are also among the sports that have been included in every modern Olympics.",
    searchMapping: {
      key: 'olympic swimming',
      phrase: '"olympic sports history"',
      required: ['swimming', 'olympic', 'sport'],
      excluded: []
    }
  },
  {
    question: "A gymnast can score a perfect 10 in modern Olympic gymnastics",
    answer: false,
    category: "sports",
    explanation: "The perfect 10 scoring system in gymnastics was replaced in 2006 with an open-ended scoring system. Now, gymnasts receive two separate scores that are added together: a Difficulty score (D-score) and an Execution score (E-score), making it impossible to score a 'perfect 10' in modern Olympic gymnastics.",
    searchMapping: {
      key: 'gymnastics scoring',
      phrase: '"gymnastics perfect 10"',
      required: ['gymnastics', 'scoring', 'olympics'],
      excluded: []
    }
  },
  {
    question: "The Olympic rings represent the five continents",
    answer: true,
    category: "sports",
    explanation: "The five Olympic rings do represent the five continents from which athletes come to compete in the games. The five colors (blue, yellow, black, green, and red) were chosen because at least one of these colors appears in the flag of every country that participated in the Olympics at the time the symbol was created in 1913.",
    searchMapping: {
      key: 'olympic rings',
      phrase: '"olympic rings meaning"',
      required: ['olympic', 'rings', 'continents'],
      excluded: []
    }
  },
  {
    question: "In baseball, a 'home run' is when a player runs all the way home without stopping",
    answer: false,
    category: "sports",
    explanation: "A 'home run' in baseball occurs when a batter hits the ball into fair territory and is able to circle all the bases, ending at home plate in one play without any errors by the defensive team. It typically happens when the ball is hit over the outfield fence, allowing the batter to circle the bases unimpeded.",
    searchMapping: {
      key: 'baseball home run',
      phrase: '"baseball home run"',
      required: ['baseball', 'home run', 'hit'],
      excluded: []
    }
  },
  {
    question: "The Tour de France cycling race happens every two years",
    answer: false,
    category: "sports",
    explanation: "The Tour de France is an annual cycling race, not biennial. It has been held annually since its inception in 1903, except during the two World Wars. The race typically takes place in July and lasts for three weeks, covering approximately 2,200 miles (3,500 kilometers).",
    searchMapping: {
      key: 'tour de france',
      phrase: '"tour de france schedule"',
      required: ['cycling', 'race', 'france'],
      excluded: []
    }
  },
  {
    question: "Golf balls have exactly 336 dimples",
    answer: false,
    category: "sports",
    explanation: "Golf balls do not all have exactly 336 dimples. The number of dimples on a golf ball can vary widely depending on the manufacturer and model. Most golf balls have between 300 and 500 dimples, with many modern balls having around 300-400. The dimples help reduce drag and allow the ball to fly farther.",
    searchMapping: {
      key: 'golf ball',
      phrase: '"golf ball dimples"',
      required: ['golf', 'ball', 'dimples'],
      excluded: []
    }
  },
  {
    question: "The first modern Olympic Games were held in Greece",
    answer: true,
    category: "sports",
    explanation: "The first modern Olympic Games were indeed held in Athens, Greece, in 1896. They were organized by the International Olympic Committee (IOC), which had been established two years earlier by Pierre de Coubertin. The games featured athletes from 14 nations competing in 43 events across 9 sports.",
    searchMapping: {
      key: 'first olympics',
      phrase: '"first modern olympic games"',
      required: ['olympics', 'athens', 'greece'],
      excluded: []
    }
  },
  {
    question: "In basketball, a player scores 3 points for a shot taken from beyond the three-point line",
    answer: true,
    category: "sports",
    explanation: "In basketball, a player does score 3 points for a successful shot taken from beyond the three-point line. This rule was first introduced to the NBA in the 1979-1980 season. Shots made from inside the three-point line are worth 2 points, and free throws are worth 1 point each.",
    searchMapping: {
      key: 'basketball points',
      phrase: '"basketball scoring rules"',
      required: ['basketball', 'three-point', 'scoring'],
      excluded: []
    }
  },
  {
    question: "Chess is considered a sport by the International Olympic Committee",
    answer: true,
    category: "sports",
    explanation: "Chess is recognized as a sport by the International Olympic Committee (IOC). While it is not included in the Olympic Games, the IOC has officially recognized the International Chess Federation (FIDE) as a sports federation since 1999. Chess competitions require significant mental stamina and are organized with international tournaments and world championships.",
    searchMapping: {
      key: 'chess sport',
      phrase: '"chess olympic sport"',
      required: ['chess', 'sport', 'olympic'],
      excluded: []
    }
  },
  {
    question: "Hockey pucks are made of rubber",
    answer: true,
    category: "sports",
    explanation: "Ice hockey pucks are indeed made of vulcanized rubber. Standard hockey pucks are 1 inch thick, 3 inches in diameter, and weigh between 5.5 and 6 ounces. The pucks are frozen before the game to reduce bouncing during play.",
    searchMapping: {
      key: 'hockey puck',
      phrase: '"hockey puck material"',
      required: ['hockey', 'puck', 'rubber'],
      excluded: []
    }
  },
  {
    question: "A baseball game has 7 innings",
    answer: false,
    category: "sports",
    explanation: "A standard baseball game has 9 innings, not 7. Each inning is divided into two halves: the top half, where the visiting team bats and the home team fields, and the bottom half, where the home team bats and the visiting team fields. Youth baseball or softball games sometimes use 7 innings, but professional and collegiate baseball uses 9 innings.",
    searchMapping: {
      key: 'baseball innings',
      phrase: '"baseball game length"',
      required: ['baseball', 'innings', 'game'],
      excluded: []
    }
  },
  {
    question: "A volleyball team has 6 players on the court",
    answer: true,
    category: "sports",
    explanation: "In standard indoor volleyball, each team does have exactly 6 players on the court. Players rotate positions in a clockwise manner each time their team wins back the serve. Beach volleyball, which is a variation of the game, has 2 players per team.",
    searchMapping: {
      key: 'volleyball players',
      phrase: '"volleyball team size"',
      required: ['volleyball', 'players', 'court'],
      excluded: ['beach']
    }
  },
  {
    question: "The NFL (National Football League) has 30 teams",
    answer: false,
    category: "sports",
    explanation: "The NFL (National Football League) has 32 teams, not 30. The teams are divided equally between two conferences: the National Football Conference (NFC) and the American Football Conference (AFC), with each conference containing 16 teams divided into 4 divisions.",
    searchMapping: {
      key: 'NFL teams',
      phrase: '"NFL number of teams"',
      required: ['NFL', 'teams', 'football'],
      excluded: []
    }
  },
  {
    question: "In archery, the center of the target is called the bullseye",
    answer: true,
    category: "sports",
    explanation: "In archery, the center of the target is indeed called the bullseye. The target consists of 10 concentric rings, with the innermost gold ring (the bullseye) being worth the highest number of points (10). The farther from the center an arrow lands, the fewer points it scores.",
    searchMapping: {
      key: 'archery target',
      phrase: '"archery bullseye"',
      required: ['archery', 'target', 'center'],
      excluded: []
    }
  },
  {
    question: "The Summer and Winter Olympics are held in the same year",
    answer: false,
    category: "sports",
    explanation: "Since 1994, the Summer and Winter Olympics have been held on separate four-year cycles. They are now staggered so that there is an Olympic event (either Summer or Winter) every two years. Before 1994, they were held in the same year.",
    searchMapping: {
      key: 'olympics schedule',
      phrase: '"summer winter olympics"',
      required: ['summer', 'winter', 'olympics'],
      excluded: []
    }
  },
  {
    question: "The world's highest tennis court is on top of a hotel",
    answer: true,
    category: "sports",
    explanation: "The world's highest tennis court is located on the helipad of the Burj Al Arab hotel in Dubai, which sits approximately 1,000 feet (305 meters) above ground level. The court was built in 2005 for a publicity photo shoot featuring Andre Agassi and Roger Federer, and is not a permanent facility.",
    searchMapping: {
      key: 'high tennis court',
      phrase: '"highest tennis court"',
      required: ['tennis', 'court', 'high'],
      excluded: []
    }
  },
  {
    question: "In cricket, a 'century' means a player has scored 100 runs",
    answer: true,
    category: "sports",
    explanation: "In cricket, a 'century' is indeed achieved when a batter scores 100 runs or more in a single innings. It is a significant milestone in the sport and is celebrated by the player raising their bat to acknowledge the achievement. Scoring a century requires considerable skill, concentration, and endurance.",
    searchMapping: {
      key: 'cricket century',
      phrase: '"cricket century runs"',
      required: ['cricket', 'century', 'batting'],
      excluded: []
    }
  }
]; 